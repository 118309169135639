<template>
  <div class="--overflow-hidden">
    <!-- begin::EditOperation -->
    <div class="row" v-if="showDataDebug">
      <div class="col-6">
        <div
          class="card card-custom card-stretch --gutter-b --card-scroll --h-250px p-4 px-2"
          :class="typeof Partners !== 'undefined' && Partners.length > 0 ? 'bg-success' : 'bg-warning'"
        >
          <span><strong>Partners</strong> are loaded ? {{ Partners.length }}</span>
        </div>
      </div>
      <div class="col-6">
        <div
          class="card card-custom card-stretch --gutter-b --card-scroll --h-250px p-4 px-2"
          :class="typeof displayPallets !== 'undefined' && displayPallets.length > 0 ? 'bg-success' : 'bg-warning'"
        >
          <span><strong>Pallets</strong> are loaded ? {{ displayPallets.length }}</span>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-custom card-stretch --gutter-b card-scroll h-250px p-4">
          <pre class="mb-0" :class="fullyLoaded ? 'text-warning' : 'text-success'"><b>fullyLoaded :</b> {{ fullyLoaded }}</pre>
          <pre class="mb-0" :class="isFetching ? 'text-warning' : 'text-success'"><b>isFetching :</b> {{ isFetching }}</pre>
          <pre class="mb-0" :class="isSubmitting ? 'text-warning' : 'text-success'"><b>isSubmitting :</b> {{ isSubmitting }}</pre>
          <pre class="mb-0" :class="isDuplicating ? 'text-warning' : 'text-success'"><b>isDuplicating :</b> {{ isDuplicating }}</pre>
          <pre class="mb-0" :class="isResetting ? 'text-warning' : 'text-success'"><b>isResetting :</b> {{ isResetting }}</pre>
          <pre class="mb-0" :class="isValidating ? 'text-warning' : 'text-success'"><b>isValidating :</b> {{ isValidating }}</pre>
          <pre class="mb-0" :class="isUploadingDoc ? 'text-warning' : 'text-success'"><b>isUploadingDoc :</b> {{ isUploadingDoc }}</pre>

          <pre class="mb-0"><b>getMyPOVIndex :</b> {{ typeof getMyPOVIndex() }}  / {{ getMyPOVIndex() }}</pre>
          <pre class="mb-0"><b>displayPallets length :</b> {{ typeof displayPallets }}  / {{ displayPallets.length }}</pre>
          <pre class="mb-0"><b>getDisplayPallets length :</b> {{ typeof getDisplayPallets }}  / {{ getDisplayPallets.length }}</pre>

          <pre class="mb-0 text-info"><b>prevOperationUID :</b> {{ prevOperationUID }}</pre>
          <pre class="mb-0 text-info"><b>currentOperationUID :</b> {{ syncedOperation.OperationUID }}</pre>
          <pre class="mb-0 text-info"><b>nextOperationUID :</b> {{ nextOperationUID }}</pre>

          <pre class="mb-0 text-info"><b>collapses :</b> {{ collapses }}</pre>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-custom card-stretch --gutter-b card-scroll h-250px p-4">
          <pre
            class="mb-0"
          ><b>MyPOV :</b> {{ typeof syncedOperation.POVs[getMyPOVIndex()] }} <br/> <span class="text-warning">{{ typeof syncedOperation.POVs[getMyPOVIndex()] !== "undefined" ? syncedOperation.POVs[getMyPOVIndex()].Role : "Pas de POV" }}</span><br/> {{ syncedOperation.POVs }}</pre>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-custom card-stretch --gutter-b card-scroll p-4">
          <pre class="mb-0"><b>hashedInitialSyncedOperation (sha1) :</b> {{ hashedInitialSyncedOperation }}</pre>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-custom card-stretch --gutter-b card-scroll p-4">
          <pre
            class="mb-0"
          ><b>hashedSyncedOperation (sha1) :</b> <span :class="{'text-info' : hashedInitialSyncedOperation != hashedSyncedOperation}">{{ hashedSyncedOperation }}</span></pre>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-custom card-stretch --gutter-b card-scroll h-250px p-4">
          <pre class="mb-0"><b>initialSyncedOperation :</b> {{ initialSyncedOperation }}</pre>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-custom card-stretch --gutter-b card-scroll h-250px p-4">
          <pre
            class="mb-0"
          ><b>modifiedOperation :</b> <span :class="{'text-info' : hashedInitialSyncedOperation != hashedSyncedOperation}">{{ modifiedOperation }}</span></pre>
        </div>
      </div>

      {{ preEstablishedNote }}
    </div>

    <div class="row h-100">
      <div class="col-xl-11">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Body-->
          <div class="card-body p-0">
            <div class="d-flex d-sm-inline-flex flex-sm-row flex-column align-items-stretch w-100">
              <!-- Flow -->
              <div
                class="order-2 order-sm-0 py-4 pl-4 pr-6 w-20 w-100-sm bg-light-opalean-lighter rounded-top-left-sm rounded-bottom-left-sm"
                :class="Object.keys(syncedOperation.Stream.Operations).length > 0 ? 'w-20 w-100-sm' : 'd-none'"
                v-if="!isMobileDevice"
              >
                <div class="d-flex --flex-column align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <span class="svg-icon svg-icon-3x svg-icon-dark-50 mt-1">
                      <inline-svg src="media/opalean/svg/Flow.svg"></inline-svg>
                    </span>
                    <hgroup class="d-flex flex-column ml-2 mt-4 text-opalean-gray-dark">
                      <span class="font-size-sm font-weight-normal" v-translate>Operation</span>
                      <h3 class="" v-translate>Flow</h3>
                    </hgroup>
                  </div>
                  <!--begin: Toolbar -->
                  <div class="card-toolbar">
                    <button
                      class="btn btn-transparent --btn-hover-opalean-gray-medium text-hover-white p-2 mr-2"
                      v-b-tooltip.hover.bottom="$gettext('Navigate to previous operation')"
                      @click="previousOperation()"
                      v-if="Object.keys(FilteredOperations).length > 0 && syncedOperation.action === 'edit' && prevOperationUID !== undefined"
                      :disabled="!(!isFetching && fullyLoaded)"
                      :class="!(!isFetching && fullyLoaded) ? 'opacity-40' : 'btn-hover-opalean-gray-medium'"
                    >
                      <span class="svg-icon svg-icon-opalean-gray-medium m-0 handle"
                        ><inline-svg src="media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                      </span>
                      <span class="visually-hidden-focusable sr-only" v-translate>Previous operation</span>
                    </button>
                    <button
                      class="btn btn-transparent --btn-hover-opalean-gray-medium text-hover-white p-2 mr-2"
                      v-b-tooltip.hover.bottom="$gettext('Navigate to next operation')"
                      @click="nextOperation()"
                      v-if="Object.keys(FilteredOperations).length > 0 && syncedOperation.action === 'edit' && nextOperationUID !== undefined"
                      :disabled="!(!isFetching && fullyLoaded)"
                      :class="!(!isFetching && fullyLoaded) ? 'opacity-40' : 'btn-hover-opalean-gray-medium'"
                    >
                      <span class="svg-icon svg-icon-opalean-gray-medium m-0 handle"
                        ><inline-svg src="media/svg/icons/Navigation/Arrow-right.svg"></inline-svg>
                      </span>
                      <span class="visually-hidden-focusable sr-only" v-translate>Next operation</span>
                    </button>
                    <!-- <a href="#" class="btn btn-transparent btn-hover-opalean-gray-medium text-hover-white p-2"
                      ><span class="svg-icon svg-icon-opalean-gray-medium m-0 handle"><inline-svg src="media/svg/icons/General/Other1.svg"></inline-svg></span>
                    </a> -->
                  </div>
                  <!--end: Toolbar -->
                </div>

                <!--begin: Filters -->
                <div
                  class="mt-4 pt-4 pb-6 border-opalean-white border-bottom border-top"
                  v-if="Object.keys(FilteredOperations).length > 0 && (FilteredOperations.Filter || computedFilteredOperationsSelected.length > 0)"
                >
                  <div class="mb-2">
                    <span class="svg-icon svg-icon-sm svg-icon-opalean-gray-medium m-0 handle"
                      ><inline-svg src="media/svg/icons/Text/Filter.svg"></inline-svg>
                    </span>
                    <span class="text-muted font-weight-bold" v-translate>Operations filtered in :</span>
                  </div>
                  <span class="label label-inline label-outline-opalean-info text-opalean-info mx-1" v-if="FilteredOperations.Filter">{{
                    FilteredOperations.Filter | safePrint
                  }}</span>
                  <span
                    class="label label-inline label-outline-opalean-gray-medium text-dark-75 mx-1"
                    v-for="(filter, index) in computedFilteredOperationsSelected"
                    :key="index"
                    >{{ filter | safePrint }}</span
                  >
                </div>
                <!--end: Filters -->

                <perfect-scrollbar
                  ref="flow-items-scroll"
                  class="flow-items-scroll pr-4 mr-n4"
                  style="--max-height: 90vh; position: relative; --overflow-x: hidden"
                >
                  <div class="flow-items mt-8">
                    <!-- Created -->
                    <div class="mb-3 card card-custom card-fit" v-if="syncedOperation.Creation.DateTime && syncedOperation.Creation.DateTime !== null">
                      <div class="card-body px-5 pt-3 pb-3">
                        <!-- Date -->
                        <span class="py-2 font-size-xs d-flex flex-column">
                          <span class="text-dark-75 font-weight-medium" v-if="syncedOperation.Creation.DateTime && syncedOperation.Creation.DateTime !== null"
                            ><strong><i class="flaticon2-plus icon-sm mr-3"></i><translate>Created at</translate></strong>
                            {{ syncedOperation.Creation.DateTime | getDateFormat("LocaleDate") }},
                            {{ syncedOperation.Creation.DateTime | getDateFormat("LocaleTime") }}</span
                          >
                          <!-- Partner -->
                          <span
                            class="text-dark-75 font-weight-bold"
                            v-if="
                              syncedOperation.Creation.PartnerID != getUserID && syncedOperation.Creation.PartnerID && syncedOperation.Creation.PartnerID !== 0
                            "
                            ><span class="text-dark-50"><translate>By </translate></span>
                            {{ getPartnerByPartnerID(syncedOperation.Creation.PartnerID) | getSafeObjectValue("Name") }}</span
                          >
                          <!-- User -->
                          <span class="text-dark-75 font-weight-bold" v-if="syncedOperation.Creation.UserName"
                            ><span class="text-dark-50"><translate>By User </translate></span> {{ syncedOperation.Creation.UserName | safePrint }}</span
                          >
                        </span>
                      </div>
                    </div>

                    <!-- Modified -->
                    <div
                      class="mb-3 card card-custom card-fit"
                      v-if="
                        syncedOperation.Modification.DateTime &&
                        syncedOperation.Modification.DateTime !== null &&
                        syncedOperation.Creation.DateTime !== syncedOperation.Modification.DateTime
                      "
                    >
                      <div class="card-body px-5 pt-3 pb-3">
                        <!-- Date -->
                        <span class="py-2 font-size-xs d-flex flex-column">
                          <span
                            class="text-dark-75 font-weight-medium"
                            v-if="syncedOperation.Modification.DateTime && syncedOperation.Modification.DateTime !== null"
                            ><strong><i class="flaticon2-pen icon-sm mr-3"></i><translate>Modified at</translate></strong>
                            {{ syncedOperation.Modification.DateTime | getDateFormat("LocaleDate") }},
                            {{ syncedOperation.Modification.DateTime | getDateFormat("LocaleTime") }}</span
                          >
                          <!-- Partner -->
                          <span
                            class="text-dark-75 font-weight-bold"
                            v-if="
                              syncedOperation.Modification.PartnerID != getUserID &&
                              syncedOperation.Modification.PartnerID &&
                              syncedOperation.Modification.PartnerID !== 0
                            "
                            ><span class="text-dark-50"><translate>By </translate></span>
                            {{ getPartnerByPartnerID(syncedOperation.Modification.PartnerID) | getSafeObjectValue("Name") }}</span
                          >
                          <!-- User -->
                          <span class="text-dark-75 font-weight-bold" v-if="syncedOperation.Modification.UserName"
                            ><span class="text-dark-50"><translate>By User </translate></span> {{ syncedOperation.Modification.UserName | safePrint }}</span
                          >
                        </span>
                      </div>
                    </div>

                    <!-- Items -->
                    <div
                      class="flow-item card card-custom card-fit --card-border"
                      v-for="(Operation, index) in syncedOperation.Stream.Operations"
                      :key="index"
                      :class="Operation.OperationUID === syncedOperation.OperationUID ? 'active' : 'unactive'"
                    >
                      <!-- Header -->
                      <div class="card-header px-4 py-2 border-bottom">
                        <h5 class="card-title align-items-start flex-column">
                          <span class="align-items-start flex-row">
                            <!-- Type -->
                            <span
                              class="svg-icon svg-icon-sm mr-1"
                              :class="Operation.OperationUID === syncedOperation.OperationUID ? 'svg-icon-light' : 'svg-icon-primary'"
                            >
                              <!--begin::Svg Icon -->
                              <inline-svg :src="getFlowType(Operation.OperationType, 'icon')"></inline-svg>
                              <!--end::Svg Icon-->
                            </span>
                            <span class="font-weight-bolder text-dark font-size-sm" v-if="Operation.OperationType !== undefined">{{
                              $gettext(getFlowType(Operation.OperationType, "name")) | safePrint
                            }}</span>
                            <div class="">
                              <span class="ml-2 mt-2">
                                <!-- Date -->
                                <span
                                  class="label label-sm label-inline font-weight-bold px-2 --border-width-2 align-middle"
                                  :class="[
                                    Operation.OperationUID !== undefined ? 'rounded-right-0' : '',
                                    Operation.OperationUID === syncedOperation.OperationUID
                                      ? 'label-outline-light text-light'
                                      : 'label-outline-opalean-gray-medium',
                                  ]"
                                  v-if="Operation.OperationDate !== undefined"
                                  >{{ Operation.OperationDate | getDateFormat("LocaleDate") }}</span
                                >

                                <!-- ID -->
                                <span
                                  class="label label-sm label-inline font-weight-bold px-2 align-middle"
                                  :class="[
                                    Operation.OperationDate !== undefined ? 'rounded-left-0' : '',
                                    Operation.OperationUID === syncedOperation.OperationID ? 'label-light' : 'label-opalean-gray-medium',
                                  ]"
                                  v-if="Operation.OperationID !== undefined"
                                  >{{ Operation.OperationID | safePrint }}</span
                                >
                              </span>
                              <div class="small" v-if="showDataDebug">
                                <code><b>current:</b> {{ syncedOperation.OperationUID }}</code
                                ><br />
                                <code><b>this:</b> {{ Operation.OperationUID }}</code>
                              </div>
                              <!-- Charter : isOS -->
                              <span class="ml-2 mt-2">
                                <span class="label label-sm label-inline font-weight-bold pl-1 pr-2 label-opalean-tertiary align-middle" v-if="Operation.IsOS">
                                  <span class="svg-icon svg-icon-sm svg-icon-dark mr-1">
                                    <!--begin::Svg Icon -->
                                    <inline-svg src="media/opalean/svg/Charter.svg" style="width: 20px !important"></inline-svg>
                                    <!--end::Svg Icon-->
                                  </span>
                                  {{ $gettext("Charter") }}</span
                                >
                              </span>
                            </div>
                          </span>

                          <!-- CMR -->
                          <span class="text-opalean-primary font-weight-bold font-size-xs mt-3 ml-1" v-if="Operation.OperationCMR"
                            ><i class="far fa-sticky-note icon-nm text-opalean-primary mr-2"></i>{{ Operation.OperationCMR | safePrint }}</span
                          >
                        </h5>

                        <b-button v-b-toggle="'collapseBody-' + index.toString()" variant="link" class="text-opalean-primary"
                          ><b-icon :icon="collapses['collapseBody-' + index.toString()] === true ? 'caret-up' : 'caret-down'"></b-icon
                        ></b-button>
                      </div>
                      <!-- Body -->
                      <b-collapse
                        :id="'collapseBody-' + index.toString()"
                        v-model="collapses['collapseBody-' + index.toString()]"
                        accordion="collapseBody"
                        role="tabpanel"
                      >
                        <div class="card-body px-5 pt-5 pb-3">
                          <div class="flowline">
                            <div class="flowline-bar"></div>

                            <div class="flowline-item" v-for="(Partner, _index) in Operation.Partners" :key="_index">
                              <span class="flowline-badge" :class="Partner.PartnerRole === Operation.MyRole ? 'flowline-badge-success bg-success' : ''"></span>
                              <div class="flowline-content d-flex align-items-center justify-content-between">
                                <span>{{ $gettext(getThirdPartyType(Partner.PartnerRole, "name")) }}</span>
                                <span v-if="Partner.PartnerID === 0">{{ Partner.PartnerFullName }}</span>
                                <span v-else>{{ getPartnerByPartnerID(Partner.PartnerID) | getSafeObjectValue("Name") }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-collapse>
                      <!-- Footer -->
                      <div class="card-footer px-5 py-3 border-top d-flex justify-content-between">
                        <b-badge
                          v-if="
                            (getOperationThirdPartyDetails_refactored(Operation, 'thirdparty', 'Attributes', '—').includes('isClient') ||
                              Operation.OperationStatus === 'B' ||
                              Operation.OperationStatus === 'L') &&
                            Operation.OperationStatus !== 'R'
                          "
                          pill
                          :variant="getStatusType(Operation.OperationStatus, 'class')"
                          class="font-weight-bolder font-size-sm text-white"
                          >{{ $gettext(getStatusType(Operation.OperationStatus, "name")) }}
                        </b-badge>
                        <i v-else-if="!getOperationThirdPartyDetails_refactored(Operation, 'thirdparty', 'Attributes', '—').includes('isClient')"></i>
                        <i v-else class="text-opalean-primary icon-md flaticon2-correct pl-0 pr-5"></i>
                        <div class="card-toolbar">
                          <b-button
                            v-if="Operation.OperationUID !== syncedOperation.OperationUID && syncedOperation.OperationUID !== 0"
                            :to="{
                              name: 'route.operations.edit',
                              params: {
                                OperationUID: Operation.OperationUID,
                              },
                            }"
                            variant="hover-light-bg-secondary"
                            class="px-2 py-0 font-weight-bold btn-hover-text-dark btn-hover-icon-dark --stretched-link"
                            v-b-tooltip.hover
                            :title="$gettext('Edit operation')"
                            ><i class="la la-edit icon-xl"></i
                          ></b-button>

                          <!--
                          <b-dropdown
                            dropleft
                            no-caret
                            toggle-class="px-2 py-0 btn-hover-bg-secondary btn-hover-text-dark btn-hover-icon-dark"
                            variant="hover-bg-secondary"
                            v-show="false"
                          >
                            <template #button-content>
                              <i class="la la-ellipsis-h icon-xl p-0"></i>
                            </template>
                            <b-dropdown-header id="dropdown-header-1" v-translate>Groups</b-dropdown-header>
                            <b-dropdown-item v-translate> Duplicate </b-dropdown-item>
                            <b-dropdown-item v-translate>Change status</b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-header id="dropdown-header-1" v-translate>Groups</b-dropdown-header>
                            <b-dropdown-item v-translate>Export</b-dropdown-item>
                            <b-dropdown-item v-translate>Print</b-dropdown-item>
                          </b-dropdown>
                          -->
                        </div>
                      </div>
                    </div>

                    <!-- Actions -->

                    <div class="card text-info" v-if="showDataDebug">
                      Testing duplicate form ID# {{ syncedOperation.OperationID }} UID# {{ syncedOperation.OperationUID }} MyRole#
                      {{ syncedOperation.MyRole }} OperationType# {{ syncedOperation.OperationType }} OperationDate#
                      {{ syncedOperation.OperationDate }} hasMCPart?# {{ syncedOperation.Partners.filter((p) => p.PartnerRole === "MC").length }} hasCinSTream?#
                      {{ syncedOperation.Stream.Operations.filter((p) => p.OperationType === "C").length }}
                    </div>

                    <!-- Duplicate  : loading  -->
                    <button
                      class="flow-item card card-custom card-fit card-border btn button border-2 border-dashed p-O"
                      v-if="
                        syncedOperation.OperationUID !== 0 &&
                        syncedOperation.OperationID !== 0 &&
                        syncedOperation.OperationType === 'D' &&
                        syncedOperation.MyRole === 'MT' &&
                        syncedOperation.Partners.filter((p) => p.PartnerRole === 'MC').length > 0 &&
                        typeof syncedOperation.Partners.filter((p) => p.PartnerRole === 'MC') !== 'undefined' &&
                        syncedOperation.Partners.filter((p) => p.PartnerRole === 'MC')[0].PartnerID !== 0 &&
                        syncedOperation.Stream.Operations.filter((p) => p.OperationType === 'C').length === 0
                      "
                      v-on:click="onDuplicateForm($event, 'loading')"
                      :disabled="isDuplicating"
                    >
                      <!-- Body -->
                      <div class="card-body px-5 py-5">
                        <span class="d-flex align-items-center"
                          ><span class="svg-icon svg-icon-3x mr-4" v-if="isDuplicating === false">
                            <inline-svg :src="getFlowType('C', 'icon')"></inline-svg>
                          </span>
                          <span class="mx-4" v-if="isDuplicating === true">
                            <b-spinner variant="opalean-gray-medium" small type="grow" class="mr-4"></b-spinner>
                          </span>
                          <span class="font-weight-bold text-left"
                            ><translate>Create a</translate> {{ $gettext(getFlowType("C", "name")) }} <translate>from this operation...</translate></span
                          >
                        </span>
                      </div>
                    </button>

                    <!-- Duplicate  : unloading  -->
                    <button
                      class="flow-item card card-custom card-fit card-border btn button border-2 border-dashed p-O"
                      v-if="
                        syncedOperation.OperationUID !== 0 &&
                        syncedOperation.OperationID !== 0 &&
                        syncedOperation.OperationType === 'C' &&
                        syncedOperation.MyRole === 'MT' &&
                        syncedOperation.Partners.filter((p) => p.PartnerRole === 'MD').length > 0 &&
                        typeof syncedOperation.Partners.filter((p) => p.PartnerRole === 'MD') !== 'undefined' &&
                        syncedOperation.Partners.filter((p) => p.PartnerRole === 'MD')[0].PartnerID !== 0 &&
                        syncedOperation.Stream.Operations.filter((p) => p.OperationType === 'D').length === 0
                      "
                      v-on:click="onDuplicateForm($event, 'unloading')"
                      :disabled="isDuplicating"
                    >
                      <!-- Body -->
                      <div class="card-body px-5 py-5">
                        <span class="d-flex align-items-center"
                          ><span class="svg-icon svg-icon-3x mr-4" v-if="isDuplicating === false">
                            <inline-svg :src="getFlowType('D', 'icon')"></inline-svg>
                          </span>
                          <span class="mx-4" v-if="isDuplicating === true">
                            <b-spinner variant="opalean-gray-medium" small type="grow" class="mr-4"></b-spinner>
                          </span>
                          <span class="font-weight-bold text-left"
                            ><translate>Create a</translate> {{ $gettext(getFlowType("D", "name")) }} <translate>from this operation...</translate></span
                          >
                        </span>
                      </div>
                    </button>

                    <!-- Duplicate  : unloading for unknown partner  -->
                    <button
                      class="flow-item card card-custom card-fit card-border btn button border-2 border-dashed p-O"
                      v-if="
                        syncedOperation.OperationUID !== 0 &&
                        syncedOperation.OperationID !== 0 &&
                        syncedOperation.OperationType === 'C' &&
                        syncedOperation.MyRole === 'MT' &&
                        syncedOperation.Partners.filter((p) => p.PartnerRole === 'MD').length > 0 &&
                        typeof syncedOperation.Partners.filter((p) => p.PartnerRole === 'MD') !== 'undefined' &&
                        syncedOperation.Partners.filter((p) => p.PartnerRole === 'MD')[0].PartnerID === 0 &&
                        syncedOperation.Partners.filter((p) => p.PartnerRole === 'MD')[0].GlobalID > 0
                      "
                      v-on:click="
                        onDuplicateForm(
                          $event,
                          'unloading',
                          true,
                          syncedOperation.Partners.filter((p) => p.PartnerRole === 'MC')[0].PartnerID,
                          syncedOperation.Partners.filter((p) => p.PartnerRole === 'MD')[0].GlobalID
                        )
                      "
                      :disabled="isDuplicating"
                    >
                      <!-- Body -->
                      <div class="card-body px-5 py-5">
                        <span class="d-flex align-items-center"
                          ><span class="svg-icon svg-icon-3x mr-4" v-if="isDuplicating === false">
                            <inline-svg :src="getFlowType('D', 'icon')"></inline-svg>
                          </span>
                          <span class="mx-4" v-if="isDuplicating === true">
                            <b-spinner variant="opalean-gray-medium" small type="grow" class="mr-4"></b-spinner>
                          </span>
                          <span class="badge badge-sm badge-primary position-absolute top-0 right-0 mr-3 mt-n4">{{ $gettext("Partner to associate") }}</span>
                          <span class="font-weight-bold text-left"
                            ><translate>Create a</translate> {{ $gettext(getFlowType("D", "name")) }} <translate>from this operation...</translate></span
                          >
                        </span>
                      </div>
                    </button>

                    <!-- Transfer  : return  -->
                    <!-- No partners conditions : &&
                        syncedOperation.Partners.filter((p) => p.PartnerRole === 'MD').length > 0 &&
                        typeof syncedOperation.Partners.filter((p) => p.PartnerRole === 'MD') !== 'undefined' &&
                        syncedOperation.Partners.filter((p) => p.PartnerRole === 'MD')[0].PartnerID !== 0 && Has to be a transfert -->
                    <button
                      class="flow-item card card-custom card-fit card-border btn button border-2 border-dashed p-0"
                      v-if="
                        syncedOperation.OperationUID !== 0 &&
                        syncedOperation.OperationID !== 0 &&
                        syncedOperation.OperationType === 'T' &&
                        (syncedOperation.MyRole === 'MC' || syncedOperation.MyRole === 'MD')
                      "
                      v-on:click="onDuplicateForm($event, 'return')"
                      :disabled="isDuplicating"
                    >
                      <!-- Body -->
                      <div class="card-body px-5 py-5">
                        <span class="d-flex align-items-center"
                          ><span class="svg-icon svg-icon-3x mr-4" v-if="isDuplicating === false">
                            <inline-svg src="media/svg/icons/Navigation/Arrow-from-right.svg"></inline-svg>
                          </span>
                          <span class="mx-4" v-if="isDuplicating === true">
                            <b-spinner variant="opalean-gray-medium" small type="grow" class="mr-4"></b-spinner>
                          </span>
                          <span class="font-weight-bold text-left"><translate>Create a return</translate> <translate>from this operation...</translate></span>
                        </span>
                      </div>
                    </button>

                    <!-- New operation : instream -->
                    <button
                      class="flow-item card card-custom card-fit card-border btn button border-2 border-dashed p-0"
                      v-if="syncedOperation.Stream.StreamID !== undefined"
                      v-on:click="onDuplicateForm($event, 'instream')"
                      :disabled="isDuplicating"
                    >
                      <!-- Body -->
                      <div class="card-body px-5 py-5">
                        <span class="d-flex align-items-center"
                          ><span class="svg-icon svg-icon-3x mr-4" v-if="isDuplicating === false">
                            <inline-svg src="media/svg/icons/Navigation/Plus.svg"></inline-svg>
                          </span>
                          <span class="mx-4" v-if="isDuplicating === true">
                            <b-spinner variant="opalean-gray-medium" small type="grow" class="mr-4"></b-spinner>
                          </span>
                          <span class="font-weight-bold text-left"><translate>Create a new operation</translate> <translate>into this flow...</translate></span>
                        </span>
                      </div>
                    </button>
                  </div>
                </perfect-scrollbar>
              </div>

              <!-- Content -->
              <div
                class="order-0 order-sm-1 p-4 rounded-top-right-sm rounded-bottom-right-sm"
                :class="[
                  Object.keys(syncedOperation.Stream.Operations).length > 0 ? 'w-80 w-100-sm' : 'w-100',
                  syncedOperation.formstep > 0 ? 'bg-light-opalean-primary' : 'bg-white',
                ]"
              >
                <!--begin:: Boards -->
                <div
                  class="boards"
                  :class="isMobileDevice ? 'd-flex flex-row justify-content-between align-items-top' : ''"
                  v-show="syncedOperation.formstep > 0"
                >
                  <div
                    class="board-items d-flex flex-wrap flex-md-nowrap --flex-column flex-md-row justify-content-between align-items-start align-items-sm-center py-5"
                    :class="isMobileDevice ? '' : 'border-bottom border-light-opalean-secondary'"
                  >
                    <!--begin:: Board: Flow type -->
                    <div class="board-item mr-2 mr-md-8 mb-3 mb-md-0">
                      <DetailsPanel
                        v-if="syncedOperation.OperationType !== null && typeof syncedOperation.OperationType === 'string'"
                        :svgIconSrc="getFlowType(syncedOperation.OperationType, 'icon')"
                        :inlineIconSrc="getOperationThirdPartyDetails_refactored(syncedOperation, 'thirdparty', 'isClient', null) ? 'flaticon2-correct' : null"
                        :title="getFlowType(syncedOperation.OperationType, 'name')"
                        :titleDescription="getFlowType(syncedOperation.OperationType, 'description')"
                        :subtitle="getOperationThirdPartyDetails_refactored(syncedOperation, 'thirdparty', 'Name', '—')"
                        :subtitleDescription="getOperationThirdPartyDetails_refactored(syncedOperation, 'thirdparty', 'ZipCode,City,CountryISO,District', '—')"
                        :inverted="false"
                      >
                      </DetailsPanel>
                    </div>
                    <!--end:: Board: Flow type -->
                    <!--begin:: Board: Who am i  -->
                    <div class="board-item mr-2 mr-md-8 mb-3 mb-md-0 d-none d-sm-block" v-if="syncedOperation.MyRole !== null">
                      <div class="d-flex">
                        <div class="details ml-2">
                          <span class="d-block font-size-sm font-weight-normal"><translate>Who am i ?</translate></span>
                          <span class="d-block font-size-h5 font-weight-bolder">
                            <b-badge v-if="syncedOperation.MyRole !== null" variant="primary">{{
                              $gettext(getThirdPartyType(syncedOperation.MyRole, "name"))
                            }}</b-badge>
                            {{ getOperationThirdPartyDetails_refactored(syncedOperation, "me", "Name", "—") }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <!--end:: Board: Who am i  -->
                    <!--begin:: Board: Details  -->
                    <!-- Date / ID -->
                    <div class="board-item mr-2 mr-md-8 mb-3 mb-md-0" v-if="syncedOperation.OperationDate !== undefined">
                      <span
                        class="label label-outline-opalean-gray-medium label-inline font-weight-bolder"
                        :class="[
                          syncedOperation.OperationID !== undefined && !isMobileDevice ? 'rounded-right-0' : '',
                          isMobileDevice ? 'label-sm border-width-1 px-1' : 'label-lg border-width-2',
                        ]"
                        v-if="syncedOperation.OperationDate !== undefined"
                        >{{ syncedOperation.OperationDate | getDateFormat("LocaleDate") }}</span
                      >
                      <span
                        class="label label-opalean-gray-medium label-inline font-weight-bold border-width-2 d-none d-sm-inline-flex"
                        :class="[
                          syncedOperation.OperationDate !== undefined && !isMobileDevice ? 'rounded-left-0' : '',
                          isMobileDevice ? 'label-sm border-width-1 px-1' : 'label-lg border-width-2',
                        ]"
                        v-if="syncedOperation.OperationID !== undefined"
                        >{{ syncedOperation.OperationID | safePrint }}</span
                      >
                    </div>
                    <!-- CMR / Ref -->
                    <div
                      class="board-item mr-2 mr-md-8 mb-3 mb-md-0 d-none d-sm-block"
                      v-if="(syncedOperation.OperationCMR !== undefined && syncedOperation.OperationCMR !== '') || syncedOperation.Manifest.length > 0"
                    >
                      <!-- CMR -->
                      <span class="d-inline-block text-primary font-weight-bolder ml-1 mb-0" v-if="syncedOperation.OperationCMR"
                        ><i class="far fa-sticky-note icon-nm text-primary"></i> {{ syncedOperation.OperationCMR | safePrint }}</span
                      >

                      <!-- Manifest -->
                      <div class="ml-1">
                        <span
                          class="label label-sm label-inline label-square font-weight-bold text-opalean-gray-medium px-2 mr-1 h-auto lh-1 label-outline-secondary"
                          v-for="(key, index) in syncedOperation.Manifest"
                          :key="index"
                          :title="key.Type"
                          >{{ key.Value | safePrint }}</span
                        >
                      </div>
                    </div>
                    <!-- Status -->
                    <div class="board-item mr-2 mr-md-8 mb-3 mb-md-0" v-if="syncedOperation.OperationStatus !== undefined">
                      <b-badge
                        v-if="
                          (getOperationThirdPartyDetails_refactored(syncedOperation, 'thirdparty', 'Attributes', '—').includes('isClient') ||
                            syncedOperation.OperationStatus === 'B' ||
                            syncedOperation.OperationStatus === 'L') &&
                          syncedOperation.OperationStatus !== 'R'
                        "
                        pill
                        _style="width: 150px"
                        :variant="getStatusType(syncedOperation.OperationStatus, 'class')"
                        class="font-weight-bolder --font-size-lg text-white"
                        :class="[isMobileDevice ? '--w-100 font-size-xs' : 'w-150px font-size-lg']"
                        >{{ $gettext(getStatusType(syncedOperation.OperationStatus, "name")) }}</b-badge
                      >
                      <i v-else-if="!getOperationThirdPartyDetails_refactored(syncedOperation, 'thirdparty', 'Attributes', '—').includes('isClient')"></i>
                      <i v-else class="text-opalean-primary icon-xxl flaticon2-correct pl-0 pr-5"></i>
                    </div>
                    <!--end:: Board: Details  -->
                  </div>
                  <!--begin:: Board: Flow  -->
                  <div class="board-items d-flex justify-content-between --border-bottom py-5" v-if="syncedOperation.Partners.length != 0 ? true : false">
                    <div class="board-item w-100">
                      <ThirdPartiesPanel
                        :MyRole="syncedOperation.MyRole"
                        :CounterPartRole="
                          getAllowedThirdPartyType(syncedOperation.OperationType, syncedOperation.MyRole, 'thirdparty', syncedOperation.HisRole)[0]
                        "
                        :shipperName="getOperationThirdPartyDetails_refactored(syncedOperation, 'shipper', 'Name')"
                        :carrierName="getOperationThirdPartyDetails_refactored(syncedOperation, 'carrier', 'Name')"
                        :dockName="getOperationThirdPartyDetails_refactored(syncedOperation, 'dock', 'Name')"
                        :receiverName="getOperationThirdPartyDetails_refactored(syncedOperation, 'receiver', 'Name')"
                        :notifiedName="getOperationThirdPartyDetails_refactored(syncedOperation, 'notified', 'Name')"
                        :subShipperName="getOperationThirdPartyDetails_refactored(syncedOperation, 'subshipper', 'Name')"
                        :subCarrierName="getOperationThirdPartyDetails_refactored(syncedOperation, 'subcarrier', 'Name')"
                        :subReceiverName="getOperationThirdPartyDetails_refactored(syncedOperation, 'subreceiver', 'Name')"
                        :editMode="true"
                        :OperationID="syncedOperation.OperationID"
                        :mobile="isMobileDevice"
                        @update="emitThirdPartiesPanel"
                      ></ThirdPartiesPanel>
                    </div>
                  </div>
                  <!--end:: Board: Flow  -->
                  <div class="mb-5" v-if="!isMobileDevice"></div>
                </div>
                <!--end:: Boards -->

                <!--begin:: Flow form -->
                <div id="operationFlow" v-show="syncedOperation.formstep < 1">
                  <b-skeleton-wrapper :loading="typeof Partners === 'undefined' || Partners.length === 0">
                    <!-- Loading -->
                    <template #loading>
                      <div class="my-6 mb-10">
                        <b-skeleton animation="fade" width="100px" class="m-auto"></b-skeleton>
                      </div>
                      <b-row>
                        <b-col>
                          <b-skeleton-img class="rounded" no-aspect height="80px"></b-skeleton-img>
                        </b-col>
                        <b-col>
                          <b-skeleton-img class="rounded" no-aspect height="80px"></b-skeleton-img>
                        </b-col>
                        <b-col>
                          <b-skeleton-img class="rounded" no-aspect height="80px"></b-skeleton-img>
                        </b-col>
                      </b-row>

                      <div class="my-6 mt-10 mb-10">
                        <b-skeleton animation="fade" width="100px" class="m-auto"></b-skeleton>
                      </div>
                      <b-row>
                        <b-col>
                          <b-skeleton-img class="rounded" no-aspect height="80px"></b-skeleton-img>
                        </b-col>
                        <b-col>
                          <b-skeleton-img class="rounded" no-aspect height="80px"></b-skeleton-img>
                        </b-col>
                        <b-col>
                          <b-skeleton-img class="rounded" no-aspect height="80px"></b-skeleton-img>
                        </b-col>
                      </b-row>

                      <div class="my-6 mt-10 mb-10">
                        <b-skeleton animation="fade" width="100px" class="m-auto"></b-skeleton>
                      </div>

                      <b-row>
                        <b-col cols="12" class="mb-10">
                          <b-skeleton-img class="rounded" no-aspect height="80px"></b-skeleton-img>
                        </b-col>
                      </b-row>

                      <div class="my-6 mt-10 mb-10">
                        <b-skeleton animation="fade" width="100px" class="m-auto"></b-skeleton>
                      </div>
                    </template>
                    <!-- Loaded : -->
                    <FlowFormThirdParties
                      ref="flowformthirdparties"
                      v-if="typeof Partners !== 'undefined' && Partners.length > 0"
                      :syncedOperation.sync="syncedOperation"
                      @update="emitData"
                      @update-partners="emitDataPartners"
                      @remove-partners="emitRemovePartners"
                    ></FlowFormThirdParties>
                    <b-col lg="12">
                      <!-- Lock / modify flow -->
                      <b-button
                        block
                        variant="success"
                        size="lg"
                        v-on:click="validateFlow"
                        :disabled="isSubmitting || !haveRequiredThirdParty()"
                        class="font-weight-bolder --text-uppercase"
                      >
                        <b-spinner v-if="isSubmitting === true" variant="light" small type="grow" class="mr-2"></b-spinner>
                        <i class="flaticon2-lock icon-md"></i>
                        {{ syncedOperation.action === "create" ? $gettext("Lock flow") : $gettext("Modify flow") }}
                      </b-button>
                    </b-col>
                  </b-skeleton-wrapper>
                </div>
                <!--end:: Flow form -->

                <!--begin:: Details -->
                <div id="operationDetails" v-show="syncedOperation.formstep > 0" v-if="!(isMobileDevice && syncedOperation.action == 'edit')">
                  <!--begin::Card-->
                  <b-card class="card-custom gutter-b" body-class="px-2 px-md-6">
                    <!--begin::Title-->
                    <h5
                      id="anchorThirdPartyType"
                      class="group-title text-center mb-10"
                      :class="
                        syncedOperation.OperationDate !== undefined &&
                        syncedOperation.OperationDate !== '' &&
                        syncedOperation.OperationCMR !== undefined &&
                        syncedOperation.OperationCMR !== ''
                          ? 'text-primary'
                          : ''
                      "
                    >
                      <span
                        v-if="
                          syncedOperation.OperationDate !== undefined &&
                          syncedOperation.OperationDate !== '' &&
                          syncedOperation.OperationCMR !== undefined &&
                          syncedOperation.OperationCMR !== ''
                        "
                        class="label label-rounded label-md font-weight-bold align-top label-primary"
                        ><i class="flaticon2-check-mark icon-sm text-white"></i
                      ></span>
                      <span v-else class="label label-rounded label-md font-weight-bold align-top label-opalean-gray-light">4</span>
                      &nbsp;<translate>When does the flow start and with which CMR ?</translate>
                    </h5>
                    <!--begin::Form-->
                    <div class="row">
                      <div class="form-group col-12 col-sm-6 mb-0">
                        <label class="font-weight-bold" v-translate for="operationDate">Operation date</label>
                        <!-- <b-form-input
                          id="operationDate"
                          type="date"
                          v-model="syncedOperation.OperationDate"
                          :placeholder="$gettext('Set the operation date')"
                          size="lg"
                          class="font-size-h4"
                        ></b-form-input> -->
                        <!--<b-input-group size="lg" class="">
                          <b-form-datepicker
                            id="operationDate"
                            v-model="syncedOperation.OperationDate"
                            class="font-size-h6"
                            :placeholder="$gettext('Set the operation date')"
                          ></b-form-datepicker>
                        </b-input-group>-->

                        <b-badge class="ml-2" variant="info" v-if="showDataDebug">{{ $language.current }}</b-badge>

                        <b-input-group :size="isMobileDevice ? 'sm' : 'lg'" class="mb-3">
                          <b-form-input
                            id="operationDate"
                            class="font-size-h6"
                            :value="OperationDateFormatted"
                            type="text"
                            :placeholder="$gettext('Set the operation date')"
                            autocomplete="off"
                            @change="onChangeDate"
                            :state="syncedOperation.OperationDate !== undefined && syncedOperation.OperationDate !== 'Invalid date'"
                          ></b-form-input>
                          <b-input-group-append>
                            <b-form-datepicker
                              v-model="OperationDateUTC"
                              button-only
                              today-button
                              :label-today-button="$gettext('Today')"
                              right
                              aria-controls="operationDate-input"
                              @context="onDateContext"
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            ></b-form-datepicker
                            ><!-- value-as-date -->
                          </b-input-group-append>
                          <b-input-group-append>
                            <b-button
                              variant="primary"
                              class="px-3"
                              v-translate
                              @click="
                                () => {
                                  OperationDateUTC = todayDate;
                                  //onChangeDate(todayDate);
                                }
                              "
                              ><i class="flaticon2-calendar-5" :title="$gettext('Today')"
                            /></b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <b-form-text id="operationDate-help" class="d-none d-sm-block" v-translate
                          >Fill the date if not today ( formatted as YYYY-MM-DD ).</b-form-text
                        >
                        <!-- DEBUG -->
                        <div class="card text-info" v-if="showDataDebug">
                          <p class="mb-1">OperationDateUTC: '{{ OperationDateUTC }}'</p>
                          <p class="mb-1">syncedOperation.OperationDate: '{{ syncedOperation.OperationDate }}'</p>
                          <p class="mb-1">Selected: '{{ OperationDateSelected }}'</p>
                          <p class="mb-1">syncedOperation.Creation: '{{ syncedOperation.Creation }}'</p>
                          <p>Formatted: '{{ OperationDateFormatted }}'</p>
                          <p class="mb-1">todayDate: '{{ todayDate }}'</p>
                        </div>
                      </div>
                      <div class="form-group col-12 col-sm-6 mb-0">
                        <label class="font-weight-bold" for="operationCMR"><translate>CMR</translate></label>
                        <b-form-input
                          id="operationCMR"
                          v-model="syncedOperation.OperationCMR"
                          :placeholder="$gettext('XX-000-0000')"
                          :size="isMobileDevice ? 'sm' : 'lg'"
                          class="font-size-h4"
                        ></b-form-input>
                        <b-form-text id="operationCMR-help" class="d-none d-sm-block" v-translate>Fill the transport pallet.</b-form-text>
                      </div>
                    </div>
                  </b-card>
                  <!--end::Card-->
                </div>
                <!--end:: Details -->

                <!--begin:: Statements -->
                <div id="operationStatements" v-show="syncedOperation.formstep > 0">
                  <!--begin::Card-->
                  <b-card class="card-custom gutter-b" body-class="px-2 px-md-6 pt-3 pt-sm-6">
                    <!--begin::Title-->
                    <h5
                      id="anchorFlowType"
                      class="group-title text-center mb-10"
                      :class="haveAnyPallets() && hasStatement ? 'text-primary' : ''"
                      v-if="!isMobileDevice"
                    >
                      <span v-if="haveAnyPallets() && hasStatement" class="label label-rounded label-md font-weight-bold align-top label-primary"
                        ><i class="flaticon2-check-mark icon-sm text-white"></i
                      ></span>
                      <span v-else class="label label-rounded label-md font-weight-bold align-top label-opalean-gray-light">5</span>
                      &nbsp;<translate>What is the pallets statement ?</translate>
                    </h5>
                    <!--begin::Form-->
                    <div id="pallets">
                      <!-- Headers -->
                      <div class="d-flex mt-sm-5">
                        <!-- Pallet -->
                        <div
                          class="py-2 pr-2 d-none d-lg-block"
                          :class="syncedOperation.OperationType === 'T' ? 'w4-7e w-100-sm' : 'w2-7e w-100-sm'"
                          v-if="!isMobileDevice"
                        >
                          <h5 class="font-weight-bold text-muted" v-translate>Pallet</h5>
                        </div>
                        <!-- Own statement -->
                        <div class="p-2 --ml-10 --ml-sm-0" :class="syncedOperation.OperationType === 'T' ? 'w2-7e w2-3e-sm' : 'w3-7e w3-5e-sm'">
                          <h5>
                            <translate>Statement</translate><br />
                            <small class="text-muted">{{ getOperationThirdPartyDetails_refactored(syncedOperation, "me", "Name", "—") }}</small>
                          </h5>
                        </div>
                        <!-- CounterPart statement -->
                        <div
                          class="py-2 pl-2 rounded-top --bg-light-opalean-info"
                          :class="[
                            syncedOperation.OperationType === 'T' ? 'w-7e w-3e-sm' : 'w2-7e w2-5e-sm',
                            syncedOperation.isOS !== undefined && syncedOperation.isOS === true ? 'bg-light-opalean-tertiary' : 'bg-light-opalean-info',
                          ]"
                          v-if="!isMobileDevice"
                        >
                          <h5
                            class="--text-opalean-info"
                            :class="syncedOperation.isOS !== undefined && syncedOperation.isOS === true ? 'text-inverse-opalean-tertiary' : 'text-opalean-info'"
                          >
                            <translate>Statement</translate><br />
                            <small
                              class="opacity-50 --text-opalean-info"
                              :class="
                                syncedOperation.isOS !== undefined && syncedOperation.isOS === true ? 'text-inverse-opalean-tertiary' : 'text-opalean-info'
                              "
                            >
                              {{ getOperationThirdPartyDetails_refactored(syncedOperation, "thirdparty", "Name", "—") }}</small
                            >
                          </h5>
                        </div>
                      </div>
                      <!-- Labels -->
                      <div class="d-flex pb-0" v-if="!isMobileDevice">
                        <!-- Pallet -->
                        <div class="py-2 pr-2 d-none d-lg-block" :class="syncedOperation.OperationType === 'T' ? 'w4-7e w-100-sm' : 'w2-7e w-100-sm'">
                          &nbsp;
                        </div>

                        <!-- Own statement -->
                        <div class="p-2" :class="syncedOperation.OperationType === 'T' ? 'w-7e w-3e-sm' : 'w-7e w-5e-sm'">
                          <p>
                            <small
                              ><i class="fa fa-arrow-circle-down icon-sm mr-2"></i>
                              <span
                                v-if="syncedOperation.OperationType !== 'T'"
                                v-html="getAccountType(syncedOperation.OperationType === 'D' ? 1 : 0, null, 'name', '{0}')"
                              ></span>
                              <span v-else v-html="getAccountType(3, null, 'name', '{0}')"></span>
                            </small>
                          </p>
                        </div>
                        <div class="p-2 w-7e w-5e-sm" v-show="syncedOperation.OperationType !== 'T'">
                          <p>
                            <small><i class="fa fa-arrow-circle-down icon-sm mr-2"></i> <span v-html="getAccountType(2, null, 'name', '{0}')"></span> </small>
                          </p>
                        </div>
                        <div class="p-2" :class="syncedOperation.OperationType === 'T' ? 'w-7e w-3e-sm' : 'w-7e w-5e-sm'">
                          <p>
                            <small
                              ><i class="fa fa-arrow-circle-down icon-sm mr-2"></i>
                              <span v-html="getAccountType(4, null, 'name', '<strong>{0}</strong>')"></span>
                            </small>
                          </p>
                        </div>

                        <!-- CounterPart statement -->
                        <!-- --- isNotClient -->
                        <div
                          class="p-2 --bg-light-opalean-info d-flex align-items-start justify-content-center"
                          :class="[
                            syncedOperation.OperationType === 'T' ? 'w-7e w-3e-sm' : 'w2-7e w2-5e-sm',
                            syncedOperation.OperationType === 'T' && typeof syncedOperation.POVs[getMyPOVIndex()] !== 'undefined'
                              ? [
                                  syncedOperation.POVs[getMyPOVIndex()].Pallets.length <= 1 ? 'min-h-140px' : '',
                                  syncedOperation.POVs[getMyPOVIndex()].Pallets.length == 2 ? 'min-h-80px' : '',
                                  syncedOperation.POVs[getMyPOVIndex()].Pallets.length > 2 ? '' : '',
                                ]
                              : '--NO-POV--',
                            syncedOperation.isOS !== undefined && syncedOperation.isOS === true ? 'bg-light-opalean-tertiary' : 'bg-light-opalean-info',
                          ]"
                          v-if="!getOperationThirdPartyDetails_refactored(syncedOperation, 'thirdparty', 'Attributes', '—').includes('isClient')"
                        >
                          <span class="d-flex align-items-center position-absolute" :class="syncedOperation.OperationType === 'T' ? 'flex-column' : ''"
                            ><span class="svg-icon svg-icon-3x mr-4 svg-icon-opalean-info">
                              <inline-svg src="media/svg/icons/Communication/Add-user.svg" />
                            </span>
                            <span class="text-opalean-info small mt-4" :class="syncedOperation.OperationType === 'T' ? 'w-80px' : 'w-180px'"
                              ><strong><translate>Your partner is not using NeoOpatrace&trade;</translate></strong
                              >, <translate>sponsor him to get real time datas here...</translate></span
                            >
                          </span>
                        </div>
                        <!-- --- isClient -->
                        <div
                          class="p-2 --bg-light-opalean-info"
                          :class="[
                            syncedOperation.OperationType === 'T' ? 'w-7e w-3e-sm' : 'w-7e w2-5e-sm',
                            syncedOperation.isOS !== undefined && syncedOperation.isOS === true ? 'bg-light-opalean-tertiary' : 'bg-light-opalean-info',
                          ]"
                          v-if="getOperationThirdPartyDetails_refactored(syncedOperation, 'thirdparty', 'Attributes', '—').includes('isClient')"
                        >
                          <!-- :class="syncedOperation.OperationType === 'T' ? 'w2-5e-70' : ''" -->
                          <p
                            class="--text-opalean-info"
                            :class="syncedOperation.isOS !== undefined && syncedOperation.isOS === true ? 'text-inverse-opalean-tertiary' : 'text-opalean-info'"
                          >
                            <small
                              ><i
                                class="fa fa-arrow-circle-down icon-sm mr-2 --text-opalean-info"
                                :class="
                                  syncedOperation.isOS !== undefined && syncedOperation.isOS === true ? 'text-inverse-opalean-tertiary' : 'text-opalean-info'
                                "
                              ></i>
                              <span v-html="getAccountType(syncedOperation.OperationType === 'D' ? 1 : 0, null, 'name', '{0}')"></span>
                            </small>
                          </p>
                        </div>
                        <div
                          class="py-2 pl-2 w-7e w-5e-sm --bg-light-opalean-info"
                          :class="syncedOperation.isOS !== undefined && syncedOperation.isOS === true ? 'bg-light-opalean-tertiary' : 'bg-light-opalean-info'"
                          v-show="syncedOperation.OperationType !== 'T'"
                          v-if="getOperationThirdPartyDetails_refactored(syncedOperation, 'thirdparty', 'Attributes', '—').includes('isClient')"
                        >
                          <p
                            class="--text-opalean-info"
                            :class="syncedOperation.isOS !== undefined && syncedOperation.isOS === true ? 'text-inverse-opalean-tertiary' : 'text-opalean-info'"
                          >
                            <small
                              ><i
                                class="fa fa-arrow-circle-down icon-sm mr-2 --text-opalean-info"
                                :class="
                                  syncedOperation.isOS !== undefined && syncedOperation.isOS === true ? 'text-inverse-opalean-tertiary' : 'text-opalean-info'
                                "
                              ></i>
                              <span v-html="getAccountType(2, null, 'name', '{0}')"></span>
                            </small>
                          </p>
                        </div>
                      </div>

                      <!--begin: Statements form -->
                      <div id="statements-form" class="justify-content-md-center" v-for="(POV, pindex) in getMyPOV(syncedOperation.MyRole)" :key="pindex">
                        <!--begin: Statement form : iterative fields -->
                        <div
                          class="d-flex statement-form m-0 flex-column flex-lg-row"
                          v-for="(Pallet, index) in syncedOperation.POVs[getMyPOVIndex()].Pallets"
                          :key="index"
                        >
                          <!-- Pallet -->
                          <div class="align-self-lg-center pallet-label" :class="syncedOperation.OperationType === 'T' ? 'w4-7e w-100-sm' : 'w2-7e w-100-sm'">
                            <div class="d-flex align-items-center">
                              <div class="py-2 pr-2 pl-lg-0 pl-2 w-100 align-self-center">
                                <h5 class="my-2" v-if="Pallet.PalletID !== 0">
                                  <span class="svg-icon svg-icon-md mr-3" :class="getPalletType(getPalletValue(Pallet.PalletID, 'PalletType'), 'class')">
                                    <inline-svg :src="getPalletType(getPalletValue(Pallet.PalletID, 'PalletType'), 'icon')"></inline-svg
                                  ></span>
                                  <a class="font-weight-bolder text-dark --text-hover-primary">{{ Pallet.PalletName }}</a>
                                </h5>
                                <Multiselect
                                  v-else
                                  v-model="syncedOperation.POVs[getMyPOVIndex()].Pallets[index]"
                                  :options="
                                    displayPallets.filter((p) => !syncedOperation.POVs[getMyPOVIndex()].Pallets.map((p) => p.PalletID).includes(p.PalletID))
                                  "
                                  label="PalletName"
                                  track-by="PalletName"
                                  :placeholder="$gettext('Select a pallet...')"
                                  :show-labels="false"
                                  @select="
                                    (selectedOption) => {
                                      flushPOVbyIndex(selectedOption, index);
                                      syncedOperation.POVs[getMyPOVIndex()].Pallets.filter((p) => p.PalletID == selectedOption.PalletID).FP = '888';
                                      syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FR = '666';

                                      $set(syncedOperation.POVs[getMyPOVIndex()].Pallets[index], 'FP', 999);
                                    }
                                  "
                                ></Multiselect>
                                <!-- Map to do not includes Pallet already present in statement -->
                              </div>
                              <div class="h6 w-60px text-opalean-gray-medium" v-if="isMobileDevice && syncedOperation.action === 'edit'">
                                {{
                                  syncedOperation.action === "create"
                                    ? syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FP
                                    : getThirdPartyPOVValue(
                                        getAllowedThirdPartyType(syncedOperation.OperationType, syncedOperation.MyRole, "thirdparty")[0],
                                        index,
                                        "FP",
                                        syncedOperation.POVs[getMyPOVIndex()].Pallets[index].PalletID
                                      )
                                }}
                                /
                                {{
                                  syncedOperation.action === "create"
                                    ? syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FR
                                    : getThirdPartyPOVValue(
                                        getAllowedThirdPartyType(syncedOperation.OperationType, syncedOperation.MyRole, "thirdparty")[0],
                                        index,
                                        "FR",
                                        syncedOperation.POVs[getMyPOVIndex()].Pallets[index].PalletID
                                      )
                                }}
                              </div>
                            </div>
                          </div>

                          <!-- Items statement -->
                          <div
                            class="d-flex align-self-center statement-items --remove-spin-buttons"
                            :class="syncedOperation.OperationType === 'T' ? 'w3-7e w-100-sm' : 'w5-7e w-100-sm'"
                          >
                            <!-- Own items -->
                            <!-- :class="syncedOperation.OperationType === 'T' ? 'w2-5e' : ''" -->
                            <div
                              class="p-2 pl-0 statement-item"
                              :class="[syncedOperation.OperationType === 'T' ? 'w2-5e' : 'w-5e', isMobileDevice ? 'w-50' : '']"
                            >
                              <!-- NEW -->
                              <code v-if="showDataDebug">
                                <!-- POV
                                {{ syncedOperation.POVs[getMyPOVIndex()].Pallets[index] }} -->
                                lastFP:{{ syncedOperation.POVs[getMyPOVIndex()].Pallets[index].lastFP }}

                                FP:{{ syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FP }}

                                3rdFP{{
                                  syncedOperation.OperationStatus === "M" &&
                                  typeof syncedOperation.POVs[getThirdPartyPOVIndex()] != "undefined" &&
                                  typeof syncedOperation.POVs[getThirdPartyPOVIndex()].Pallets[index] != "undefined"
                                    ? syncedOperation.POVs[getThirdPartyPOVIndex()].Pallets[index].FP
                                    : "undefined"
                                }}

                                Validated?{{ syncedOperation.POVs[getMyPOVIndex()].Pallets[index].ValidatedFP }}

                                type:: {{ typeof syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FP }}
                              </code>

                              <b-input-group>
                                <b-input-group-prepend size="lg" is-text v-if="syncedOperation.OperationStatus === 'M'">
                                  <b-form-checkbox
                                    class="checkbox-info --mr-n2 ml-n1 mr-n3"
                                    size="lg"
                                    v-model="syncedOperation.POVs[getMyPOVIndex()].Pallets[index].ValidatedFP"
                                    @input="
                                      (checked) => {
                                        validateFPFR(checked, index);
                                      }
                                    "
                                  >
                                    <!-- <i class="la la-check-circle icon-xl m-O p-0"></i> -->
                                    <span class="sr-only"><translate>Validate this statement</translate></span>
                                  </b-form-checkbox>
                                </b-input-group-prepend>
                                <!-- ### -->
                                <!-- 
                                  MY FP
                                -->
                                <FormSpinbuttonWithInput
                                  v-if="isMobileDevice"
                                  :id="`statement-item-loaded-${index}`"
                                  :class="[
                                    syncedOperation.OperationStatus === 'M' && syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FP != null
                                      ? 'text-info border-info border-2'
                                      : 'text-dark-50',
                                    Pallet.PalletID === 0 ? 'text-dark-25' : '',
                                    'p-2',
                                  ]"
                                  :elClass="[
                                    syncedOperation.OperationStatus === 'M' && syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FP != null
                                      ? 'text-info'
                                      : 'text-dark-75',
                                    Pallet.PalletID === 0 ? 'text-dark-25' : '',
                                    isMobileDevice ? 'font-size-h1' : 'font-size-h4',
                                    'font-weight-bold',
                                  ]"
                                  :value="syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FP"
                                  :defaultValue="
                                    syncedOperation.OperationStatus === 'M' &&
                                    typeof syncedOperation.POVs[getThirdPartyPOVIndex()] != 'undefined' &&
                                    typeof syncedOperation.POVs[getThirdPartyPOVIndex()].Pallets[index] != 'undefined'
                                      ? syncedOperation.POVs[getThirdPartyPOVIndex()].Pallets[index].FP
                                      : undefined
                                  "
                                  :min="0"
                                  :max="9999"
                                  :placeholder="
                                    syncedOperation.OperationStatus !== 'M'
                                      ? $gettext('0')
                                      : `(${syncedOperation.POVs[getThirdPartyPOVIndex()].Pallets[index].FP.toString()})`
                                  "
                                  :disabled="Pallet.PalletID === 0"
                                  :state="getStatementState(index)"
                                  :repeatStepMultiplier="5"
                                  @change="
                                    (data) => {
                                      // syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FP = data.value;

                                      // if (data.event === 'change') {
                                      //   if (syncedOperation.OperationStatus === 'M')
                                      //     syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FP =
                                      //       data.value != syncedOperation.POVs[getThirdPartyPOVIndex()].Pallets[index].FP ? Number(data.value) : null;
                                      //   else syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FP = Number(data.value);

                                      //   validateFPFR(syncedOperation.POVs[getMyPOVIndex()].Pallets[index].ValidatedFP, index, data.value, 'FP', data.event);
                                      // }

                                      if (data.event === 'change')
                                        validateFPFR(syncedOperation.POVs[getMyPOVIndex()].Pallets[index].ValidatedFP, index, data.value, 'FP', data.event);
                                    }
                                  "
                                />

                                <!-- <b-form-spinbutton
                                  v-if="isMobileDevice && false"
                                  vertical
                                  :id="`statement-item-loaded-${index}`"
                                  min="1"
                                  max="999"
                                  :placeholder="
                                    syncedOperation.OperationStatus !== 'M'
                                      ? $gettext('0')
                                      : `(${syncedOperation.POVs[getThirdPartyPOVIndex()].Pallets[index].FP.toString()})`
                                  "
                                  size="lg"
                                  class="font-weight-bold d-none"
                                  :class="[
                                    syncedOperation.OperationStatus === 'M' && syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FP != null
                                      ? 'text-info border-info border-2 px-2'
                                      : 'text-dark-50 px-2',
                                    Pallet.PalletID === 0 ? 'text-dark-25' : '',
                                    isMobileDevice ? 'font-size-h1' : 'font-size-h4',
                                  ]"
                                  :disabled="Pallet.PalletID === 0"
                                  v-model="syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FP"
                                  :state="getStatementState(index)"
                                  @input="
                                    (value) => {
                                      // syncedOperation.POVs[getMyPOVIndex()].Pallets[index].lastFP =
                                      //   syncedOperation.POVs[getThirdPartyPOVIndex()].Pallets[index].FP + value;
                                    }
                                  "
                                  @change="
                                    (value, $event) => {
                                      validateFPFR(syncedOperation.POVs[getMyPOVIndex()].Pallets[index].ValidatedFP, index, value, 'FP', $event);
                                    }
                                  "
                                  @click.native="test_keydown_handler"
                                ></b-form-spinbutton>
 -->
                                <b-form-input
                                  v-if="!isMobileDevice"
                                  :id="`statement-item-loaded-${index}`"
                                  type="text"
                                  v-mask="{ regex: '[-]?\\d+', allowMinus: true }"
                                  :placeholder="
                                    syncedOperation.OperationStatus !== 'M'
                                      ? $gettext('0')
                                      : `(${syncedOperation.POVs[getThirdPartyPOVIndex()].Pallets[index].FP.toString()})`
                                  "
                                  required
                                  size="lg"
                                  class="font-weight-bold"
                                  :class="[
                                    syncedOperation.OperationStatus === 'M' ? 'text-info border-info border-2 px-2' : '',
                                    Pallet.PalletID === 0 ? 'text-dark-25' : '',
                                    isMobileDevice ? 'font-size-h1' : 'font-size-h4',
                                  ]"
                                  :disabled="Pallet.PalletID === 0"
                                  v-model="syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FP"
                                  :state="getStatementState(index)"
                                  @change="
                                    (value) => {
                                      validateFPFR(syncedOperation.POVs[getMyPOVIndex()].Pallets[index].ValidatedFP, index, value);
                                    }
                                  "
                                ></b-form-input>

                                <!-- <b-input-group-append size="lg" is-text v-if="syncedOperation.OperationStatus === 'M' && isMobileDevice">
                                  <b-form-checkbox
                                    class="checkbox-danger --mr-n2 ml-n1 mr-n3"
                                    size="lg"
                                    @input="
                                      (checked) => {
                                        validateFPFR(checked, index);
                                      }
                                    "
                                    ><i class="la la-exclamation-circle icon-xl m-O p-0 text-danger"></i
                                    ><span class="sr-only"><translate>Reject this statement</translate></span></b-form-checkbox
                                  >
                                </b-input-group-append> -->
                              </b-input-group>
                            </div>
                            <div class="p-2 --w-5e statement-item" v-show="syncedOperation.OperationType !== 'T'" :class="isMobileDevice ? 'w-50' : 'w-5e'">
                              <!-- NEW -->
                              <code v-if="showDataDebug">
                                <!-- POV
                                {{ syncedOperation.POVs[getMyPOVIndex()].Pallets[index] }} -->
                                lastFR:{{ syncedOperation.POVs[getMyPOVIndex()].Pallets[index].lastFR }}

                                FR:{{ syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FR }}

                                3rdFP{{
                                  syncedOperation.OperationStatus === "M" &&
                                  typeof syncedOperation.POVs[getThirdPartyPOVIndex()] != "undefined" &&
                                  typeof syncedOperation.POVs[getThirdPartyPOVIndex()].Pallets[index] != "undefined"
                                    ? syncedOperation.POVs[getThirdPartyPOVIndex()].Pallets[index].FR
                                    : "undefined"
                                }}

                                Validated?{{ syncedOperation.POVs[getMyPOVIndex()].Pallets[index].ValidatedFR }}

                                type:: {{ typeof syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FR }}
                              </code>

                              <b-input-group>
                                <b-input-group-prepend
                                  size="lg"
                                  is-text
                                  v-if="!['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) && syncedOperation.OperationStatus === 'M'"
                                >
                                  <b-form-checkbox
                                    class="checkbox-info --mr-n2 ml-n1 mr-n3"
                                    size="lg"
                                    v-model="syncedOperation.POVs[getMyPOVIndex()].Pallets[index].ValidatedFR"
                                    @input="
                                      (checked) => {
                                        validateFPFR(checked, index, null, 'FR');
                                      }
                                    "
                                  >
                                    <span class="sr-only">Validate this statement</span>
                                  </b-form-checkbox>
                                </b-input-group-prepend>
                                <!-- ### -->
                                <!-- 
                                  MY FR
                                -->

                                <FormSpinbuttonWithInput
                                  v-if="isMobileDevice"
                                  :id="`statement-item-returned-${index}`"
                                  :class="[
                                    ['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) || Pallet.PalletID === 0 ? 'text-dark-25' : '',
                                    !['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) &&
                                    syncedOperation.OperationStatus === 'M' &&
                                    syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FR != null
                                      ? 'text-info border-info border-2'
                                      : 'text-dark-50',
                                    Pallet.PalletID === 0 ? 'text-dark-25' : '',
                                    'p-2',
                                  ]"
                                  :elClass="[
                                    ['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) || Pallet.PalletID === 0 ? 'text-dark-25' : '',
                                    !['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) &&
                                    syncedOperation.OperationStatus === 'M' &&
                                    syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FR != null
                                      ? 'text-info'
                                      : 'text-dark-75',
                                    Pallet.PalletID === 0 ? 'text-dark-25' : '',
                                    isMobileDevice ? 'font-size-h1' : 'font-size-h4',
                                    'font-weight-bold',
                                  ]"
                                  :value="syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FR"
                                  :defaultValue="
                                    syncedOperation.OperationStatus === 'M' &&
                                    typeof syncedOperation.POVs[getThirdPartyPOVIndex()] != 'undefined' &&
                                    typeof syncedOperation.POVs[getThirdPartyPOVIndex()].Pallets[index] != 'undefined'
                                      ? syncedOperation.POVs[getThirdPartyPOVIndex()].Pallets[index].FR
                                      : undefined
                                  "
                                  :min="0"
                                  :max="9999"
                                  :placeholder="
                                    !['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) && syncedOperation.OperationStatus === 'M'
                                      ? `(${syncedOperation.POVs[getThirdPartyPOVIndex()].Pallets[index].FR.toString()})`
                                      : $gettext('0')
                                  "
                                  :disabled="['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) || Pallet.PalletID === 0"
                                  :state="!['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) ? getStatementState(index, 'FR') : null"
                                  :repeatStepMultiplier="5"
                                  @change="
                                    (data) => {
                                      if (data.event === 'change')
                                        validateFPFR(syncedOperation.POVs[getMyPOVIndex()].Pallets[index].ValidatedFR, index, data.value, 'FR', data.event);
                                    }
                                  "
                                />

                                <!-- <b-form-spinbutton
                                  v-if="isMobileDevice"
                                  vertical
                                  :id="`statement-item-returned-${index}`"
                                  min="1"
                                  max="999"
                                  :placeholder="
                                    !['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) && syncedOperation.OperationStatus === 'M'
                                      ? `(${syncedOperation.POVs[getThirdPartyPOVIndex()].Pallets[index].FR.toString()})`
                                      : $gettext('0')
                                  "
                                  size="lg"
                                  class="font-weight-bold"
                                  :class="[
                                    ['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) || Pallet.PalletID === 0 ? 'text-dark-25' : '',
                                    !['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) &&
                                    syncedOperation.OperationStatus === 'M' &&
                                    syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FR != null
                                      ? 'text-info border-info border-2 px-2'
                                      : 'text-dark-50 px-2',
                                    isMobileDevice ? 'font-size-h1' : 'font-size-h4',
                                  ]"
                                  :disabled="['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) || Pallet.PalletID === 0"
                                  v-model="syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FR"
                                  :state="!['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) ? getStatementState(index, 'FR') : null"
                                  @change="
                                    (value) => {
                                      validateFPFR(syncedOperation.POVs[getMyPOVIndex()].Pallets[index].ValidatedFR, index, value, 'FR');
                                    }
                                  "
                                ></b-form-spinbutton> -->

                                <b-form-input
                                  v-if="!isMobileDevice"
                                  :id="`statement-item-returned-${index}`"
                                  type="text"
                                  v-mask="{ regex: '[-]?\\d+', allowMinus: true }"
                                  :placeholder="
                                    !['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) && syncedOperation.OperationStatus === 'M'
                                      ? `(${syncedOperation.POVs[getThirdPartyPOVIndex()].Pallets[index].FR.toString()})`
                                      : $gettext('0')
                                  "
                                  required
                                  size="lg"
                                  class="font-weight-bold"
                                  :class="[
                                    ['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) || Pallet.PalletID === 0 ? 'text-dark-25' : '',
                                    !['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) && syncedOperation.OperationStatus === 'M'
                                      ? 'text-info border-info border-2 px-2'
                                      : '',
                                    isMobileDevice ? 'font-size-h2' : 'font-size-h4',
                                  ]"
                                  :disabled="['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) || Pallet.PalletID === 0"
                                  v-model="syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FR"
                                  :state="!['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) ? getStatementState(index, 'FR') : null"
                                  @change="
                                    (value) => {
                                      validateFPFR(syncedOperation.POVs[getMyPOVIndex()].Pallets[index].ValidatedFR, index, value, 'FR');
                                    }
                                  "
                                ></b-form-input>
                              </b-input-group>
                            </div>
                            <div class="p-2 statement-item" :class="syncedOperation.OperationType === 'T' ? 'w2-5e' : 'w-5e'" v-if="!isMobileDevice">
                              <!-- 
                                MY IA
                              -->
                              <b-form-input
                                :id="`statement-item-accountImpact-${index}`"
                                type="text"
                                :placeholder="$gettext('0')"
                                size="lg"
                                class="font-size-h4 font-weight-bold"
                                :class="[
                                  getImpactAccount(
                                    syncedOperation.OperationType,
                                    syncedOperation.MyRole,
                                    syncedOperation.HisRole,
                                    syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FP,
                                    syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FR
                                  ) < 0
                                    ? 'text-info'
                                    : 'text-opalean-info',
                                  ['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) ? 'text-dark-25' : '',
                                ]"
                                :value="
                                  ['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType'))
                                    ? 0
                                    : getImpactAccount(
                                        syncedOperation.OperationType,
                                        syncedOperation.MyRole,
                                        syncedOperation.HisRole,
                                        syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FP,
                                        syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FR
                                      )
                                "
                                :disabled="true"
                              ></b-form-input>
                            </div>
                            <!--
                          <div class="p-2 w-7e bg-light-warning statement-item" :class="index < POVs.Pallets.length - 1 ? '' : 'rounded-bottom-left'">
                            <b-form-input
                              :id="`statement-item-corrective-${index}`"
                              type="number"
                              :placeholder="$gettext('—')"
                              required
                              size="lg"
                              class="font-size-h4 font-weight-bold"
                              v-model="POVs.Pallets[index].corrective"
                            ></b-form-input>
                          </div>
                          <div class="p-2 w-7e bg-light-warning statement-item" :class="index < POVs.Pallets.length - 1 ? '' : 'rounded-bottom-left'">
                            <b-form-input
                              :id="`statement-item-stockImpact-${index}`"
                              type="number"
                              :placeholder="$gettext('—')"
                              required
                              size="lg"
                              class="font-size-h4 font-weight-bold"
                              v-model="POVs.Pallets[index].stockImpact"
                            ></b-form-input>
                          </div>
                          -->
                            <!-- Third party items -->
                            <div
                              class="p-2 --bg-light-opalean-info statement-item"
                              :class="[
                                index < syncedOperation.POVs[getMyPOVIndex()].Pallets.length - 1 ? '' : 'rounded-bottom-left rounded-bottom-right',
                                syncedOperation.OperationType === 'T' ? 'w2-5e' : 'w-5e',
                                syncedOperation.isOS !== undefined && syncedOperation.isOS === true ? 'bg-light-opalean-tertiary' : 'bg-light-opalean-info',
                              ]"
                              v-if="!isMobileDevice"
                            >
                              <!-- syncedOperation.OperationType === 'T' ? '--w2-5e' : '' -->
                              <!-- 
                                TP FP
                              -->
                              <b-form-input
                                :id="`statement-item-thirdPartyLoaded-${index}`"
                                type="text"
                                :placeholder="$gettext('0')"
                                size="lg"
                                class="font-size-h4 font-weight-bold text-opalean-info"
                                :disabled="true"
                                :value="
                                  syncedOperation.action === 'create'
                                    ? syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FP
                                    : getThirdPartyPOVValue(
                                        getAllowedThirdPartyType(
                                          syncedOperation.OperationType,
                                          syncedOperation.MyRole,
                                          'thirdparty',
                                          syncedOperation.HisRole
                                        )[0],
                                        index,
                                        'FP',
                                        syncedOperation.POVs[getMyPOVIndex()].Pallets[index].PalletID
                                      )
                                "
                                v-if="getOperationThirdPartyDetails_refactored(syncedOperation, 'thirdparty', 'Attributes', '—').includes('isClient')"
                                :state="getStatementState(index)"
                              ></b-form-input>
                              <!--     v-model="
                                  syncedOperation.POVs.filter(
                                    p => p.Role === getAllowedThirdPartyType(syncedOperation.OperationType, syncedOperation.MyRole, 'thirdparty')
                                  ).Pallets[index].FP
                                "-->
                            </div>
                            <div
                              class="p-2 w-5e --bg-light-opalean-info statement-item"
                              v-show="syncedOperation.OperationType !== 'T'"
                              :class="[
                                index < syncedOperation.POVs[getMyPOVIndex()].Pallets.length - 1 ? '' : 'rounded-bottom-right',
                                syncedOperation.isOS !== undefined && syncedOperation.isOS === true ? 'bg-light-opalean-tertiary' : 'bg-light-opalean-info',
                              ]"
                              v-if="!isMobileDevice"
                            >
                              <!-- 
                                TP FR
                              -->
                              <b-form-input
                                :id="`statement-item-thirdPartyReturned-${index}`"
                                type="text"
                                :placeholder="$gettext('0')"
                                size="lg"
                                class="font-size-h4 font-weight-bold"
                                :class="[['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) ? 'text-dark-25' : 'text-opalean-info']"
                                :disabled="true"
                                :value="
                                  syncedOperation.action === 'create'
                                    ? syncedOperation.POVs[getMyPOVIndex()].Pallets[index].FR
                                    : getThirdPartyPOVValue(
                                        getAllowedThirdPartyType(
                                          syncedOperation.OperationType,
                                          syncedOperation.MyRole,
                                          'thirdparty',
                                          syncedOperation.HisRole
                                        )[0],
                                        index,
                                        'FR',
                                        syncedOperation.POVs[getMyPOVIndex()].Pallets[index].PalletID
                                      )
                                "
                                v-if="getOperationThirdPartyDetails_refactored(syncedOperation, 'thirdparty', 'Attributes', '—').includes('isClient')"
                                :state="!['P', 'L'].includes(getPalletValue(Pallet.PalletID, 'PalletType')) ? getStatementState(index, 'FR') : null"
                              ></b-form-input>
                              <!--     v-model="
                                  syncedOperation.POVs.filter(
                                    p => p.Role === getAllowedThirdPartyType(syncedOperation.OperationType, syncedOperation.MyRole, 'thirdparty')
                                  ).Pallets[index].FR
                                "-->
                            </div>
                          </div>
                        </div>
                        <!--end: Statement form : iterative fields -->

                        <b-col lg="12">
                          <!-- Add -->
                          <b-button
                            block
                            variant="outline-opalean-gray-medium"
                            size="lg"
                            class="btn-hover-primary font-weight-bold --text-uppercase"
                            @click="addStatement()"
                            v-if="
                              syncedOperation.OperationStatus !== 'M' &&
                              displayPallets.filter((p) => !syncedOperation.POVs[getMyPOVIndex()].Pallets.map((p) => p.PalletID).includes(p.PalletID)).length >
                                0
                            "
                            ><i class="flaticon2-plus icon-md mt-n1"></i> <translate>Add a pallet statement</translate></b-button
                          >
                          <!-- Map is used to not show button if no pallets are available -->

                          <!-- Submit + fullyLoaded + isFetching -->
                          <b-button
                            block
                            variant="success"
                            size="lg"
                            class="font-weight-bolder --text-uppercase"
                            v-on:click="onSubmitForm"
                            :disabled="
                              isFetching ||
                              isSubmitting ||
                              !haveRequiredThirdParty() ||
                              syncedOperation.OperationDate === undefined ||
                              syncedOperation.OperationDate === 'Invalid date'
                            "
                            v-if="
                              fullyLoaded &&
                              syncedOperation.formstep > 0 &&
                              (hashedInitialSyncedOperation !== hashedSyncedOperation || syncedOperation.OperationStatus === 'B') &&
                              (syncedOperation.action === 'create' ? hasStatement : true)
                            "
                          >
                            <span class="navi-icon mr-2" v-if="!isFetching">
                              <i class="far fa-edit --text-danger" v-if="syncedOperation.action === 'edit'"></i>
                              <i class="far fa-plus-square --text-success" v-else></i>
                            </span>
                            <b-spinner v-if="isFetching || isSubmitting" variant="opalean-gray-medium" small type="grow" class="mr-2"></b-spinner>
                            {{ syncedOperation.action === "create" ? $gettext("Submit") : $gettext("Save") }}
                          </b-button>

                          <div class="card card-custom card-stretch --gutter-b card-scroll p-4 text-info" v-if="showDataDebug">
                            <strong>Do not show if statement == 0</strong>
                            #Both
                            {{ hasStatement }}
                            <hr />
                            <strong>syncedOperation.formstep</strong>
                            {{ syncedOperation.formstep }}
                            <hr />
                            <strong>Operation object has been modified ?</strong>
                            {{ hashedInitialSyncedOperation !== hashedSyncedOperation }}
                          </div>

                          <!-- Validate -->
                          <b-button
                            block
                            variant="info"
                            size="lg"
                            v-on:click="validateStatement"
                            :disabled="isValidating"
                            class="font-weight-bolder --text-uppercase"
                            v-if="
                              syncedOperation.formstep > 0 &&
                              syncedOperation.action === 'edit' &&
                              syncedOperation.OperationStatus === 'M' &&
                              hashedInitialSyncedOperation === hashedSyncedOperation
                            "
                          >
                            <b-spinner v-if="isValidating === true" variant="opalean-gray-medium" small type="grow" class="mr-2"></b-spinner>
                            <i class="flaticon2-check-mark icon-md"></i>
                            <translate>Validate all statements</translate>
                          </b-button>
                        </b-col>
                      </div>
                      <!--end: Statements form -->
                    </div>
                  </b-card>
                  <!--end::Card-->
                </div>
                <!--begin:: Statements -->

                <!--begin:: Shared notes -->
                <div id="operationNotes" v-show="syncedOperation.formstep > 1" v-if="!isMobileDevice">
                  <!--begin::Card-->
                  <b-card class="card-custom gutter-b" body-class="px-2 px-md-6">
                    <!--begin::Title-->
                    <h5 id="anchorNotes" class="group-title text-center mb-10" :class="haveAnyNotes() ? 'text-primary' : ''">
                      <span v-if="haveAnyNotes()" class="label label-rounded label-md font-weight-bold align-top label-primary"
                        ><i class="flaticon2-check-mark icon-sm text-white"></i
                      ></span>
                      <span v-else class="label label-rounded label-md font-weight-bold align-top label-opalean-gray-light">5</span>
                      &nbsp;<translate>Do you have something to note about this operation ?</translate>
                    </h5>
                    <!--end::Title-->

                    <!--begin::Document -->
                    <div
                      id="operationDocuments"
                      class="row"
                      v-if="typeof syncedOperation.Documents !== 'undefined' && Object.keys(syncedOperation.Documents).length > 0"
                    >
                      <!-- My document -->
                      <div class="col" v-if="Object.keys(getDocumentsByPOV('me')).length > 0">
                        <!--begin::Card-->
                        <div
                          class="card card-custom gutter-b card-stretch"
                          :class="
                            getDocumentsByPOV('thirdparty').DocumentGetURL !== undefined && getDocumentsByPOV('me').SenderPartnerID !== 0
                              ? 'border border-2 border-opalean-primary'
                              : 'border-dashed border-2 border-opalean-primary'
                          "
                        >
                          <div class="card-header border-0">
                            <h5 class="card-title font-weight-bolder text-dark-75" v-translate>My document</h5>
                            <div class="card-toolbar">
                              <button
                                type="button"
                                class="btn btn-outline-primary btn-hover-primary text-hover-white p-2 mr-2"
                                v-b-tooltip.hover.bottom="'Replace document'"
                                v-if="
                                  getDocumentsByPOV('me').DocumentPutURL !== '' &&
                                  getDocumentsByPOV('me').DocumentPutURL !== null &&
                                  getDocumentsByPOV('me').SenderPartnerID !== 0
                                "
                                :disabled="isUploadingDoc"
                                @click="
                                  getDocumentsByPOV('me').DocumentPutURL !== '' &&
                                  getDocumentsByPOV('me').DocumentPutURL !== null &&
                                  getDocumentsByPOV('me').SenderPartnerID !== 0
                                    ? $refs.FilesInput.$refs.input.click()
                                    : null
                                "
                              >
                                <span class="svg-icon svg-icon-primary m-0 handle"><inline-svg src="media/svg/icons/Design/Edit.svg"></inline-svg> </span>
                                <span class="visually-hidden-focusable sr-only"><translate>Replace document</translate></span>
                              </button>
                              <button
                                type="button"
                                class="btn btn-outline-danger btn-hover-danger text-hover-white p-2"
                                v-b-tooltip.hover.bottom="'Delete document'"
                                v-if="getDocumentsByPOV('me').DocumentDelURL !== '' && getDocumentsByPOV('me').DocumentDelURL !== null"
                                :disabled="isUploadingDoc"
                                @click="
                                  getDocumentsByPOV('me').DocumentDelURL !== '' && getDocumentsByPOV('me').DocumentDelURL !== null
                                    ? deleteDocument(getDocumentsByPOV('me').DocumentDelURL)
                                    : null
                                "
                              >
                                <span class="svg-icon svg-icon-danger m-0 handle"><inline-svg src="media/svg/icons/Home/Trash.svg"></inline-svg> </span>
                                <span class="visually-hidden-focusable sr-only"><translate>Delete document</translate></span>
                              </button>
                            </div>
                          </div>

                          <div class="card-body pt-2 pb-10">
                            <div class="d-flex flex-column align-items-center">
                              <!--begin: Icon-->
                              <a
                                :href="
                                  getDocumentsByPOV('me').DocumentGetURL !== '' &&
                                  getDocumentsByPOV('me').DocumentGetURL !== null &&
                                  getDocumentsByPOV('me').SenderPartnerID !== 0
                                    ? getDocumentsByPOV('me').DocumentGetURL
                                    : null
                                "
                                target="_blank"
                                class="cursor-pointer"
                                @click="
                                  !isUploadingDoc &&
                                  getDocumentsByPOV('me').DocumentPutURL !== '' &&
                                  getDocumentsByPOV('me').DocumentPutURL !== null &&
                                  getDocumentsByPOV('me').SenderPartnerID == 0
                                    ? $refs.FilesInput.$refs.input.click()
                                    : null
                                "
                              >
                                <span class="svg-icon svg-icon-opalean-primary svg-icon-4x m-0 handle"
                                  ><inline-svg
                                    :src="
                                      getDocumentsByPOV('me').DocumentGetURL !== '' &&
                                      getDocumentsByPOV('me').DocumentGetURL !== null &&
                                      getDocumentsByPOV('me').SenderPartnerID !== 0
                                        ? 'media/svg/icons/Files/Selected-file.svg'
                                        : 'media/svg/icons/Navigation/Plus.svg'
                                    "
                                  ></inline-svg>
                                </span>
                                <!--end: Icon-->
                                <!--begin: Tite-->
                                <span class="text-primary font-weight-bolder font-size-lg ml-2">
                                  {{
                                    getDocumentsByPOV("me").DocumentGetURL !== "" &&
                                    getDocumentsByPOV("me").DocumentGetURL !== null &&
                                    getDocumentsByPOV("me").SenderPartnerID !== 0
                                      ? $gettext("Download document")
                                      : $gettext("Add document")
                                  }}</span
                                ></a
                              >
                              <!--end: Tite-->
                            </div>
                          </div>
                        </div>
                        <!--end:: Card-->
                      </div>
                      <!-- Other document : thirdparty-->
                      <div class="--col-xl-6 --col-lg-6 --col-md-6 col-sm-6" v-if="getDocumentsByPOV('thirdparty').SenderPartnerID !== 0" v-show="false">
                        <!-- Object.keys(getDocumentsByPOV('thirdparty')).length > 0 &&  -->
                        <!--begin::Card-->
                        <div
                          class="card card-custom gutter-b card-stretch card-bordered"
                          :class="
                            getDocumentsByPOV('thirdparty').DocumentGetURL !== undefined
                              ? 'border border-2 border-warning'
                              : 'border-dashed border-2 border-opalean-gray-medium'
                          "
                        >
                          <div class="card-header border-0">
                            <h5
                              class="card-title"
                              :class="getDocumentsByPOV('thirdparty').DocumentGetURL !== undefined ? 'text-warning' : 'text-dark-75'"
                              v-if="$language.current === 'en_EN'"
                            >
                              <strong>{{ getPartnerByPartnerID(getDocumentsByPOV("thirdparty").SenderPartnerID) | getSafeObjectValue("Name") }}</strong
                              ><translate>'s document</translate>
                            </h5>
                            <h5
                              class="card-title"
                              :class="getDocumentsByPOV('thirdparty').DocumentGetURL !== undefined ? 'text-warning' : 'text-dark-75'"
                              v-else
                            >
                              <translate>'s document</translate>&nbsp;
                              <strong>{{ getPartnerByPartnerID(getDocumentsByPOV("thirdparty").SenderPartnerID) | getSafeObjectValue("Name") }}</strong>
                            </h5>
                            <div class="card-toolbar"></div>
                          </div>
                          <div class="card-body pt-2 pb-10">
                            <div class="d-flex flex-column align-items-center">
                              <!--begin: Icon-->
                              <a
                                :href="getDocumentsByPOV('thirdparty').DocumentGetURL !== undefined ? getDocumentsByPOV('thirdparty').DocumentGetURL : null"
                                :class="getDocumentsByPOV('thirdparty').DocumentGetURL !== undefined ? 'text-warning' : 'text-dark-75'"
                                target="_blank"
                              >
                                <span class="svg-icon svg-icon-opealean-gray-medium svg-icon-4x m-0 handle"
                                  ><inline-svg
                                    :src="
                                      getDocumentsByPOV('thirdparty').DocumentGetURL !== undefined
                                        ? 'media/svg/icons/Files/Selected-file.svg'
                                        : 'media/svg/icons/Code/Stop.svg'
                                    "
                                  ></inline-svg>
                                </span>
                                <!--end: Icon-->
                                <!--begin: Tite-->
                                <span class="text-dark-75 font-weight-bold font-size-lg ml-4">{{
                                  getDocumentsByPOV("thirdparty").DocumentGetURL !== undefined ? $gettext("Download document") : $gettext("No document")
                                }}</span>
                              </a>

                              <!--end: Tite-->
                            </div>
                          </div>
                        </div>
                        <!--end:: Card-->
                      </div>
                      <!-- Others documents : thirdparties -->
                      <template v-if="Object.keys(getDocumentsByPOV('thirdparties')).length > 0">
                        <div class="col" v-for="(Document, d) in getDocumentsByPOV('thirdparties').filter((d) => d.SenderPartnerID !== 0)" :key="d">
                          <!-- Object.keys(Document).length > 0 &&  -->

                          <!--begin::Card-->
                          <div
                            class="card card-custom gutter-b card-stretch card-bordered"
                            :class="
                              Document.DocumentGetURL !== undefined ? 'border border-2 border-warning' : 'border-dashed border-2 border-opalean-gray-medium'
                            "
                          >
                            <div class="card-header border-0">
                              <h5
                                class="card-title"
                                :class="Document.DocumentGetURL !== undefined ? 'text-warning' : 'text-dark-75'"
                                v-if="$language.current === 'en_EN'"
                              >
                                <strong>{{ getPartnerByPartnerID(Document.SenderPartnerID) | getSafeObjectValue("Name") }}</strong
                                ><translate>'s document</translate>
                              </h5>
                              <h5 class="card-title" :class="Document.DocumentGetURL !== undefined ? 'text-warning' : 'text-dark-75'" v-else>
                                <translate>'s document</translate>&nbsp;
                                <strong>{{ getPartnerByPartnerID(Document.SenderPartnerID) | getSafeObjectValue("Name") }}</strong>
                              </h5>
                              <div class="card-toolbar"></div>
                            </div>
                            <div class="card-body pt-2 pb-10">
                              <div class="d-flex flex-column align-items-center">
                                <!--begin: Icon-->
                                <a
                                  :href="Document.DocumentGetURL !== undefined ? Document.DocumentGetURL : null"
                                  :class="Document.DocumentGetURL !== undefined ? 'text-warning' : 'text-dark-75'"
                                  target="_blank"
                                >
                                  <span class="svg-icon svg-icon-opealean-gray-medium svg-icon-4x m-0 handle"
                                    ><inline-svg
                                      :src="Document.DocumentGetURL !== undefined ? 'media/svg/icons/Files/Selected-file.svg' : 'media/svg/icons/Code/Stop.svg'"
                                    ></inline-svg>
                                  </span>
                                  <!--end: Icon-->
                                  <!--begin: Tite-->
                                  <span class="text-dark-75 font-weight-bold font-size-lg ml-4">{{
                                    Document.DocumentGetURL !== undefined ? $gettext("Download document") : $gettext("No document")
                                  }}</span>
                                </a>

                                <!--end: Tite-->
                              </div>
                            </div>
                          </div>
                          <!--end:: Card-->
                        </div>
                      </template>

                      <!-- Hidden input -->
                      <div class="col-12 mb-4">
                        <!--
                         <b-button block variant="outline-opalean-gray-medium" size="lg" class="btn-hover-primary font-weight-bold" @click="addDocument()"
                          ><i class="flaticon2-plus icon-md mt-n1"></i> <translate>Upload document</translate></b-button
                        >
                        -->
                        <b-form-group label-size="lg" class="mt-n20">
                          <!-- :class="{ invisible: !isUploadingDoc }" -->
                          <b-form-file
                            class="mt-3 b-form-file-xl align-items-center invisible"
                            ref="FilesInput"
                            @click="clearDocFiles"
                            @change="onSelectedFiles"
                            :status="docLoadError"
                            :placeholder="$gettext('Choose a file or drop it here...')"
                            :browse-text="
                              getDocumentsByPOV('me').DocumentPutURL !== '' &&
                              getDocumentsByPOV('me').DocumentPutURL !== null &&
                              getDocumentsByPOV('me').SenderPartnerID !== 0
                                ? $gettext('Replace document')
                                : $gettext('Add document')
                            "
                            accept=".tiff, .tif, .pdf, .jpg , .webp, .png, .heic "
                          >
                            <template slot="file-name" slot-scope="{ names }">
                              <b-badge :variant="FileBadgeVariant"
                                >{{ names[0] }} <b-spinner v-if="docLoadPercentage !== 100" small class="ml-2 mr-4"></b-spinner
                              ></b-badge>
                              <b-badge v-if="names.length > 1" variant="light-primary" class="ml-1">
                                + {{ names.length - 1 }} <translate>more files</translate></b-badge
                              >
                            </template>
                          </b-form-file>
                          <div class="d-flex align-items-middle justify-content-between mt-2">
                            <span class="font-weight-bolder small ml-2" :class="docLoadError ? 'text-danger' : 'text-success'">
                              {{ docLoadResponse }}
                            </span>
                            <b-badge v-on:click.stop.prevent="clearDocFiles" variant="opalean-gray-light" v-if="docLoadPercentage === 100"
                              ><translate>Reset</translate></b-badge
                            >
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                    <!-- DEV -->
                    <b-card class="card-custom gutter-b mt-10" body-class="p-3" v-if="showDataDebug">
                      <pre class="mb-0" :class="isUploadingDoc ? 'text-warning' : 'text-success'">
                        <b>isUploadingDoc :</b> {{ isUploadingDoc }}
                        <b>MyRole :</b> {{ syncedOperation.MyRole }}
                        <b>HisRole :</b> {{ syncedOperation.HisRole }}
                        <b>Me :</b> {{ getDocumentsByPOV('me') }}
                        <b>Thirdparty :</b> {{ getDocumentsByPOV('thirdparty') }}
                        <b>Thirdparties :</b> {{ getDocumentsByPOV('thirdparties') }}
                                                <b>Partners role & ID :</b> {{ syncedOperation.Partners.map((p) => ( {PartnerRole: p.PartnerRole, PartnerID: p.PartnerID}) ) }}


                        <b>getUserClientID :</b> {{ getUserClientID }}
                        <b>getOperationThirdPartyDetails me :</b> {{ getOperationThirdPartyDetails_refactored(syncedOperation,
                  "me",
                  "PartnerID",
                  "—"
                ) }}
                        <b>getOperationThirdPartyDetails thirdparty :</b> {{ getOperationThirdPartyDetails_refactored(syncedOperation,
                  "thirdparty",
                  "PartnerID",
                  "—"
                ) }}
                      </pre>
                    </b-card>

                    <!--end::Document -->

                    <!--begin::Card-->
                    <div class="card card-custom card-border">
                      <!--begin::Body-->
                      <div class="card-body">
                        <div>
                          <!--begin::Notes-->
                          <div v-for="(Message, index) in syncedOperation.Messages" :key="index">
                            <!--begin::Note-->
                            <div :class="Message.SenderClientID === getUserClientID ? 'align-items-start' : 'align-items-end'" class="d-flex flex-column mb-1">
                              <div class="d-flex flex-column" :class="Message.SenderClientID === getUserClientID ? 'align-items-start' : 'align-items-end'">
                                <a class="text-dark-75 text-hover-primary font-weight-bold font-size-h6">{{ Message.SenderUserName }}</a>
                                <span class="text-dark-50 font-size-sm">{{ getPartnerByPartnerID(Message.SenderClientID) | getSafeObjectValue("Name") }}</span>
                              </div>
                              <div
                                class="mt-2 rounded p-5 font-weight-bold font-size-lg text-left max-w-500px"
                                :class="Message.SenderClientID === getUserClientID ? 'bg-opalean-primary text-light' : 'bg-opalean-gray-light text-dark-75'"
                              >
                                <span v-html="transformUrlIntoLinkInMessage(Message.MessageText)"></span>
                              </div>
                              <div
                                class="d-flex flex-row text-muted font-size-sm mt-3"
                                :class="Message.SenderClientID === getUserClientID ? 'text-end' : 'text-start'"
                              >
                                <span v-b-toggle="'collapse' + index" class="font-weight-bold" v-if="$language.current === 'en_EN'"
                                  >{{ Message.SentAt | getDateFormat("Humanized") }} <translate>ago</translate></span
                                >
                                <span v-b-toggle="'collapse' + index" class="font-weight-bold" v-else
                                  ><translate>ago</translate> {{ Message.SentAt | getDateFormat("Humanized", $language.current) }}</span
                                >
                                <b-collapse :id="'collapse' + index" class="ml-2">
                                  <span>{{ Message.SentAt | getDateFormat("LocaleDate") }}, {{ Message.SentAt | getDateFormat("LocaleTime") }}</span>
                                </b-collapse>
                              </div>
                            </div>
                            <!--end::Note-->
                          </div>
                          <div v-if="syncedOperation.Messages.length === 0">
                            <p class="text-muted" v-translate>No messages</p>
                          </div>
                          <!--end::Notes-->
                        </div>
                      </div>
                      <!--end::Body-->
                      <!--begin::Footer-->
                      <div class="card-footer align-items-center">
                        <!--begin::Compose-->
                        <div class="d-flex align-items-center justify-content-end">
                          <textarea
                            class="form-control border-0 p-2 bg-opalean-whiter w-50"
                            rows="3"
                            :placeholder="$gettext('Write a note here...')"
                            v-model="inputMessage"
                          ></textarea>

                          <b-form-group
                            id="group-preEstablishedNotes"
                            :label="$gettext('Or')"
                            label-for="select-preEstablishedNotes"
                            label-size="sm"
                            label-class="font-weight-bolder mb-0"
                            class="ml-15 w-50"
                          >
                            <b-form-select v-model="preEstablishedNote" :value="null" id="select-preEstablishedNotes" size="lg">
                              <!-- This slot appears above the options from 'options' prop -->
                              <template #first>
                                <b-form-select-option :value="null"><translate>Select to write a pre-established note ...</translate></b-form-select-option>
                              </template>
                              <!-- Fix translation issue :options="preEstablishedNotes" -->
                              <template v-for="(Note, index) in preEstablishedNotes">
                                <b-form-select-option :value="Note.value" :key="index">{{ $gettext(Note.text) }}</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>

                          <button
                            type="button"
                            size="lg"
                            class="btn btn-outline-primary btn-lg font-weight-bolder chat-send --py-2 --px-6 ml-15 d-flex align-items-center"
                            @click="sendMessage()"
                            :disabled="isFetching || isSubmitting"
                          >
                            <i class="flaticon2-send icon-1x"></i>
                            <span><translate>Send</translate></span>
                          </button>
                        </div>
                        <!--begin::Compose-->
                      </div>
                      <!--end::Footer-->
                    </div>
                    <!--end::Card-->
                  </b-card>
                </div>
                <div v-show="syncedOperation.formstep === 1">
                  <div class="card card-custom card-fit card-border button border-2 border-dashed">
                    <!-- Body -->
                    <div class="card-body px-5 py-5">
                      <span class="d-flex align-items-center"
                        ><span class="svg-icon svg-icon-3x mr-4">
                          <inline-svg src="media/svg/icons/Files/File-plus.svg"></inline-svg>
                        </span>
                        <span class="font-weight-bold"><translate>Please submit operation to add documents and notes</translate></span>
                      </span>
                    </div>
                  </div>
                </div>
                <!--begin:: Shared notes -->

                <!--begin:: Send a picture -->
                <div id="operationCamera" v-show="syncedOperation.formstep > 1" v-if="isMobileDevice">
                  <!-- (DEV) SEND A PIC -->

                  <!-- Mobile Input file-->
                  <b-form-file
                    v-if="isMobileDevice"
                    class="mt-3 b-form-file-xl align-items-center invisible"
                    ref="FilesInput"
                    @click="clearDocFiles"
                    @change="onSelectedFiles"
                    :status="docLoadError"
                    :placeholder="$gettext('Choose a file or drop it here...')"
                    :browse-text="
                      getDocumentsByPOV('me').DocumentPutURL !== '' &&
                      getDocumentsByPOV('me').DocumentPutURL !== null &&
                      getDocumentsByPOV('me').SenderPartnerID !== 0
                        ? $gettext('Replace document')
                        : $gettext('Add document')
                    "
                    accept=".jpg, .png, .webp, .heic, .pdf"
                  >
                    <template slot="file-name" slot-scope="{ names }">
                      <b-badge :variant="FileBadgeVariant"
                        >{{ names[0] }} <b-spinner v-if="docLoadPercentage !== 100" small class="ml-2 mr-4"></b-spinner
                      ></b-badge>
                      <b-badge v-if="names.length > 1" variant="light-primary" class="ml-1">
                        + {{ names.length - 1 }} <translate>more files</translate></b-badge
                      >
                    </template>
                  </b-form-file>

                  <!-- Take a picture -->
                  <h5>
                    <translate>Document</translate><br />
                    <small class="text-muted"><translate>Take a picture</translate></small>
                  </h5>

                  <button
                    type="button"
                    size="lg"
                    class="btn btn-outline-dark btn-lg btn-block font-weight-bolder"
                    @click="onCameraInit()"
                    :disabled="!isMobileDevice"
                    v-if="!initCamera"
                  >
                    <span class="svg-icon-primary">
                      <img :src="getPath('media/svg/icons/Devices/Camera.svg')" class="text-primary svg-icon-primary mr-2" alt="Take a picture" />
                    </span>
                    <span><translate>Load camera</translate></span>
                  </button>

                  <div class="cameraContainer" v-else>
                    <!-- Camera -->
                    <div class="cameraVideoContainer">
                      <video ref="video" id="video" playsinline autoplay></video>
                      <canvas ref="canvas" id="canvas" width="320" height="240" style="display: none"></canvas>
                      <!-- Loader -->
                      <div class="camera-loader" v-if="loadingCamera"><b-spinner label="Loading..." variant="primary"></b-spinner></div>
                      <!-- Buttons -->
                      <div class="camera-buttons">
                        <button @click="switchCamera" class="btn btn-sm btn-light mr-2 p-1 switch-camera">
                          <img :src="getPath('media/opalean/svg/Camera_switch.svg')" alt="Switch camera icon" />
                          <label class="ml-1 m-0">
                            <span class="label label-inline">{{ camera }}</span>
                          </label>
                        </button>
                        <!-- <button type="button" class="btn btn-sm btn-light mr-2 p-1" @click="switchCamera()">Switch Camera</button>
                        <button type="button" class="btn btn-sm btn-light mr-2 p-1" @click="setCamera('environment')">Back Camera</button>
                        <button type="button" class="btn btn-sm btn-light mr-2 p-1" @click="setCamera('user')">Front Camera</button> -->
                        <button type="button" class="btn btn-sm btn-light mr-2 p-1" @click="pause()">
                          <img :src="getPath('media/svg/icons/Media/Pause.svg')" alt="Pause camera" />
                        </button>
                        <button type="button" class="btn btn-sm btn-light mr-2 p-1" @click="unpause()">
                          <img :src="getPath('media/svg/icons/Media/Play.svg')" alt="Play camera" />
                        </button>
                        <button type="button" class="btn btn-sm btn-primary text-light p-1" @click="doScreenshot()" title="Take a picture">
                          <img :src="getPath('media/svg/icons/Devices/Camera.svg')" class="text-light" alt="Take a picture" />
                          <label class="ml-1 d-none">
                            <span class="label label-inline"><translate>Take the picture</translate></span>
                          </label>
                        </button>
                      </div>
                      <img ref="screenshotImage" id="screenshotImage" class="--d-none card rounded-sm shadow-sm h-100px w-130px border-2 border-light" alt="" />
                    </div>
                    <!-- Display errors-->
                    <p class="--camera-error alert alert-danger" role="alert" v-if="noFrontCamera">
                      <translate>You don't seem to have a front camera on your device</translate>
                    </p>
                    <p class="--camera-error alert alert-danger" role="alert" v-if="noRearCamera">
                      <translate>You don't seem to have a rear camera on your device</translate>
                    </p>
                  </div>
                </div>
                <!--end:: Send a picture -->
              </div>
            </div>
          </div>
          <!--end::Body-->
        </div>
      </div>
      <div class="col-xl-1 px-1" v-if="!isMobileDevice">
        <div class="position-fixed w-100">
          <div class="navi navi-hover navi-active navi-link-rounded navi-bold navi-icon-center --navi-light-icon">
            <!--begin:Section-->
            <div class="navi-section mb-2 font-size-sm font-weight-bold text-opalean-gray-medium pb-1 ml-0" v-translate>Actions</div>
            <!--end:Section-->

            <!-- Validate -->
            <div class="navi-item my-2">
              <button
                class="navi-link navi-hover-info px-3 --active"
                v-on:click="validateStatement"
                :disabled="isValidating || !haveRequiredThirdParty()"
                v-if="
                  syncedOperation.formstep > 0 &&
                  syncedOperation.action === 'edit' &&
                  syncedOperation.OperationStatus === 'M' &&
                  hashedInitialSyncedOperation === hashedSyncedOperation
                "
              >
                <span class="navi-icon mr-2" v-if="isValidating === false">
                  <i class="fas fa-check-circle text-info"></i>
                </span>
                <b-spinner v-if="isValidating === true" variant="opalean-gray-medium" small type="grow" class="ml-2 mr-4"></b-spinner>
                <span class="navi-text font-weight-bolder font-size-lg mr-2" v-translate>Validate</span>
                <!-- <span class="navi-label"><span class="label label-rounded label-light-danger font-weight-bolder">6</span></span> -->
              </button>
            </div>

            <!-- Edit -->
            <div class="navi-item my-2">
              <button
                class="navi-link navi-hover-primary px-3 active"
                v-on:click="onSubmitForm"
                :disabled="isFetching || isSubmitting || !haveRequiredThirdParty()"
                v-if="
                  fullyLoaded &&
                  syncedOperation.formstep > 0 &&
                  (hashedInitialSyncedOperation !== hashedSyncedOperation || syncedOperation.OperationStatus === 'B') &&
                  (syncedOperation.action === 'create' ? hasStatement : true) &&
                  syncedOperation.OperationDate !== undefined &&
                  syncedOperation.OperationDate !== 'Invalid date'
                "
              >
                <span class="navi-icon mr-2" v-if="!isFetching">
                  <i class="far fa-edit --text-danger" v-if="syncedOperation.action === 'edit'"></i>
                  <i class="far fa-plus-square --text-success" v-else></i>
                </span>
                <b-spinner v-if="isFetching || isSubmitting" variant="opalean-gray-medium" small type="grow" class="ml-2 mr-4"></b-spinner>
                <span class="navi-text font-weight-bolder font-size-lg mr-2">{{
                  syncedOperation.action === "create" ? $gettext("Submit") : $gettext("Save")
                }}</span>
                <!-- <span class="navi-label"><span class="label label-rounded label-light-danger font-weight-bolder">6</span></span> -->
              </button>
            </div>

            <!-- Draft -->
            <div class="navi-item my-2">
              <button
                class="navi-link navi-hover-opalean-dark px-3 active"
                v-on:click="onSubmitForm($event, true)"
                :disabled="isFetching || isSubmitting || !haveRequiredThirdParty()"
                v-if="
                  fullyLoaded &&
                  syncedOperation.formstep > 0 &&
                  (hashedInitialSyncedOperation !== hashedSyncedOperation || syncedOperation.OperationStatus === 'B') &&
                  (syncedOperation.action === 'create' ? hasStatement : true) &&
                  syncedOperation.OperationDate !== undefined &&
                  syncedOperation.OperationDate !== 'Invalid date' &&
                  (syncedOperation.action === 'create' || syncedOperation.OperationStatus === 'B')
                "
              >
                <span class="navi-icon mr-2" v-if="!isFetching">
                  <i class="fas fa-pencil-alt --text-danger" v-if="syncedOperation.action === 'edit'"></i>
                  <i class="fas fa-pencil-alt --text-success" v-else></i>
                </span>
                <b-spinner v-if="isFetching || isSubmitting" variant="opalean-gray-medium" small type="grow" class="ml-2 mr-4"></b-spinner>
                <span class="navi-text font-weight-bolder font-size-lg mr-2">{{
                  syncedOperation.action === "create" ? $gettext("Draft") : $gettext("Draft")
                }}</span>
                <!-- <span class="navi-label"><span class="label label-rounded label-light-danger font-weight-bolder">6</span></span> -->
              </button>
            </div>

            <!-- Duplicate -->
            <div class="navi-item my-2">
              <button
                class="navi-link navi-hover-warning px-3 --active"
                v-on:click="onDuplicateForm($event, 'copyflow')"
                :disabled="isDuplicating || !haveRequiredThirdParty()"
                v-if="fullyLoaded && syncedOperation.OperationID !== 0 && syncedOperation.formstep > 0"
              >
                <span class="navi-icon mr-2" v-if="isDuplicating === false">
                  <i class="far fa-clone --text-warning"></i>
                </span>
                <b-spinner v-if="isDuplicating === true" variant="opalean-gray-medium" small type="grow" class="ml-2 mr-4"></b-spinner>
                <span class="navi-text font-weight-bolder font-size-lg mr-2" v-translate>Duplicate</span>
                <!-- <span class="navi-label"><span class="label label-rounded label-light-danger font-weight-bolder">6</span></span> -->
              </button>
            </div>

            <!-- Reset -->
            <div class="navi-item my-2">
              <button
                class="navi-link navi-hover-danger px-3 --active"
                v-on:click="onResetForm"
                :disabled="!(!isFetching && fullyLoaded) || isResetting"
                :class="!(!isFetching && fullyLoaded) ? 'opacity-40' : ''"
              >
                <span class="navi-icon mr-2" v-if="isResetting === false">
                  <i class="fas fa-undo-alt"></i>
                </span>
                <b-spinner v-if="isResetting === true" variant="opalean-gray-medium" small type="grow" class="ml-2 mr-4"></b-spinner>
                <span class="navi-text font-weight-bolder font-size-lg mr-2">{{
                  syncedOperation.action === "create" ? $gettext("Reset") : $gettext("Cancel")
                }}</span>
                <!-- <span class="navi-label"><span class="label label-rounded label-light-danger font-weight-bolder">6</span></span> -->
              </button>
            </div>

            <div class="navi-section mb-2 font-size-sm font-weight-bold text-opalean-gray-medium pb-1 ml-0" v-translate>Print</div>

            <!-- Print -->
            <div class="navi-item my-2">
              <button
                class="navi-link navi-link-info px-3 --active"
                v-b-modal.printModal
                v-if="syncedOperation.formstep > 0"
                :disabled="!(!isFetching && fullyLoaded)"
                :class="!(!isFetching && fullyLoaded) ? 'opacity-40' : ''"
                v-on:click="callPartnerDetails"
              >
                <span class="navi-icon mr-2">
                  <i class="fas fa-print --text-info"></i>
                </span>
                <span class="navi-text font-weight-bolder font-size-lg mr-2" v-translate>Print</span>
                <!-- <span class="navi-label"><span class="label label-rounded label-light-danger font-weight-bolder">6</span></span> -->
              </button>
            </div>

            <div class="navi-item my-2 mx-4 d-none">
              <b-form-checkbox v-model="validateAndPrint" id="check-print" name="check-print" switch v-translate>Validate & print</b-form-checkbox>
            </div>

            <!--begin:Section-->
            <!-- 
            <div class="navi-section mb-2 font-size-sm font-weight-bold text-opalean-gray-medium pb-1 ml-0" v-translate>Status</div>
            <div class="navi-item my-2">
              <a href="#" class="navi-link px-3">
                <span class="navi-icon mr-2">
                  <i class="fa fa-genderless text-danger"></i>
                </span>
                <span class="navi-text font-weight-bolder font-size-lg mr-2">Custom Work</span>
                <span class="navi-label">
                  <span class="label label-rounded label-light-danger font-weight-bolder">6</span>
                </span>
              </a>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>

    <!-- Print Modal -->
    <b-modal id="printModal" size="lg" scrollable centered ok-disabled :title="$gettext('Print an operation')" @hidden="deletePrint">
      <div id="contentToPrint" v-if="syncedOperation.formstep > 0">
        <!--begin::Header-->
        <div class="d-flex justify-content-between">
          <!--begin:: Boards -->
          <div class="boards w-70">
            <!--begin:: Thirdparty  -->
            <div class="board-items d-flex flex-column justify-content-between align-items-start border-bottom border-3 border-opalean-gray-dark py-5">
              <!--begin:: Board: Thirdparty  -->
              <div class="board-item mr-8 d-flex flex-row align-items-center justify-content-center">
                <span
                  class="svg-icon svg-icon-3x svg-icon-primary"
                  v-if="getFlowType(syncedOperation.OperationType, 'icon') !== '' && getFlowType(syncedOperation.OperationType, 'icon') !== null"
                >
                  <inline-svg :src="getFlowType(syncedOperation.OperationType, 'icon')"></inline-svg>
                </span>
                <!-- Title -->
                <h1 class="ml-3 mb-0 text-dark display-4 font-weight-bolder">
                  {{ getOperationThirdPartyDetails_refactored(syncedOperation, "me", "Name", "—") }}
                </h1>
              </div>
              <!--end:: Board: Thirdparty  -->
            </div>

            <!--begin:: Title  -->
            <div class="board-items py-5">
              <!--begin:: Board: Title  -->
              <div class="board-item mr-8 d-flex flex-row align-items-center justify-content-between w-100">
                <!-- Title -->
                <h1 class="text-opalean-gray-dark font-weight-bolder">Pallet voucher</h1>
                <!-- Date / ID -->
                <div class="" v-if="syncedOperation.OperationID !== undefined">
                  <span class="label label-lg label-opalean-gray-dark text-white label-inline font-weight-bolder border-width-2 rounded-right-0">N°</span>
                  <span class="label label-lg label-outline-opalean-gray-dark label-inline font-weight-bolder border-width-2 rounded-left-0">{{
                    syncedOperation.OperationID | safePrint
                  }}</span>
                </div>
              </div>
              <!--end:: Board: Title  -->
            </div>

            <!--begin:: Details  -->
            <div class="board-items py-5">
              <!--begin:: Board: Details  -->
              <div class="board-item mr-8 d-flex flex-row align-items-center justify-content-between w-100" v-if="syncedOperation.OperationDate !== undefined">
                <!-- Title -->
                <h5 class="mb-2 text-opalean-gray-dark" v-translate>Date</h5>
                <!-- Date / ID -->
                <div class="ml-4">
                  <span
                    class="mb-2 label label-lg label-outline-opalean-gray-dark label-inline font-weight-bolder border-width-2"
                    v-if="syncedOperation.OperationDate !== undefined"
                    >{{ syncedOperation.OperationDate | getDateFormat("LocaleDate") }}</span
                  >
                </div>
              </div>

              <div class="board-item mr-8 d-flex flex-row align-items-center justify-content-between w-100" v-if="syncedOperation.OperationCMR !== undefined">
                <!-- Title -->
                <h5 class="mb-2 text-primary">CMR</h5>
                <!-- Date / ID -->
                <div class="ml-4">
                  <span class="mb-2 label label-lg label-primary label-inline font-weight-bolder border-width-2">{{
                    syncedOperation.OperationCMR | safePrint
                  }}</span>
                </div>
              </div>

              <!--end:: Board: Details  -->
            </div>
          </div>
          <!--end:: Boards -->

          <!--begin:: QRCode -->
          <div class="d-flex justify-content-end ml-2 w-30">
            <qrcode-vue id="operationQrCode" :value="operationURL" size="180" render-as="svg" level="H" />
          </div>
          <!--end:: QRCode -->
        </div>
        <!--begin::Header-->

        <!--begin:: Boards -->
        <div class="boards w-100 mt-10">
          <div class="board-items d-flex flex-row justify-content-between align-items-center border-top border-bottom border-2 border-opalean-gray-medium py-5">
            <div class="board-item pr-4 w-50 border-right border-1 border-opalean-gray-light"></div>
            <div class="board-item pl-4 w-50">
              <h6 class="text-primary text-uppercase" v-translate>Carrier</h6>
              <span class="d-block font-size-h5 font-weight-bolder">
                {{ getOperationThirdPartyDetails_refactored(syncedOperation, "carrier", "Name", "—") }}
              </span>
              <div class="h-70px" v-html="computedAddress(getOperationThirdPartyDetails_refactored(syncedOperation, 'carrier', 'PartnerID'))"></div>
            </div>
          </div>

          <div class="board-items d-flex justify-content-between border-bottom border-2 border-opalean-gray-medium py-5">
            <div class="board-item pr-4 w-50 border-right border-1 border-opalean-gray-light">
              <h6 class="text-primary text-uppercase" v-translate>Shipper</h6>
              <span class="d-block font-size-h5 font-weight-bolder">
                {{ getOperationThirdPartyDetails_refactored(syncedOperation, "shipper", "Name", "—") }}
              </span>
              <div class="h-70px" v-html="computedAddress(getOperationThirdPartyDetails_refactored(syncedOperation, 'shipper', 'PartnerID'))"></div>
              <!--begin:: Statement MC -->
              <!-- Get the MC POVs or for a loading when i am a carrier : MT -->
              <b-table-lite
                class="mt-8 border-dark table-vertical-center"
                noCollapse
                bordered
                striped
                hover
                v-if="Object.keys(syncedOperation.POVs).length > 0"
                :items="
                  (syncedOperation.MyRole === 'MT' && syncedOperation.OperationStatus !== 'M' && syncedOperation.OperationType === 'C') ||
                  (syncedOperation.MyRole === 'MC' && syncedOperation.OperationStatus === 'M' && syncedOperation.OperationType === 'C')
                    ? getMyPOVIndex('MT') != null
                      ? [...syncedOperation.POVs[getMyPOVIndex('MT')].Pallets, ...getFakeLines()]
                      : [
                          ...$mainFunctions.getFilteredObject(syncedOperation.POVs[getMyPOVIndex()]?.Pallets ?? [], ['PalletID', 'PalletName']),
                          ...getFakeLines(),
                        ]
                    : getMyPOVIndex('MC') != null
                    ? [...syncedOperation.POVs[getMyPOVIndex('MC')].Pallets, ...getFakeLines()]
                    : [...$mainFunctions.getFilteredObject(syncedOperation.POVs[getMyPOVIndex()]?.Pallets ?? [], ['PalletID', 'PalletName']), ...getFakeLines()]
                "
                :fields="[
                  { key: 'PalletName', label: $gettext('Pallet name'), tdClass: 'h-50px min-h-50px' },
                  { key: 'FP', label: $gettext(getAccountType(0, null, 'name', '{0}')) },
                  { key: 'FR', label: $gettext(getAccountType(2, null, 'name', '{0}')) },
                ]"
              >
                <template #cell(PalletName)="data">
                  <h5 class="my-2" v-if="data.item.PalletID !== 0">
                    <span class="svg-icon svg-icon-md mr-3" :class="getPalletType(getPalletValue(data.item.PalletID, 'PalletType'), 'class')">
                      <inline-svg :src="getPalletType(getPalletValue(data.item.PalletID, 'PalletType'), 'icon')"></inline-svg
                    ></span>
                    <a class="font-weight-bolder text-dark --text-hover-primary">{{ data.item.PalletName }}</a>
                  </h5>
                </template>
                <template #cell()="data">
                  <h5 class="mb-0">{{ data.value }}</h5>
                </template>
              </b-table-lite>
              <!--end:: Statement -->
            </div>
            <div class="board-item pl-4 w-50">
              <h6 class="text-primary text-uppercase" v-translate>Receiver</h6>
              <span class="d-block font-size-h5 font-weight-bolder">{{
                getOperationThirdPartyDetails_refactored(syncedOperation, "receiver", "Name", "—")
              }}</span>
              <div class="h-70px" v-html="computedAddress(getOperationThirdPartyDetails_refactored(syncedOperation, 'receiver', 'PartnerID'))"></div>

              <!--begin:: Statement MD -->
              <!-- Get the MC POVs or for a unloading when i am a carrier : MT -->
              <b-table-lite
                class="mt-8 border-dark table-vertical-center"
                noCollapse
                bordered
                striped
                hover
                v-if="Object.keys(syncedOperation.POVs).length > 0"
                :items="
                  (syncedOperation.MyRole === 'MT' && syncedOperation.OperationStatus !== 'M' && syncedOperation.OperationType === 'D') ||
                  (syncedOperation.MyRole === 'MD' && syncedOperation.OperationStatus === 'M' && syncedOperation.OperationType === 'D')
                    ? getMyPOVIndex('MT') != null
                      ? [...syncedOperation.POVs[getMyPOVIndex('MT')].Pallets, ...getFakeLines()]
                      : [
                          ...$mainFunctions.getFilteredObject(syncedOperation.POVs[getMyPOVIndex()]?.Pallets ?? [], ['PalletID', 'PalletName']),
                          ...getFakeLines(),
                        ]
                    : getMyPOVIndex('MD') != null
                    ? [...syncedOperation.POVs[getMyPOVIndex('MD')].Pallets, ...getFakeLines()]
                    : [...$mainFunctions.getFilteredObject(syncedOperation.POVs[getMyPOVIndex()]?.Pallets ?? [], ['PalletID', 'PalletName']), ...getFakeLines()]
                "
                :fields="[
                  { key: 'PalletName', label: $gettext('Pallet name'), tdClass: 'h-50px min-h-50px' },
                  { key: 'FP', label: $gettext(getAccountType(6, null, 'name', '{0}')) },
                  { key: 'FR', label: $gettext(getAccountType(2, null, 'name', '{0}')) },
                ]"
              >
                <template #cell(PalletName)="data">
                  <h5 class="my-2" v-if="data.item.PalletID !== 0">
                    <span class="svg-icon svg-icon-md mr-3" :class="getPalletType(getPalletValue(data.item.PalletID, 'PalletType'), 'class')">
                      <inline-svg :src="getPalletType(getPalletValue(data.item.PalletID, 'PalletType'), 'icon')"></inline-svg
                    ></span>
                    <a class="font-weight-bolder text-dark --text-hover-primary">{{ data.item.PalletName }}</a>
                  </h5>
                </template>
                <template #cell()="data">
                  <h5 class="mb-0">{{ data.value }}</h5>
                </template>
              </b-table-lite>
              <!--end:: Statement -->
            </div>
          </div>
        </div>
        <!--end:: Boards -->

        <p class="mt-2 mb-25" v-translate>Additionnal notes :</p>

        <!--begin:: Visas -->
        <div class="row mb-35">
          <div class="col"><span class="text-center" v-translate>Sender visa</span></div>
          <div class="col"><span class="text-center" v-translate>Carrier visa</span></div>
          <div class="col"><span class="text-center" v-translate>Receiver visa</span></div>
        </div>
        <!--end:: Visas -->

        <!--begin::Logotype-->
        <div class="border-top border-3 border-dark d-flex align-items-center justify-content-between pt-4">
          <p v-translate>Receive this voucher, in real time, in your NeoOpatrace&trade; app.<br />For more information, contact +33 3 66 72 16 80</p>
          <img class="ml-4" alt="Opalean" :src="getPath('media/opalean/logos/logo_opalean_130_26px.png')" width="195" height="39" />
        </div>
        <!--end::Logotype-->

        <div class="card" v-if="showDataDebug">
          MY: {{ this.syncedOperation.MyRole }} / TP:
          {{ this.getAllowedThirdPartyType(this.syncedOperation.OperationType, this.syncedOperation.MyRole, "thirdparty")[0] }}<br />
          MY: {{ getMyPOVIndex() }} / TP: {{ getThirdPartyPOVIndex() }}<br />
          MC: {{ getMyPOVIndex("MC") }} / MT: {{ getMyPOVIndex("MT") }} / MD: {{ getMyPOVIndex("MD") }}
        </div>
      </div>

      <div slot="modal-footer" class="w-100">
        <b-btn size="sm" class="float-right" variant="info" @click="print"> <i class="fa fa-print"></i> Print </b-btn>
      </div>
    </b-modal>

    <!-- DEV -->
    <b-card class="card-custom gutter-b mt-10" body-class="p-3" v-if="showDataDebug">
      <pre class="mb-0" :class="hashedInitialSyncedOperation != hashedSyncedOperation ? 'text-info' : 'text-secondary'">
modified Operation ? {{ hashedInitialSyncedOperation != hashedSyncedOperation }}</pre
      >
      <pre class="mb-0" :class="fullyLoaded ? 'text-warning' : 'text-success'"><b>fullyLoaded :</b> {{ fullyLoaded }}</pre>
      <pre class="mb-0" :class="isFetching ? 'text-warning' : 'text-success'"><b>isFetching :</b> {{ isFetching }}</pre>
      <pre class="mb-0" :class="isSubmitting ? 'text-warning' : 'text-success'"><b>isSubmitting :</b> {{ isSubmitting }}</pre>
      <pre class="mb-0" :class="isDuplicating ? 'text-warning' : 'text-success'"><b>isDuplicating :</b> {{ isDuplicating }}</pre>
      <pre class="mb-0" :class="isResetting ? 'text-warning' : 'text-success'"><b>isResetting :</b> {{ isResetting }}</pre>
      <pre class="mb-0" :class="isValidating ? 'text-warning' : 'text-success'"><b>isValidating :</b> {{ isValidating }}</pre>
      <pre class="mb-0" :class="isUploadingDoc ? 'text-warning' : 'text-success'"><b>isUploadingDoc :</b> {{ isUploadingDoc }}</pre>
      <hr />
      <pre>the POVs: {{ syncedOperation.POVs }}</pre>
      <hr />
      <pre>FilteredOperations: {{ FilteredOperations }}</pre>
      <hr />
      <pre>syncedOperation: {{ syncedOperation }}</pre>
    </b-card>

    <!-- end::EditOperation -->
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
@media screen {
  #print {
    display: none;
  }

  /* body #print,
  body #print * {
    visibility: visible !important;
  }
  body #print {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    float: none;

    border: 20px solid red;
    padding: 4rem;
  }

  body #print h1,
  .font-size-sm,
  .font-size-h5 {
  } */

  /**
  Mobile */

  .b-form-spinbutton .btn svg {
    height: 2.5em;
    width: 2.5em;
  }

  .b-form-spinbutton output,
  .b-form-spinbutton input {
    justify-content: center;
  }

  .b-form-spinbutton output > div,
  .b-form-spinbutton output > bdi {
  }

  .cameraContainer {
  }
  .cameraVideoContainer {
    position: relative;
  }

  .cameraVideoContainer video {
    width: calc(100vw - 25px);
    box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
    border-radius: 0.42rem;
    min-height: 250px;
    background: white;
  }
}

@media print {
  @page {
    size: auto A4 portrait; /* auto is the initial value */
    /* this affects the margin in the printer settings */
    margin: 8mm 0mm 8mm 0mm;
  }

  body {
    /* Resetting colors and font size */
    background: white;
    /* color: black; */
    font-size: 140% !important;

    /* this affects the margin on the content before sending to printer */
    margin: 0px;
  }
  h1.display-4 {
    font-size: 300% !important;
  }
  h1 {
    font-size: 230% !important;
  }
  h5,
  .font-size-sm,
  .font-size-md,
  .font-size-h5 {
    font-size: 140% !important;
  }

  .table h5 {
    font-size: 120% !important;
  }

  .table thead th,
  .table thead td {
    font-size: 110% !important;
  }

  .label.label-lg {
    height: 50px;
    width: 24px;
    font-size: 130%;
    border-radius: 1rem;
  }

  .svg-icon.svg-icon-md svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }

  .svg-icon.svg-icon-3x svg {
    height: 7.5rem !important;
    width: 7.5rem !important;
  }

  .icon-md {
    font-size: 105% !important;
  }

  .icon-nm {
    font-size: 100% !important;
  }

  #operationQrCode svg {
    width: 220px !important;
    height: 220px !important;
  }

  a {
    text-decoration: none !important;
  }

  .badge {
    border: none;
  }

  body * {
    visibility: hidden !important;
  }
  body > .d-flex {
    display: none !important;
  }
  body #print,
  body #print * {
    visibility: visible !important;
  }

  /* Setting content width, unsetting floats and margins */
  body #print {
    /* margin: 4rem; */
    transform: scale(0.92);
    padding: 4rem;
    /* font-size: 150% !important; */

    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    float: none;

    border: 2px solid gray;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }

  .table thead th,
  .table-bordered th,
  .table-bordered td {
    border-color: gray !important;
    border-width: 2px;
  }

  /* Defining all page breaks */
  a {
    page-break-inside: avoid;
  }
  blockquote {
    page-break-inside: avoid;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
    page-break-inside: avoid;
  }
  img {
    page-break-inside: avoid;
    page-break-after: avoid;
  }
  table,
  pre {
    page-break-inside: avoid;
  }
  ul,
  ol,
  dl {
    page-break-before: avoid;
  }
}
</style>

<script>
import Vue from "vue";
var vm = new Vue();

import * as moment from "moment";

import { mapGetters } from "vuex";
import { INC_OPERATION_TID, HAS_OPERATION_DRAFT, RESET_OPERATION_DRAFT } from "@/core/services/store/neoopatrace/datas.module";

import statics from "@/core/statics/statics.js";
import helpers from "@/core/statics/helpers.js";

//import NeoOpatraceProgressWizard from "@/view/pages/wizard/NeoOpatraceProgressWizard";

import DetailsPanel from "@/view/content/neoopatrace/components/DetailsPanel.vue";
import ThirdPartiesPanel from "@/view/content/neoopatrace/components/ThirdPartiesPanel.vue";
import FlowFormThirdParties from "@/view/content/neoopatrace/components/FlowFormThirdParties.vue";

import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
//import Swal from "sweetalert2";

import QrcodeVue from "qrcode.vue";

import hash from "object-hash";

// New for mobile device
import FormSpinbuttonWithInput from "@/view/content/neoopatrace/components/FormSpinbuttonWithInput.vue";

// const FormData = require("form-data");

export default {
  title() {
    return vm.$gettext("route.operations.edit");
  },
  name: "EditOperation",
  components: {
    //NeoOpatraceProgressWizard,
    DetailsPanel,
    ThirdPartiesPanel,
    FlowFormThirdParties,
    Multiselect,
    QrcodeVue,

    FormSpinbuttonWithInput,
  },
  // beforeRouteUpdate(to, from, next) {
  //   console.log("beforeRouteUpdate router::to,from", to, from);
  // },
  watch: {
    // Watch
    $route(to, from) {
      console.log("[EDIT OPERATION] watch router::to,from", to, from);
    },
    // Create
    "$route.params.OperationTID": {
      handler: function (OperationTID) {
        console.log("[CREATE] watch router::val ($route.params.OperationTID)", OperationTID);
        console.log("[CREATE] router:: this getOperationTID :: ", this.getOperationTID);
        console.log("[CREATE] router:: this.syncedOperation.OperationTID :: ", this.syncedOperation.OperationTID);

        // If we come from /create route, we probably have a OperationTID
        if (OperationTID !== undefined)
          if (
            this.syncedOperation.OperationUID === undefined &&
            this.syncedOperation.OperationID === undefined &&
            (this.syncedOperation.OperationTID === undefined || this.syncedOperation.OperationTID === this.getOperationTID)
          ) {
            // If we create a brand new operation
            // Else, if we already have a this.syncedOperation.OperationTID locally stored
            // Locally store OperationTID in form
            this.syncedOperation.OperationTID = this.getOperationTID;
            // Inc OperationTID for the next one
            this.$store.dispatch(INC_OPERATION_TID).then(() => {});
          } else {
            // const swalMixin = Swal.mixin({
            //   customClass: {
            //     confirmButton: "btn btn-success",
            //     cancelButton: "btn btn-outline-secondary",
            //   },
            //   buttonsStyling: false,
            // });

            //alert(this.hashedInitialSyncedOperation != this.hashedSyncedOperation);
            if (this.hashedInitialSyncedOperation != this.hashedSyncedOperation) {
              console.log("[CREATE] watch router", "There is ≠ in operation");
              window.swalAction
                .fire({
                  title: vm.$gettext("Create a new operation"),
                  text: vm.$gettext("Start a fresh operation and abord changes on this one ?"),
                  icon: "question",
                  showCancelButton: true,
                  confirmButtonText: vm.$gettext("YES"),
                  cancelButtonText: vm.$gettext("NO"),
                  reverseButtons: true,
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    // Reset our form values
                    this.resetData();
                    // Locally store OperationTID in form
                    this.syncedOperation.OperationTID = this.getOperationTID;
                    // Inc OperationTID for the next one
                    this.$store.dispatch(INC_OPERATION_TID).then(() => {});

                    // Message
                    // swalMixin.fire(vm.$gettext("Resetted"), vm.$gettext("Your form has been resetted."), "success");
                    window.swalToast.fire({
                      title: vm.$gettext("Resetted"),
                      html: vm.$gettext("Your form has been resetted."),
                      timer: 1200,
                      timerProgressBar: true,
                      icon: "success",
                    });
                  } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === window.swalAction.DismissReason.cancel
                  ) {
                    // Allow retrying to renew create operartion

                    // Inc OperationTID for the next one
                    this.$store.dispatch(INC_OPERATION_TID).then(() => {});
                    // this.$store.dispatch(INC_OPERATION_TID).then(() => {
                    //swalMixin.fire(vm.$gettext("Cancelled"), vm.$gettext("You can keep editing your form."), "success");
                    // });
                  }
                });
            } else {
              console.log("[CREATE] watch router", "There is no ≠ in operation");

              // Reset our form values
              this.resetData();
              // Locally store OperationTID in form
              this.syncedOperation.OperationTID = this.getOperationTID;
              // Inc OperationTID for the next one
              this.$store.dispatch(INC_OPERATION_TID).then(() => {});
            }
          }
      },
      //deep: true,
      immediate: true,
    },
    // Edit
    "$route.params.OperationUID": {
      handler: function (OperationUID) {
        console.log("[EDIT] watch router::val ($route.params.OperationUID)", OperationUID, this.$route.params.OperationUID);
        console.log("[EDIT] router:: this.syncedOperation.OperationUID :: ", this.syncedOperation.OperationUID);

        // If we come from /edit route, we probably have a OperationTID
        if (OperationUID !== undefined)
          if (this.syncedOperation.OperationUID !== OperationUID && this.syncedOperation.OperationUID !== undefined) {
            // If we already have a this.syncedOperation.OperationUID locally stored and we call for a new Operation

            // const swalMixin = Swal.mixin({
            //   customClass: {
            //     confirmButton: "btn btn-success",
            //     cancelButton: "btn btn-outline-secondary",
            //   },
            //   buttonsStyling: false,
            // });

            if (Object.keys(this.modifiedOperation).length !== 0 && this.modifiedOperation.status !== "success") {
              // If there is ≠ in operation
              console.log("[EDIT] watch router", "There is ≠ in operation");
              // Then call swal
              window.swalAction
                .fire({
                  title: vm.$gettext("Edit another operation"),
                  text: vm.$gettext("Replace operation and abord changes on this one ?"),
                  icon: "question",
                  showCancelButton: true,
                  confirmButtonText: vm.$gettext("YES"),
                  cancelButtonText: vm.$gettext("NO"),
                  reverseButtons: true,
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    // Locally store to call it
                    this.OperationUID = OperationUID;

                    // Re-call our new operation
                    this.callData(OperationUID);

                    // Message
                    //swalMixin.fire(vm.$gettext("Success"), vm.$gettext("Operation has been loaded."), "success");
                    window.swalToast.fire({
                      title: vm.$gettext("Success"),
                      html: vm.$gettext("Operation has been loaded."),
                      timer: 1200,
                      timerProgressBar: true,
                      icon: "success",
                    });
                  } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === window.Swal.DismissReason.cancel
                  ) {
                    //swalMixin.fire(vm.$gettext("Cancelled"), vm.$gettext("You can keep editing your form."), "success");
                  }
                });
            } else {
              // If there is no ≠ in operation
              console.log("[EDIT] watch router", "There is no ≠ in operation");
              // Locally store to call it
              this.OperationUID = OperationUID;

              // Re-call our new operation
              this.callData(OperationUID);
            }
          }
      },
      //deep: true,
      immediate: true,
    },

    // Debounce watcher
    // https://stackoverflow.com/questions/45178621/how-to-correctly-use-vue-js-watch-with-lodash-debounce
    syncedOperation: {
      immediate: false, // Initiate at first load, trigger the callback immediately with the current value of the expression
      deep: true, // Look deeper in object
      handler: function (n) {
        // No old object when it's an object
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        if (true === this.fullyLoaded) this.diffSyncedOperation(n);
        console.log("√ diff Watch::syncedOperation", "Only if this.fullyLoaded === true", this.fullyLoaded);
      },
    },

    // On particular Object
    /*"syncedOperation.POVs": {
      immediate: false, // Initiate at first load, trigger the callback immediately with the current value of the expression
      deep: true, // Look deeper in object
      handler: function (n) {
        // No old object when it's an object
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        //this.diffSyncedOperation(n);
        console.log("diff Watch::syncedOperation.POVs", n);
      },
    },*/

    // Date watcher
    "syncedOperation.OperationDate": {
      //deep: true, // Detect nested value changes inside Objects
      immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
      handler: function (date) {
        console.log("Watch::OperationDate", date);
        this.$set(this, "OperationDateUTC", date);
      },
    },

    // Iso date watcher
    OperationDateUTC: {
      //deep: true, // Detect nested value changes inside Objects
      immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
      handler: function (date) {
        console.log("Watch::OperationDateUTC", date);
        this.$set(this.syncedOperation, "OperationDate", moment(new Date(String(date))).format());
      },
    },

    // Used to watch :  syncedOperation.POVs[getMyPOVIndex()].Pallets[index].lastFP
    // "syncedOperation.POVs": {
    //   deep: true, // Detect nested value changes inside Objects
    //   immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
    //   handler: function (POVs) {
    //     console.log("Watch::POVs", typeof POVs[this.getMyPOVIndex()] != "undefined" ? POVs[this.getMyPOVIndex()].Pallets : "undefined");

    //     // Store old POVs
    //     if (typeof POVs[this.getMyPOVIndex()] != "undefined" && typeof POVs[this.getMyPOVIndex()].Pallets[0].FP === "undefined")
    //       this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[0].lastFP = -1;
    //   },
    // },
  },
  data() {
    return {
      // OperationID: undefined, // Params
      prevOperationUID: undefined,
      nextOperationUID: undefined,
      //Preferences
      showDataDebug: statics.showDataDebug,
      //Statics
      flowTypes: statics.flowTypes,
      thirdPartyTypes: statics.thirdPartyTypes,
      preEstablishedNotes: statics.preEstablishedNotes,
      // Sync
      syncedOperation: {
        OperationStatus: undefined,
        OperationTID: undefined, // Local
        thirdpartyEdit: undefined, // Local
        action: "create",
        formstep: 0, // 0 define flow / 1 define statement / 2 add notes or update operation
        OperationUID: undefined, // undefined mounted to 0
        OperationID: undefined, // undefined mounted to 0
        OperationRef: undefined,
        OperationDate: undefined,
        OperationCMR: undefined,
        OperationType: null,
        OperationTypeSlug: undefined,
        MyRole: null,
        MyRoleSlug: undefined,
        Partners: [],
        //
        POVs: [
          {
            Pallets: [],
          },
        ],
        //
        Manifest: [
          /*{
            Type: "OpRef",
            Value: "1234"
          }*/
        ],
        Creation: {
          // PartnerID: null,
          // UserName: null,
          // DateTime: "2022-02-10T17:07:00",
        },
        Modification: {
          // PartnerID: null,
          // UserName: null,
          // DateTime: "2022-02-10T17:07:00",
        },
        Documents: [],
        Stream: {
          Operations: [],
          /*StreamID: 2137701,
          Operations: [
            {
              OperationID: 2137701,
              OperationType: "C",
              OperationDate: null,
              OperationCMR: null,
              OperationStatus: "R",
              MyRole: "MC",
              Partners: [
                {
                  PartnerID: 100099,
                  PartnerRole: "MC",
                  PartnerFullName: null
                },
                {
                  PartnerID: 216323,
                  PartnerRole: "MT",
                  PartnerFullName: null
                },
                {
                  PartnerID: 0,
                  PartnerRole: "MD",
                  PartnerFullName: "Carrefour"
                }
              ],
              POVs: null,
              HasNewMessages: null,
              HasMyDoc: null,
              HasOtherDoc: null
            },
            {
              OperationID: 2137702,
              OperationType: "D",
              OperationDate: null,
              OperationCMR: null,
              OperationStatus: "N",
              MyRole: "MC",
              Partners: [
                {
                  PartnerID: 100099,
                  PartnerRole: "MC",
                  PartnerFullName: null
                },
                {
                  PartnerID: 216323,
                  PartnerRole: "MT",
                  PartnerFullName: null
                },
                {
                  PartnerID: 0,
                  PartnerRole: "MD",
                  PartnerFullName: "Carrefour"
                }
              ],
              POVs: null,
              HasNewMessages: null,
              HasMyDoc: null,
              HasOtherDoc: null
            }
          ]*/
        },
        Messages: [
          /*{
            SenderClientID: 100099,
            SenderUserName: "Jean Pierre",
            SentAt: "1980-10-14T12:50:00",
            MessageText: "J'ai reçu 3 palettes au lieu de 4"
          },
          {
            SenderClientID: 216323,
            SenderUserName: "Pierre",
            SentAt: "2021-01-01T00:00:00",
            MessageText: "Tu es sûr car j'ai compté au chargement avec un huissier"
          },
          {
            SenderClientID: 100099,
            SenderUserName: "Jean Pierre",
            SentAt: "2022-01-03T12:00:00",
            MessageText: "Ton huisser ne sait pas compter"
          },
          {
            SenderClientID: 216323,
            SenderUserName: "Pierre",
            SentAt: "2022-01-05T14:00:00",
            MessageText: "Recompte encore"
          }*/
        ],
        IsDraft: undefined,
      },
      // Partners
      partnerDetails: [],
      // Pallets
      displayPallets: [],
      // Notes form
      inputMessage: "",
      preEstablishedNote: null,
      // Form
      isSubmitting: false,
      isResetting: false,
      isDuplicating: false,
      isValidating: false,
      OperationDateUTC: "",
      OperationDateFormatted: "",
      OperationDateSelected: "",
      todayDate: "",
      validateAndPrint: false,
      // Watcher
      initialSyncedOperation: {},
      modifiedOperation: {},
      fullyLoaded: false,
      isFetching: false,
      // Docs
      isUploadingDoc: false,
      docLoadPercentage: 0,
      docLoadError: null,
      docLoadResponse: "",
      FileBadgeVariant: "primary",
      // Camera
      noRearCamera: false,
      noFrontCamera: false,
      loadingCamera: false,
      initCamera: false,
      camera: "auto",
      screenshot: null,
      // Collapse
      collapses: {},
    };
  },
  async created() {
    console.log("√ Created", this.$route.params.OperationUID, this.syncedOperation.action, this.fullyLoaded);
    // Passed params
    // First created actions when /edit
    if (this.$route.params.OperationUID != null) {
      // Switch to edit
      this.$set(this.syncedOperation, "action", "edit");

      // Call data when Pallets are loaded
      const waitPallets = setInterval(() => {
        console.log("√ Do we received Pallets ?", this.getPallets.length > 0);
        if (this.getPallets.length > 0) {
          // Set / Reset Multiselect options
          this.displayPallets = _.orderBy(this.$mainFunctions.noReactiveCopy(this.getDisplayPallets), "DisplayOrder") ?? [];

          // Then call data
          this.callData(this.$route.params.OperationUID);
          clearInterval(waitPallets);
        }
      }, 100);
    }
  },
  computed: {
    ...mapGetters([
      "getDevice",
      "getPartnerByPartnerID",
      "getPartners", // Added to prevent partners not loaded
      "getPallets",
      "getCountries",
      "getPallet",
      "getDefaultPallets",
      //"getMorePallets",
      "getDisplayPallets",
      "getUserClientID",
      "getUserID",
      "getUserName",
      "getOperationTID",
      "getFilteredOperations",
      "getPreviousOperationID",
      "getNextOperationID",
    ]),
    // ...mapState(["Pallets", "getPallets", "getDisplayPallets", "getDefaultPallets"]), // Not working
    // Pallets: function () {
    //   /* Returns only ByDefault === false && DisplayOrder > 0 Pallets in order */
    //   //return this.getMorePallets; // Not ordered
    //   // return _.orderBy(this.getMorePallets, "DisplayOrder") ?? []; // Ordered

    //   /* Returns only && DisplayOrder > 0 Pallets in order */
    //   //return this.getDisplayPallets; // Not ordered
    //   //https://logaretm.com/blog/vue-composition-api-non-reactive-objects/
    //   //return _.orderBy(Object.freeze(this.getDisplayPallets), "DisplayOrder") ?? []; // Ordered

    //   // NON-REACTIVE
    //   return _.orderBy(this.$mainFunctions.noReactiveCopy(this.getDisplayPallets), "DisplayOrder") ?? [];
    // },
    Partners() {
      return this.getPartners;
    },
    CountryISO() {
      return this.getCountries;
    },
    FilteredOperations() {
      return this.getFilteredOperations;
    },
    computedFilteredOperationsSelected() {
      console.log("computedFilteredOperationsSelected:", this.getFilteredOperations);
      if (typeof this.getFilteredOperations !== "undefined")
        if (this.getFilteredOperations.Selected !== null) return Object.values(this.getFilteredOperations.Selected).filter((f) => f !== undefined);
        else return [];
      else return [];
    },
    // Print
    operationURL() {
      return window.location.href;
    },
    // Hash
    hashedInitialSyncedOperation() {
      return hash(this.initialSyncedOperation);
    },
    hashedSyncedOperation() {
      return hash(this.syncedOperation);
    },
    // Statement filter
    hasStatement() {
      if (this.getMyPOVIndex() != null && typeof this.syncedOperation.POVs !== "undefined")
        return [
          this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets.map((p) => p.FP == parseInt(p.FP, 10) && parseInt(p.FP) !== 0).reduce(
            (a, b) => a || b,
            false
          ),
          this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets.map((p) => p.FR == parseInt(p.FR, 10) && parseInt(p.FR) !== 0).reduce(
            (a, b) => a || b,
            false
          ),
        ].reduce((a, b) => a || b, false);
      else return false;
    },

    // Device
    isMobileDevice() {
      return this.getDevice === "mobile" ? true : false;
    },
  },
  mixins: [helpers],
  methods: {
    // Watcher debounce
    // Used to make a deep diff of operatio object
    diffSyncedOperation: _.debounce(function (n) {
      console.log(
        "√ debounced diff Watch::syncedOperation",
        "n::",
        n,
        "initialSyncedOperation::",
        this.initialSyncedOperation,
        this.$mainFunctions.diff(n, this.initialSyncedOperation)
      );

      // Store a non-reactive compared object
      let keepKeys = ["OperationUID", "OperationID", "Role", "PalletID", "PartnerID", "PartnerRole", "StreamID", "xData"]; //"RefOP" //"FP", "FR"
      this.modifiedOperation = Object.assign({}, this.$mainFunctions.diff(n, this.initialSyncedOperation, false, false, keepKeys)); // Dernier arstreament = keepIndexes
      console.log("syncedOperation > modifiedOperation::", this.modifiedOperation);

      // Store ( in store ) a boolean to know if there is an edited operation
      if (Object.keys(this.modifiedOperation).length !== 0)
        // If there is ≠ in operation
        this.$store.dispatch(HAS_OPERATION_DRAFT).then(() => {});
      // If there is no ≠ in operation
      else this.$store.dispatch(RESET_OPERATION_DRAFT).then(() => {});
    }, 300), // Reducing delay from 1000 to 300 to prevent quick send errors

    // ThirdPartiesPanel
    emitThirdPartiesPanel(id) {
      console.log("emitThirdPartiesPanel::", id, this.syncedOperation.formstep);
      // this.syncedOperation.formstep = 0;
      // this.syncedOperation.thirdpartyEdit = id;
      //REACTIVE
      this.$set(this.syncedOperation, "formstep", 0);
      this.$set(this.syncedOperation, "thirdpartyEdit", id);
    },

    // Form
    // Datepicker
    onChangeDate(date) {
      // The date from a OperationDateFormatted date or from a French filling
      // https://momentjs.com
      //this.syncedOperation.OperationDate = moment(String(date), "DD/MM/YYYY").format();
      //REACTIVE
      // this.$set(this.syncedOperation, "OperationDate", moment(String(date), "DD/MM/YYYY").format());

      // Localize moment
      if (this.$language.current) moment.locale(this.$language.current);

      // console.log(moment("31/08/2021", "DD/MM/YYYY").format("YYYY-MM-DD"));
      // console.log(moment("2021-08-31", "YYYY-MM-DD").format("DD/MM/YYYY"));
      let currentLocaleFormat = "YYYY-MM-DD";
      if (this.$language.current && this.$language.current === "fr_FR") currentLocaleFormat = "DD/MM/YYYY";

      console.log(
        "Date::onChangeDate",
        this.$language.current,
        currentLocaleFormat,
        "Date source:",
        date,
        "Date source to string:",
        String(date),
        moment(date),
        moment(String(date), currentLocaleFormat),
        "Iso string:",
        moment(new Date(String(date))).toISOString(),
        "format:",
        moment(new Date(String(date))).format(),
        "currentLocaleFormat:",
        moment(new Date(String(date)), currentLocaleFormat).format(),
        "currentLocaleFormat to DD/MM/YYYY:",
        moment(new Date(String(date)), currentLocaleFormat).format("DD/MM/YYYY"),
        "toLocaleDateString:",
        new Date(date).toLocaleDateString(),
        "localized final solution:",
        moment(String(date), currentLocaleFormat).format()
      );

      //this.$set(this.syncedOperation, "OperationDate", moment(String(date)).toISOString());
      //this.$set(this.syncedOperation, "OperationDate", moment(new Date(String(date))).format());
      this.$set(this.syncedOperation, "OperationDate", moment(String(date), currentLocaleFormat).format());
      this.$set(this, "OperationDateUTC", moment(String(date), currentLocaleFormat).format());
    },
    onDateContext(ctx) {
      console.log(
        "Date::onDateContext",
        ctx,
        ctx.selectedDate,
        moment(new Date(String(ctx.selectedFormatted))).format(),
        moment(new Date(ctx.selectedDate)).format()
      );
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.OperationDateFormatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      //this.OperationDateSelected = ctx.selectedYMD;
      this.OperationDateSelected = moment(new Date(ctx.selectedDate)).format();
    },
    // Documents
    // On change file
    onSelectedFiles: function (event) {
      this.isUploadingDoc = true;
      var files = event.target.files;
      var url = this.getDocumentsByPOV("me").DocumentPutURL;
      //      var url = "https://www.concipio.fr/cors.php";
      for (var i = 0; i < files.length; i++) {
        // @todo : wilhem, a voir si on garde la progression de l'evenement...
        const onUploadProgress = (event) => {
          const percentage = Math.round((100 * event.loaded) / event.total);
          this.docLoadPercentage = percentage;
          console.log(file.name + " : " + percentage);
        };
        const file = files[i];
        const formData = new FormData();
        formData.append(file.name, file);
        Vue.axios
          .post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
          })
          .then((response) => {
            if (response.data.slice(0, 1) === "+") {
              this.docLoadError = false;
              this.docLoadResponse = vm.$gettext("Document successfully uploaded.");
              this.FileBadgeVariant = "success";

              // Store message to set a notification
              (async () => {
                let response = null;
                let errorStatus = null;
                let errorData = null;
                try {
                  response = await ApiService.post(
                    "/operation" + (typeof this.syncedOperation.OperationUID !== "undefined" ? "?ID=" + this.syncedOperation.OperationUID : ""),
                    {
                      Messages: [
                        {
                          SenderClientID: this.getUserClientID,
                          SenderUserName: this.getUserName,
                          SentAt: new Date(),
                          MessageText: vm.$gettext("Document added."),
                        },
                      ],
                      OperationID: this.syncedOperation.OperationID,
                      OperationType: this.syncedOperation.OperationType,
                    }
                  );
                } catch (error) {
                  console.error("onSelectedFiles:: Error response:", error.response.data, error.response.status, error.response.headers);

                  errorStatus = error.response.status;
                  errorData = error.response.data.errors.join(", ");
                } finally {
                  console.log(response);
                  console.log(errorStatus);
                  this.isUploadingDoc = false;

                  if (response === null && errorStatus !== null) {
                    // Error
                    window.swalAlert.fire({
                      title: errorStatus,
                      html: vm.$gettext("Damned, we are sorry, we have an error...") + (errorData !== null ? "<br/><code> " + errorData + " </code>" : ""),
                      icon: "error",
                      confirmButtonClass: "btn btn-secondary",
                      footer: '<a href="mailto:support@opalean.fr">' + vm.$gettext("Need to contact support ?") + "</a>",
                    });
                    // Set status
                    this.$set(this.modifiedOperation, "status", "error");
                  } else if (response !== null && response.status === 200) {
                    console.info("onSelectedFiles:: Success response:", response);

                    // // Success, so reset Draft
                    // this.$store.dispatch(RESET_OPERATION_DRAFT).then(() => {});

                    // // Set status
                    // this.$set(this.modifiedOperation, "status", "success");

                    // Success
                    // window.swalToast.fire({
                    //   title: actionTitle + " " + vm.$gettext("Operation"),
                    //   html: vm.$gettext("Document has been successfully uploaded and notification sended") + " <em>" + actionMessage + "</em> !",
                    //   icon: "success",
                    //   timer: 2000,
                    //   confirmButtonClass: "btn btn-secondary",
                    //   footer: vm.$gettext("Operation will be automatically reloaded in few seconds...") + '<a href="">' + vm.$gettext("Back to list") + '</a>',
                    // });
                  }
                }
              })();

              // Then, reload
              setTimeout(() => {
                this.clearDocFiles();
                this.callData(this.syncedOperation.OperationUID);
              }, 500);
            } else {
              this.docLoadError = true;
              this.docLoadResponse = vm.$gettext("There was an error while trying to upload the document :") + " " + response.data.substr(5);
              this.FileBadgeVariant = "danger";
              this.isUploadingDoc = false;

              // Then, reset
              setTimeout(() => {
                this.clearDocFiles();
              }, 500);
            }
          })
          .catch((error) => {
            this.docLoadError = true;
            this.docLoadResponse = vm.$gettext("There was an error while trying to upload the document :") + " " + error.message;

            // Then, reset
            setTimeout(() => {
              this.clearDocFiles();
            }, 500);
          });
      }
    },
    // Reset files
    clearDocFiles() {
      this.docLoadPercentage = 0;
      this.docLoadError = null;
      this.docLoadResponse = "";
      this.$refs["FilesInput"].reset();
    },
    deleteDocument(url) {
      Vue.axios
        .delete(url)
        .then((response) => {
          console.log("deleteDocument::", response.data);

          // Store message to set a notification
          (async () => {
            let response,
              errorStatus,
              errorData = null;
            try {
              response = await ApiService.post(
                "/operation" + (typeof this.syncedOperation.OperationUID !== "undefined" ? "?ID=" + this.syncedOperation.OperationUID : ""),
                {
                  Messages: [
                    {
                      SenderClientID: this.getUserClientID,
                      SenderUserName: this.getUserName,
                      SentAt: new Date(),
                      MessageText: vm.$gettext("Document deleted."),
                    },
                  ],
                  OperationID: this.syncedOperation.OperationID,
                  OperationType: this.syncedOperation.OperationType,
                }
              );
            } catch (error) {
              console.error("deleteDocument:: Error response:", error.response.data, error.response.status, error.response.headers);

              errorStatus = error.response.status;
              errorData = error.response.data.errors.join(", ");
            } finally {
              console.log(response);
              console.log(errorStatus);
              this.isSubmitting = false;

              if (response === null && errorStatus !== null) {
                // Error
                window.swalAlert.fire({
                  title: errorStatus,
                  html: vm.$gettext("Damned, we are sorry, we have an error...") + (errorData !== null ? "<br/><code> " + errorData + " </code>" : ""),
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary",
                  footer: '<a href="mailto:support@opalean.fr">' + vm.$gettext("Need to contact support ?") + "</a>",
                });
                // Set status
                this.$set(this.modifiedOperation, "status", "error");
              } else if (response !== null && response.status === 200) {
                console.info("deleteDocument:: Success response:", response);

                // // Success, so reset Draft
                // this.$store.dispatch(RESET_OPERATION_DRAFT).then(() => {});

                // // Set status
                // this.$set(this.modifiedOperation, "status", "success");

                // Success
                // window.swalToast.fire({
                //   title: actionTitle + " " + vm.$gettext("Operation"),
                //   html: vm.$gettext("Document has been successfully deleted and notification sended") + " <em>" + actionMessage + "</em> !",
                //   icon: "success",
                //   timer: 2000,
                //   confirmButtonClass: "btn btn-secondary",
                //   footer: vm.$gettext("Operation will be automatically reloaded in few seconds...") + '<a href="">' + vm.$gettext("Back to list") + '</a>',
                // });
              }
            }
          })();

          // Then, reload
          setTimeout(() => {
            this.callData(this.syncedOperation.OperationUID);
          }, 500);
        })
        .catch((error) => {
          console.log("deleteDocument::", error.message);

          // Then, reset
          setTimeout(() => {
            this.clearDocFiles();
          }, 500);
        });
    },

    // Medias
    // Handle camera via navigator.mediaDevices to take pictures
    // https://codepen.io/chrisbeast/pen/ebYwpX
    async onCameraInit() {
      this.noRearCamera = false;
      this.noFrontCamera = false;
      this.loadingCamera = true;
      this.initCamera = true;

      // Check for rear camera
      try {
        await navigator.mediaDevices.getUserMedia({ video: { facingMode: { exact: "environment" } } });
      } catch (error) {
        console.error("onCameraInit:: Rear camera Error :", error.name);
        if (error.name === "OverconstrainedError" || error.name === "NotAllowedError" || error.name === "NotFoundError" || error.name === "NotReadableError") {
          this.noRearCamera = true;
        }
      } finally {
        console.log("onCameraInit:: Do something Rear", this.noRearCamera, this.noFrontCamera);
      }

      // Check for Front camera
      try {
        await navigator.mediaDevices.getUserMedia({ video: { facingMode: "user" } });
      } catch (error) {
        console.error("onCameraInit:: Rear camera Error :", error.name);
        if (error.name === "OverconstrainedError" || error.name === "NotAllowedError" || error.name === "NotFoundError" || error.name === "NotReadableError") {
          this.noFrontCamera = true;
        }
      } finally {
        console.log("onCameraInit:: Do something Front", this.noRearCamera, this.noFrontCamera);
      }

      // Finally init the right camera
      if (!this.noFrontCamera) this.setCamera("user");
      else if (!this.noRearCamera) this.setCamera("environment");
      else console.error("onCameraInit:: No camera available");

      console.log("onCameraInit:: End", this.noRearCamera, this.noFrontCamera);
    },

    setCamera(face) {
      if (typeof video !== "undefined") {
        this.loadingCamera = false;
        this.stop();
        this.stream(face);
      }
    },
    stop() {
      return video.srcObject && video.srcObject.getTracks().map((t) => t.stop());
    },
    stream(face) {
      if (face === "user") {
        return navigator.mediaDevices.getUserMedia({ video: { facingMode: face } }).then((stream) => {
          video.srcObject = stream;
          this.localstream = stream;
        });
      }
      if (face === "environment") {
        return navigator.mediaDevices.getUserMedia({ video: { facingMode: { exact: face } } }).then((stream) => {
          video.srcObject = stream;
          this.localstream = stream;
        });
      }
    },
    // Used to switch between cameras
    switchCamera() {
      switch (this.camera) {
        case "front":
          this.setCamera("environment");
          this.camera = "rear";
          break;
        case "rear":
          this.setCamera("user");
          this.camera = "front";
          break;
        case "auto":
          this.setCamera("environment");
          this.camera = "rear";
          break;
      }
    },
    unpause() {
      video.play();
      this.camera = "auto";
    },
    pause() {
      video.pause();
      this.camera = "off";
    },
    //
    doScreenshot() {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext("2d").drawImage(video, 0, 0);
      screenshotImage.src = canvas.toDataURL("image/webp");
      screenshotImage.classList.remove("d-none");
      this.uploadFile(canvas.toDataURL("image/webp"));
    },
    uploadFile: function (file) {
      this.isUploadingDoc = true;
      var url = this.getDocumentsByPOV("me").DocumentPutURL;
      const formData = new FormData();
      formData.append("Screenshot", file);
      Vue.axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.slice(0, 1) === "+") {
            this.docLoadError = false;
            this.docLoadResponse = vm.$gettext("Document successfully uploaded.");
            this.FileBadgeVariant = "success";

            // Store message to set a notification
            (async () => {
              let response = null;
              let errorStatus = null;
              let errorData = null;
              try {
                response = await ApiService.post(
                  "/operation" + (typeof this.syncedOperation.OperationUID !== "undefined" ? "?ID=" + this.syncedOperation.OperationUID : ""),
                  {
                    Messages: [
                      {
                        SenderClientID: this.getUserClientID,
                        SenderUserName: this.getUserName,
                        SentAt: new Date(),
                        MessageText: vm.$gettext("Document added."),
                      },
                    ],
                    OperationID: this.syncedOperation.OperationID,
                    OperationType: this.syncedOperation.OperationType,
                  }
                );
              } catch (error) {
                console.error("onSelectedFiles:: Error response:", error.response.data, error.response.status, error.response.headers);

                errorStatus = error.response.status;
                errorData = error.response.data.errors.join(", ");
              } finally {
                console.log(response);
                console.log(errorStatus);
                this.isUploadingDoc = false;

                if (response === null && errorStatus !== null) {
                  // Error
                  window.swalAlert.fire({
                    title: errorStatus,
                    html: vm.$gettext("Damned, we are sorry, we have an error...") + (errorData !== null ? "<br/><code> " + errorData + " </code>" : ""),
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                    footer: '<a href="mailto:support@opalean.fr">' + vm.$gettext("Need to contact support ?") + "</a>",
                  });
                  // Set status
                  this.$set(this.modifiedOperation, "status", "error");
                } else if (response !== null && response.status === 200) {
                  console.info("onSelectedFiles:: Success response:", response);

                  // // Success, so reset Draft
                  // this.$store.dispatch(RESET_OPERATION_DRAFT).then(() => {});

                  // // Set status
                  // this.$set(this.modifiedOperation, "status", "success");

                  // Success
                  // window.swalToast.fire({
                  //   title: actionTitle + " " + vm.$gettext("Operation"),
                  //   html: vm.$gettext("Document has been successfully uploaded and notification sended") + " <em>" + actionMessage + "</em> !",
                  //   icon: "success",
                  //   timer: 2000,
                  //   confirmButtonClass: "btn btn-secondary",
                  //   footer: vm.$gettext("Operation will be automatically reloaded in few seconds...") + '<a href="">' + vm.$gettext("Back to list") + '</a>',
                  // });
                }
              }
            })();

            // Then, reload
            setTimeout(() => {
              this.clearDocFiles();
              this.callData(this.syncedOperation.OperationUID);
            }, 500);
          } else {
            this.docLoadError = true;
            this.docLoadResponse = vm.$gettext("There was an error while trying to upload the document :") + " " + response.data.substr(5);
            this.FileBadgeVariant = "danger";
            this.isUploadingDoc = false;

            // Then, reset
            setTimeout(() => {
              this.clearDocFiles();
            }, 500);
          }
        })
        .catch((error) => {
          this.docLoadError = true;
          this.docLoadResponse = vm.$gettext("There was an error while trying to upload the document :") + " " + error.message;

          // Then, reset
          setTimeout(() => {
            this.clearDocFiles();
          }, 500);
        });
    },

    // Local helpers
    // Use local datas from json or store
    getMyPOV: function (role) {
      console.info("getMyPOV::", role);
      if (role !== null && typeof role !== "undefined" && this.syncedOperation.POVs.filter((p) => p.Role === role).length > 0)
        return this.syncedOperation.POVs.filter((p) => p.Role == role);
      else return [];
    },
    getMyPOVIndex: function (role = this.syncedOperation.MyRole) {
      console.info("getMyPOVIndex::", role);
      if (role !== null && typeof role !== "undefined" && this.syncedOperation.POVs.length > 0) {
        let index = this.syncedOperation.POVs.findIndex((pov) => {
          return pov.Role === role ? true : false;
        });
        return index !== -1 ? index : null;
      } else return null; // Return an error in catch callData
    },
    getThirdPartyPOVValue: function (role, index, key, PalletID = null) {
      // try {
      //   console.log(
      //     "getThirdPartyPOV::",
      //     this.syncedOperation.POVs.filter(i => i.Role === role),
      //     typeof this.syncedOperation.POVs.filter(p => p.Role === role)[0].Pallets[index]
      //   );
      // } catch (e) {
      //   alert("[ERROR]getThirdPartyPOV::" + e);
      // }
      if (
        role !== null &&
        typeof role !== "undefined" &&
        typeof this.syncedOperation.POVs !== "undefined" &&
        this.syncedOperation.POVs.filter((p) => p.Role === role).length > 0 &&
        typeof this.syncedOperation.POVs.filter((p) => p.Role === role)[0].Pallets[index] !== "undefined"
      ) {
        // console.log(
        //   "getThirdPartyPOVValue::",
        //   PalletID,
        //   this.syncedOperation.POVs.filter((p) => p.Role === role)[0].Pallets.filter((p) => p.PalletID == PalletID)
        // );
        return PalletID !== null
          ? this.syncedOperation.POVs.filter((p) => p.Role === role)[0].Pallets.filter((p) => p.PalletID == PalletID)[0][key]
          : this.syncedOperation.POVs.filter((p) => p.Role === role)[0].Pallets[index][key];
      } else return "—";
    },
    getThirdPartyPOVIndex: function (role = this.getAllowedThirdPartyType(this.syncedOperation.OperationType, this.syncedOperation.MyRole, "thirdparty")[0]) {
      if (role !== null && typeof role !== "undefined" && this.syncedOperation.POVs.length > 0) {
        let index = this.syncedOperation.POVs.findIndex((pov) => {
          return pov.Role === role ? true : false;
        });
        return index !== -1 ? index : 0;
      } else return 0;
    },
    getPartnerNames() {
      console.log("getPartnerNames::", this.syncedOperation.Partners);

      if (typeof this.syncedOperation.Partners !== "undefined" && this.syncedOperation.Partners.length > 0)
        this.syncedOperation.Partners.forEach((p, index) => {
          console.log("getPartnerNames::p", p);
          if (p.PartnerID !== null && p.PartnerID !== 0) {
            let _Partner = this.getPartnerByPartnerID(p.PartnerID);

            // NOT REACTIVE
            //this.syncedOperation.Partners[index] = { ...p, Name: _Partner.Name, PartnerID: _Partner.PartnerID };

            // REACTIVE
            // Update v-model
            // Tableaux https://fr.vuejs.org/v2/guide/reactivity.html#Pour-les-tableaux
            if (typeof _Partner !== "undefined" && typeof _Partner.PartnerID !== "undefined")
              Vue.set(this.syncedOperation.Partners, index, { ...p, Name: _Partner.Name, PartnerID: _Partner.PartnerID });
            else console.log("getPartnerNames::No Partner");
          } else console.log("getPartnerNames::No PartnerID");
        });
      else console.log("getPartnerNames::No Partners");
    },
    getPalletNames() {
      console.log("getPalletNames::", this.syncedOperation.POVs);

      if (typeof this.syncedOperation.POVs !== "undefined" && this.syncedOperation.POVs.length > 0)
        this.syncedOperation.POVs.forEach((p, pov) => {
          console.log("getPalletNames::p", p);
          p.Pallets.forEach((a, index) => {
            if (a.PalletID !== null && p.PalletID !== 0) {
              let _Pallet = this.getPallet(a.PalletID);

              // NOT REACTIVE
              //this.syncedOperation.POVs[pov].Pallets[index] = { ...a, PalletID: _Pallet.PalletID, PalletName: _Pallet.PalletName };

              // REACTIVE
              // Update v-model
              // Tableaux https://fr.vuejs.org/v2/guide/reactivity.html#Pour-les-tableaux
              if (typeof _Pallet !== "undefined" && typeof _Pallet.PalletID !== "undefined")
                Vue.set(this.syncedOperation.POVs[pov].Pallets, index, { ...a, PalletID: _Pallet.PalletID, PalletName: _Pallet.PalletName });
              else console.log("getPalletNames::No Pallet");
            } else console.log("getPalletNames::No PalletID");
          });
        });
      else console.log("getPalletNames::No POVs");
    },
    haveRequiredThirdParty() {
      console.log("haveRequiredThirdParty::", Object.keys(this.syncedOperation.Partners).length);
      let requiredPartner = this.syncedOperation.Partners.find(
        (p) => p.PartnerRole === this.getAllowedThirdPartyType(this.syncedOperation.OperationType, this.syncedOperation.MyRole, "thirdparty")[0]
      );
      console.log(
        "requiredPartner::",
        this.getAllowedThirdPartyType(this.syncedOperation.OperationType, this.syncedOperation.MyRole, "thirdparty")[0],
        requiredPartner
      );
      //return Object.keys(this.syncedOperation.Partners).every(k => !this.syncedOperation.Partners[k]) ? false : true; // null OR >0
      return Object.keys(this.syncedOperation.Partners).length > 1 && typeof requiredPartner !== "undefined" ? true : false; // > 1
    },
    getPalletValue(id, value) {
      console.log("getPalletValue::", id, value);
      let _Pallet = [];
      if (typeof id !== "undefined" && typeof value !== "undefined") _Pallet = this.getPallet(id);
      if (typeof _Pallet !== "undefined") return _Pallet[value];
      else return [];
    },
    flushPOVbyIndex(data, index) {
      console.log("flushPOVbyIndex::", data, index, "data.PalletID", data.PalletID);
      setTimeout(() => {
        this.$set(this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index], "FP", null);
        this.$set(this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index], "FR", null);
      }, 10);
    },
    // Documents
    getDocumentsByPOV: function (pov) {
      let d;

      // Then pick one or many depends the POV : we want my role or we want the counterpart ?
      switch (pov) {
        case "me":
          d = this.syncedOperation.Documents.filter((d) => d.SenderPartnerID === this.getUserClientID)[0];
          if (d === undefined) d = this.syncedOperation.Documents.filter((d) => d.SenderPartnerID === 0)[0];
          break;
        case "thirdparty":
          d = this.syncedOperation.Documents.filter(
            (d) => d.SenderPartnerID == parseInt(this.getOperationThirdPartyDetails_refactored(this.syncedOperation, "thirdparty", "PartnerID"))
          )[0];
          break;
        case "thirdparties":
          d = this.syncedOperation.Documents.filter((d) => d.SenderPartnerID !== this.getUserClientID);
          break;
        case "onlyme":
          d = this.syncedOperation.Documents.filter((d) => d.SenderPartnerID === 0)[0];
          break;
        case "all":
          d = this.syncedOperation.Documents;
          break;
      }

      // Finaly return
      if (typeof d !== "undefined") return d;
      else return {}; // Fix returning object
    },
    // Statement
    addStatement() {
      var newPallet = {
        PalletID: 0,
        PalletName: undefined,
      };
      this.syncedOperation.POVs.find((p) => p.Role === this.syncedOperation.MyRole).Pallets.push(newPallet); // A mettre à jour POVs = array
    },
    validateStatement() {
      this.isValidating = true;
      // Actions
      setTimeout(() => {
        this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets.forEach((p, index) => {
          this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index].ValidatedFP = true;
          this.validateFPFR(this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index].ValidatedFP, index);
          this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index].ValidatedFR = true;
          this.validateFPFR(this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index].ValidatedFR, index, null, "FR");
        });
        this.syncedOperation.allValidated = true;
        this.isValidating = false;
      }, 500);
    },
    // TODO TO COMMENT
    // test_keydown_handler(event) {
    //   console.log("test_keydown_handler:: FPFR", "event::", event);
    //   if (event.which === 13) {
    //     // The key pressed was the enter key
    //   }
    // },
    // TODO TO COMMENT
    // formatterFPFR(value = null) {
    //   console.log(
    //     "formatterFPFR::",
    //     "value::",
    //     value,
    //     typeof value,
    //     value != null && typeof value == "string" ? value.charAt(0) : null,
    //     value != null && typeof value == "string" ? value.substring(1) : null
    //   );
    // },
    validateFPFR(checked, index, value = null, field = "FP", event = undefined) {
      console.info(
        "validateFPFR::",
        checked,
        index,
        "value::",
        value,
        typeof value,
        value != null && typeof value == "string" ? value.charAt(0) : null,
        value != null && typeof value == "string" ? value.substring(1) : null,
        "field::",
        field,
        typeof this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field],
        this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field],
        typeof this.syncedOperation.POVs[this.getThirdPartyPOVIndex()].Pallets[index][field],
        this.syncedOperation.POVs[this.getThirdPartyPOVIndex()].Pallets[index][field],
        "value is ≠ ?::",
        value != this.syncedOperation.POVs[this.getThirdPartyPOVIndex()].Pallets[index][field],
        "event::",
        event,
        "status::",
        this.syncedOperation.OperationStatus
      );
      if (this.getMyPOVIndex() === null) return;

      //if (value != null && typeof value == "string") value.toString(value); // If value came from b-spinbutton instead of an input string
      // if (this.isMobileDevice) {
      //   console.info(
      //     "validateFPFR:: isMobileDevice",
      //     value,
      //     this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field],
      //     this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index]["last" + field]
      //   );
      // }

      // if (event) {
      //   console.info("validateFPFR:: We got an event !", event, "initial value is  = ", this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field]);

      //   setTimeout(() => {
      //     // if (event === "plus")
      //     //   this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] =
      //     //     value != this.syncedOperation.POVs[this.getThirdPartyPOVIndex()].Pallets[index][field] ? value : null;
      //     // if (event === "dash")
      //     //   this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] =
      //     //     value != this.syncedOperation.POVs[this.getThirdPartyPOVIndex()].Pallets[index][field] ? value : null;
      //     if (event === "change") {
      //       if (this.syncedOperation.OperationStatus === "M")
      //         this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] =
      //           value != this.syncedOperation.POVs[this.getThirdPartyPOVIndex()].Pallets[index][field] ? Number(value) : null;
      //       else this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] = Number(value);

      //       // TODO REACTIVE
      //       // this.$set(
      //       //   this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index],
      //       //   field,
      //       //   value != this.syncedOperation.POVs[this.getThirdPartyPOVIndex()].Pallets[index][field] ? value : null
      //       // ); // NOT WORKING when Default setted

      //       console.info("validateFPFR::", "updated value is now = ", this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field]);
      //     }
      //   }, 50);
      // }

      if (event) {
        console.info("validateFPFR:: We got an event !", event, "initial value is  = ", this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field]);
        if (event === "change") {
          if (this.syncedOperation.OperationStatus === "M") {
            if (value != this.syncedOperation.POVs[this.getThirdPartyPOVIndex()].Pallets[index][field])
              Vue.set(this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index], field, Number(value));
            else Vue.set(this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index], field, null);
          } else {
            Vue.set(this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index], field, Number(value));
          }

          console.info("validateFPFR::", "updated value is now = ", this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field]);
        }
      }

      // Fix when it's not an OperationStatus === "M"
      if (checked !== undefined) {
        // Check if user fill a number, not empty, || 0, && != of ThirdPartyPOV
        if (
          ((typeof this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] === "number" ||
            typeof this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] === "string") &&
            this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] != "" &&
            this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] !==
              this.syncedOperation.POVs[this.getThirdPartyPOVIndex()].Pallets[index][field]) ||
          this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] === 0
        ) {
          console.info("validateFPFR::", "User filled a number ≠ of statement");
          // Needs to debounce because it's happening right at the same time from check
          setTimeout(() => {
            if (field === "FP") this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index].ValidatedFP = false;
            else if (field === "FR") this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index].ValidatedFR = false;
          }, 100);
          // If not, then copy from counterpart statement
        } else {
          console.info("validateFPFR::", "User ain't filled a number");

          if (checked) {
            // this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index].ValidatedFP = true;
            this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] =
              this.syncedOperation.POVs[this.getThirdPartyPOVIndex()].Pallets[index][field];
          } else {
            // this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index].ValidatedFP = false;
            this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] = null; //undefined
          }
        }
      } else {
        // Check for all type of operation status : if value == "" return null
        // if (
        //   (typeof this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] === "number" ||
        //     typeof this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] === "string") &&
        //   this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] != "" &&
        //   this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] !==
        //     this.syncedOperation.POVs[this.getThirdPartyPOVIndex()].Pallets[index][field]
        // ) {

        // No value
        if (typeof value != "string" && value == "") {
          console.info(
            "validateFPFR::nulledFPFR::A",
            "NOT ONLY M STATUS = User ain't filled a number >> Then, replace empty string by null >> NOT nulled for MobileDevice"
          );
          if (!this.isMobileDevice) this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] = null; //undefined
        } else {
          console.info("validateFPFR::nulledFPFR::B", "NOT ONLY M STATUS = User filled a number >> Then, nothing to do");
        }

        // Input error _12
        if (typeof value == "string" && value.charAt(0) == "_") {
          console.info("validateFPFR::nulledFPFR::C", "NOT ONLY M STATUS = User filled a _string with _ >> Then, replace by a number");
          this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] = parseInt(value.substring(1));
        }

        // } else {
        //   console.log("validateFPFR::nulledFPFR::C", "NOT ONLY M STATUS = User ain't filled a number ≠ of statement >> Then, replace empty string by null");
        //   this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] = null; //undefined
        // }
      }
      setTimeout(() => {
        console.info("validateFPFR::", "At the end value is = ", this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field]);
      }, 300);
    },
    // nulledFPFR(index, value, field = "FP") {
    //   console.log(
    //     "nulledFPFR::",
    //     index,
    //     value,
    //     field,
    //     this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field],
    //     typeof this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field],
    //     this.syncedOperation.POVs[this.getThirdPartyPOVIndex()].Pallets[index][field]
    //   );
    //   // Fix when for all OperationStatus
    //   // Check for all type of operation status : if value == "" return null
    //   if (
    //     (typeof this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] === "number" ||
    //       typeof this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] === "string") &&
    //     this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] != "" &&
    //     this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] !== this.syncedOperation.POVs[this.getThirdPartyPOVIndex()].Pallets[index][field]
    //   ) {
    //     console.log("nulledFPFR::", "ALL STATUS = User filled a number ≠ of statement >> Then, nothing to do");
    //   } else {
    //     console.log("nulledFPFR::", "ALL STATUS = User ain't filled a number ≠ of statement >> Then, replace empty string by null");
    //     this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] = null; //undefined
    //   }
    // },
    getStatementState(index, field = "FP") {
      console.log(
        "getStatementState::",
        "My value::",
        this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field],
        Number(this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field]),
        "Thirdparty value::",
        this.getThirdPartyPOVValue(
          this.getAllowedThirdPartyType(this.syncedOperation.OperationType, this.syncedOperation.MyRole, "thirdparty", this.syncedOperation.HisRole)[0],
          index,
          field,
          this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index].PalletID
        ),
        Number(
          this.getThirdPartyPOVValue(
            this.getAllowedThirdPartyType(this.syncedOperation.OperationType, this.syncedOperation.MyRole, "thirdparty", this.syncedOperation.HisRole)[0],
            index,
            field,
            this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index].PalletID
          )
        ),
        "Have Thirdparty value ?::",
        this.getThirdPartyPOVValue(
          this.getAllowedThirdPartyType(this.syncedOperation.OperationType, this.syncedOperation.MyRole, "thirdparty", this.syncedOperation.HisRole)[0],
          index,
          field,
          this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index].PalletID
        ) === "—"
      );
      return typeof this.syncedOperation.OperationStatus === "undefined" ||
        this.syncedOperation.OperationStatus === "B" ||
        this.syncedOperation.OperationStatus === "R" ||
        this.syncedOperation.OperationStatus === "M" ||
        Number(this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field]) ===
          Number(
            this.getThirdPartyPOVValue(
              this.getAllowedThirdPartyType(this.syncedOperation.OperationType, this.syncedOperation.MyRole, "thirdparty", this.syncedOperation.HisRole)[0],
              index,
              field,
              this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index].PalletID
            )
          ) ||
        this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index][field] === undefined ||
        this.getThirdPartyPOVValue(
          this.getAllowedThirdPartyType(this.syncedOperation.OperationType, this.syncedOperation.MyRole, "thirdparty", this.syncedOperation.HisRole)[0],
          index,
          field,
          this.syncedOperation.POVs[this.getMyPOVIndex()].Pallets[index].PalletID
        ) === "—"
        ? null
        : false;
    },
    // Titles showing functions
    haveAnyPallets() {
      if (
        this.syncedOperation.MyRole !== null &&
        typeof this.syncedOperation.MyRole !== "undefined" &&
        this.syncedOperation.POVs.filter((p) => p.Role === this.syncedOperation.MyRole).length > 0 &&
        typeof this.syncedOperation.POVs.filter((p) => p.Role === this.syncedOperation.MyRole)[0].Pallets !== "undefined"
      )
        return Object.keys(this.syncedOperation.POVs.filter((p) => p.Role === this.syncedOperation.MyRole)[0].Pallets).every(
          (k) => !this.syncedOperation.POVs.filter((p) => p.Role === this.syncedOperation.MyRole)[0].Pallets[k]
        )
          ? false
          : true;
      else return false;
    },
    haveAnyNotes() {
      if (
        (this.syncedOperation.Messages !== null && this.syncedOperation.Messages.length > 0) ||
        (this.syncedOperation.Documents !== null && this.syncedOperation.Documents.filter((d) => d.SenderPartnerID !== 0).length > 0)
      )
        return true;
      else return false;
    },
    // Messages
    sendMessage() {
      let _Message = "";
      if (this.inputMessage) {
        /* Way A = Push to object and user store method */
        // this.syncedOperation.Messages.push({
        //   SenderClientID: this.getUserClientID,
        //   SenderUserName: this.getUserName,
        //   SentAt: new Date(),
        //   MessageText: this.inputMessage,
        // });
        _Message = this.inputMessage;
        this.inputMessage = null;
      }
      // Or check for pre established notes
      if (this.preEstablishedNote) {
        console.log(this.preEstablishedNotes.filter((n) => n.value === this.preEstablishedNote)[0]);
        /* Way A = Push to object and user store method */
        // this.syncedOperation.Messages.push({
        //   SenderClientID: this.getUserClientID,
        //   SenderUserName: this.getUserName,
        //   SentAt: new Date(),
        //   MessageText: this.preEstablishedNote + " | " + vm.$gettext(this.preEstablishedNotes.filter((n) => n.value === this.preEstablishedNote)[0].text),
        // });
        _Message = this.preEstablishedNote + " | " + vm.$gettext(this.preEstablishedNotes.filter((n) => n.value === this.preEstablishedNote)[0].text);
        this.preEstablishedNote = null;
      }

      /* Way B = Store message right now */
      // Store message and reload
      if (_Message !== "" && _Message !== null) {
        this.isSubmitting = true;
        this.isFetching = true;
        (async () => {
          let response,
            errorStatus,
            errorData = null;
          try {
            response = await ApiService.post(
              "/operation" + (typeof this.syncedOperation.OperationUID !== "undefined" ? "?ID=" + this.syncedOperation.OperationUID : ""),
              {
                Messages: [
                  {
                    SenderClientID: this.getUserClientID,
                    SenderUserName: this.getUserName,
                    SentAt: new Date(),
                    MessageText: _Message,
                  },
                ],
                OperationID: this.syncedOperation.OperationID,
                OperationType: this.syncedOperation.OperationType,
              }
            );
          } catch (error) {
            console.error("sendMessage:: Error response:", error.response.data, error.response.status, error.response.headers);

            errorStatus = error.response.status;
            errorData = error.response.data.errors.join(", ");
          } finally {
            console.error("sendMessage:: Response:", response, errorStatus);
            this.isSubmitting = false;

            if (response === null && errorStatus !== null) {
              // Error
              window.swalAlert.fire({
                title: errorStatus,
                html: vm.$gettext("Damned, we are sorry, we have an error...") + (errorData !== null ? "<br/><code> " + errorData + " </code>" : ""),
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                footer: '<a href="mailto:support@opalean.fr">' + vm.$gettext("Need to contact support ?") + "</a>",
              });
              // Set status
              this.$set(this.modifiedOperation, "status", "error");
            } else if (response !== null && response.status === 200) {
              console.info("sendMessage:: Success response:", response);

              // Success
              window.swalToast.fire({
                title: vm.$gettext("Updating Operation"),
                html: vm.$gettext("The message has been successfully sended !"),
                icon: "success",
                timer: 2000,
                confirmButtonClass: "btn btn-secondary",
                footer:
                  vm.$gettext("Operation will be automatically reloaded in few seconds...") +
                  '<a href="' +
                  (!this.isMobileDevice ? "/#/operations/list" : "/#/mobile/operations/list/M") +
                  '">' +
                  vm.$gettext("Back to list") +
                  "</a>",
              });

              // Then, reload & clear
              setTimeout(() => {
                this.callData(this.syncedOperation.OperationUID);
              }, 500);
            }
          }
        })();
      }
    },
    // Emit
    emitData(data) {
      console.log("emitData::data", data, typeof data.MyRole, data.MyRole, this.syncedOperation.action);
      console.log("emitData::syncedOperation", this.syncedOperation);
      //let merged = "";
      let merged = { ...this.syncedOperation, ...data };

      // /**
      //  * BUG de reactivité à gérer
      //  * VOIR si cette emitData est necessaire ?
      //  */

      //       // Update POVs Role for create action
      //       if (typeof data.MyRole !== "undefined" && data.MyRole != null && this.syncedOperation.action === "create") {

      //         this.$set(merged.POVs[0], "Role", data.MyRole);
      //         // this.$set(this.syncedOperation.POVs[0], "Role", data.MyRole);
      //         //merged.POVs[0].Role = data.MyRole;
      //         Vue.set(this.syncedOperation.POVs, 0, merged.POVs[0]);

      //         this.syncedOperation.POVs[0].Role = data.MyRole;
      //         console.log("emitData::merged POV Role", merged.POVs[0], merged.POVs[0].Role, "syncedOperation:", this.syncedOperation.POVs[0]);
      //         console.log("emitData::merged POV Role FULL OBJETCS", merged, "syncedOperation:", this.syncedOperation);
      //       } else {
      //         this.$emit("update:syncedOperation", merged); // TODO Check why ?
      //         console.log("emitData::merged", merged);
      //       }

      // /**
      //  * Alternative ?
      //  */

      //Vue.set(this.syncedOperation, merged);
      if (typeof data.MyRole !== "undefined" && data.MyRole != null && this.syncedOperation.action === "create") {
        console.log("emitData::merged POV Role", merged.POVs[0], merged.POVs[0].Role, "syncedOperation:", this.syncedOperation.POVs[0]);

        // this.$set(this.syncedOperation, "TEST_Role", data.MyRole); // WORKING
        // this.$set(this.syncedOperation.POVs, "TEST_Role", data.MyRole); // WORKING
        this.$set(this.syncedOperation.POVs[0], "Role", data.MyRole); // NOT WORKING when Default setted
        // // REACTIVE
        // Vue.set(this.syncedOperation.POVs, 0, { ...this.syncedOperation.POVs[0], Role:  data.MyRole }); // NOT WORKING
        // this.syncedOperation.POVs[0].Role = data.MyRole; // NOT WORKING
      } else {
        // this.$emit("update:syncedOperation", merged); // TODO Check why ?
        Vue.set(this.syncedOperation, merged);

        console.log("emitData::merged", merged);
      }

      console.log("emitData::merged POV Role FULL OBJETCS", merged ? merged : null, "syncedOperation:", this.syncedOperation);
    },
    emitDataPartners(Partners) {
      console.log("emitData::Partners", Partners);
      console.log("emitData::Partners.keys", Object.keys(Partners));
      console.log("emitData::syncedOperation.Partners BEFORE", this.syncedOperation.Partners);

      // Always remove to update last synced Partner
      this.syncedOperation.Partners = this.syncedOperation.Partners.filter((p) => p.PartnerRole !== Object.keys(Partners)[0]);

      var PartnersArray = [];
      Object.values(Partners).forEach((o) => {
        console.log("emitData::Partners.o", o);
        if (o !== null) PartnersArray.push(o);
        //else this.syncedOperation.Partners = this.syncedOperation.Partners.filter(p => p.PartnerRole !== Object.keys(Partners)[0]);
      });

      console.log("emitData::PartnersArray", PartnersArray);
      console.log("emitData::PartnersArray.length", PartnersArray.length);

      // Update & replace
      //if (PartnersArray.length > 0) this.syncedOperation.Partners = PartnersArray;
      // else this.syncedOperation.Partners = [];
      // this.$emit("update:syncedOperation", this.syncedOperation);

      // Merge
      // TODO REACTIVE
      let merged = { ...this.syncedOperation, ...{ Partners: PartnersArray } }; // We will not merge, because, we don't we doublons
      console.log("emitData::Merged", merged);
      let merged2 = [...this.syncedOperation.Partners, ...PartnersArray]; // We will not merge, because, we don't we doublons
      console.log("emitData::Merged Partners", merged2);
      this.syncedOperation.Partners = merged2;
      console.log("emitData::syncedOperation.Partners AFTER ", this.syncedOperation.Partners);

      // this.$emit("update:syncedOperation", merged);

      // // Merge & update
      // let merged = { ...this.syncedOperation.Partners, ...[PartnersArray] }; // We will not merge, because, we don't we doublons
      // console.log("emitData::Merged", merged);
      // this.syncedOperation.Partners = merged;
      // console.log("emitData::syncedOperation.Partners AFTER ", this.syncedOperation.Partners);

      // else this.syncedOperation.Partners = [];

      this.$emit("update:syncedOperation", this.syncedOperation); // TODO Seems to be not necessary !!!
    },
    emitRemovePartners() {
      this.syncedOperation.Partners = [];
      this.$emit("update:syncedOperation", this.syncedOperation); // TODO Seems to be not necessary !!!
    },
    // All others
    validateFlow() {
      this.isSubmitting = true;
      // Actions
      setTimeout(() => {
        // Next step
        this.isSubmitting = false;
        // this.syncedOperation.formstep = 1;
        //REACTIVE
        this.$set(this.syncedOperation, "formstep", 1);
        this.$set(this.syncedOperation, "thirdpartyEdit", undefined); // Needed to reset and allow click event again

        // Modify operation conditionned by operation type
        if (this.syncedOperation.action === "create") {
          console.log("validateFlow::Stream length", this.syncedOperation.Stream.Operations.length);
          if (this.syncedOperation.Stream.Operations.length > 0) {
            // Update stream
            // NO REACTIVE
            //this.syncedOperation.Stream.Operations[0].Partners = this.syncedOperation.Partners;
            // REACTIVE
            // Solution pour updater un tableau dans un tableau ..
            this.$set(this.syncedOperation.Stream.Operations, 0, { ...this.syncedOperation.Stream.Operations[0], Partners: this.syncedOperation.Partners });
          } else {
            // Add stream
            let Stream = {
              OperationType: this.syncedOperation.OperationType,
              OperationStatus: "B",
              MyRole: this.syncedOperation.MyRole,
              Partners: this.syncedOperation.Partners,
            };
            // NO REACTIVE
            // this.syncedOperation.Stream.Operations.push(Stream);
            // REACTIVE
            this.$set(this.syncedOperation.Stream.Operations, 0, Stream);
          }

          // Add Creation
          let _Creation = {
            PartnerID: this.getUserClientID,
            UserName: this.getUserName,
            //DateTime: Date.now(),
            DateTime: moment(new Date()).format(), // Fix issue 18/02/23 when sending a inflow loading | unloading | copy >> OLD : new Date(),
          };
          this.$set(this.syncedOperation, "Creation", _Creation);
        } else if (this.syncedOperation.action === "edit") {
          // Edit stream
          console.log(
            "validateFlow:: edit",
            this.syncedOperation.Stream.Operations.filter((o) => o.OperationUID === this.syncedOperation.OperationUID)
          );
          if (this.syncedOperation.OperationUID != undefined || this.syncedOperation.OperationUID != 0)
            this.syncedOperation.Stream.Operations.filter((o) => o.OperationUID === this.syncedOperation.OperationUID)[0].Partners =
              this.syncedOperation.Partners; // Is this reactive ?
        }
      }, 500);
    },
    // Form
    onSubmitForm: function (event, isDraft = false) {
      console.log("onSubmitForm > syncedOperation::", this.syncedOperation, "StreamID::", this.syncedOperation.Stream.StreamID); //JSON.stringify()
      console.log(
        "onSubmitForm > modifiedOperation::",
        this.modifiedOperation,
        "StreamID::",
        typeof this.modifiedOperation.Stream !== "undefined" ? this.modifiedOperation.Stream.StreamID : "No Stream"
      );
      event.preventDefault();

      // Const
      this.isSubmitting = true;
      this.isFetching = true;
      let actionTitle = this.syncedOperation.action === "create" ? vm.$gettext("Create") : vm.$gettext("Edit");
      let actionMessage = this.syncedOperation.action === "create" ? vm.$gettext("submitted") : vm.$gettext("edited");

      // Remove key-value using destructuring
      const { Stream, ...modifiedOperationNoStream } = this.modifiedOperation;
      if (isDraft) {
        this.syncedOperation.OperationStatus = "B"; // TODO: (Complete) Add draft
        this.syncedOperation.IsDraft = true; // TODO: (Complete) Add draft
      }

      // // Add StreamID
      // let tempOperation = { ...{ Stream: { StreamID: this.syncedOperation.Stream.StreamID } }, ...modifiedOperationNoStream };

      // Remove messages & docs
      let tempOperation = this.$mainFunctions.noReactiveCopy(this.syncedOperation);
      delete tempOperation.Messages;
      delete tempOperation.Documents;

      // Make sure all FP FR are type string and not int ( specially for mobile, because we use a number spinner )

      // POVs Reset all FR statements // BUG TODO REACTIVE // #12
      tempOperation.POVs.forEach((_POV, _POV_index) => {
        _POV.Pallets.forEach((_Pallets, _Pallets_index) => {
          if (
            typeof tempOperation.POVs[_POV_index].Pallets[_Pallets_index].FR != "undefined" &&
            tempOperation.POVs[_POV_index].Pallets[_Pallets_index].FR !== null
          )
            tempOperation.POVs[_POV_index].Pallets[_Pallets_index].FR = tempOperation.POVs[_POV_index].Pallets[_Pallets_index].FR.toString();
          if (
            typeof tempOperation.POVs[_POV_index].Pallets[_Pallets_index].FP != "undefined" &&
            tempOperation.POVs[_POV_index].Pallets[_Pallets_index].FP !== null
          )
            tempOperation.POVs[_POV_index].Pallets[_Pallets_index].FP = tempOperation.POVs[_POV_index].Pallets[_Pallets_index].FP.toString();
        });
      });

      console.log(
        "onSubmitForm > modifiedOperation:: No Stream ",
        modifiedOperationNoStream,
        Stream,
        "StreamID::",
        typeof modifiedOperationNoStream.Stream !== "undefined" ? modifiedOperationNoStream.Stream.StreamID : "No Stream",
        "modifiedOperation::",
        this.modifiedOperation,
        "tempOperation::",
        tempOperation
      );

      // Post Operation
      (async () => {
        let response,
          errorStatus,
          errorData = null;
        try {
          response = await ApiService.post(
            //"/operation?ID=" + (typeof this.syncedOperation.OperationUID !== "undefined" ? this.syncedOperation.OperationUID : "0"),
            "/operation" + (typeof this.syncedOperation.OperationUID !== "undefined" ? "?ID=" + this.syncedOperation.OperationUID : ""),
            // this.syncedOperation
            //this.modifiedOperation
            //modifiedOperationNoStream /* Returning only the modified keys && no Stream */
            // tempOperation /* DEBUG waiting to finish Stream */ >>> 10/22 Last best option
            //this.syncedOperation /* 10/22 Send all option... */
            //this.modifiedOperation /* 16/02/23 send modified object to do not resent messages  */
            tempOperation // 17/02/23 Pov HAVE to be complete and not deep diffed.
          );
        } catch (error) {
          console.error("onSubmitForm:: Error response:", error.response.data, error.response.status, error.response.headers);

          errorStatus = error.response.status;
          if (typeof error.response.data !== "undefined") errorData = error.response.data;
          if (typeof error.response.data.errors !== "undefined" && typeof error.response.data.errors === "object")
            errorData = error.response.data.errors.join(", ");
        } finally {
          console.log(response);
          console.log(errorStatus);
          this.isSubmitting = false;
          this.isFetching = false;

          if (typeof response === "undefined" && errorStatus !== null) {
            // Error
            window.swalWarning.fire({
              title: errorStatus,
              html: vm.$gettext("Damned, we are sorry, we have an error...") + (errorData !== null ? "<br/><code> " + errorData + " </code>" : ""),
              footer: '<a class="text-warning" href="mailto:support@opalean.fr">' + vm.$gettext("Need to contact support ?") + "</a>",
            });
            // Set status
            this.$set(this.modifiedOperation, "status", "error");
          } else if (typeof response !== "undefined" && response.status === 200) {
            console.info("onSubmitForm:: Success response:", response);

            // Success, so reset Draft
            this.$store.dispatch(RESET_OPERATION_DRAFT).then(() => {});

            // Set status
            this.$set(this.modifiedOperation, "status", "success");

            // Success
            window.swalToast.fire({
              title: actionTitle + " " + vm.$gettext("Operation"),
              html: vm.$gettext("The operation has been successfully") + " <em>" + actionMessage + "</em> !",
              icon: "success",
              timer: 2000,
              confirmButtonClass: "btn btn-secondary",
              footer:
                vm.$gettext("Operation will be automatically reloaded in few seconds...") +
                '<a href="' +
                (!this.isMobileDevice ? "/#/operations/list" : "/#/mobile/operations/list/M") +
                '">' +
                vm.$gettext("Back to list") +
                "</a>",
            });

            // Reload if OperationUID
            setTimeout(() => {
              // Concoles
              console.log("onSubmitForm:: Success, then reload / re-route Operation...");
              if (typeof response.data.OperationUID !== "undefined") console.log("onSubmitForm:: to...", response.data.OperationUID);
              if (typeof response.data.OperationUID !== "undefined" && response.data.OperationUID == this.syncedOperation.OperationUID)
                console.log("onSubmitForm:: reload...", response.data.OperationUID, response.data.OperationUID);
              // Edit new Operation via returned OperationUID
              // If edit and not a mobile of status is to validate, reload or re-route
              // Always see your operation if you created it
              if (
                !this.isMobileDevice ||
                (this.isMobileDevice && this.syncedOperation.action == "create") ||
                (this.isMobileDevice && this.syncedOperation.OperationStatus === "M")
              )
                if (typeof response.data.OperationUID !== "undefined")
                  if (response.data.OperationUID == this.syncedOperation.OperationUID)
                    // Check if we already are inside this operation, then, same route, reload
                    this.callData(response.data.OperationUID);
                  // No Re-route if response.data.OperationUID == this.syncedOperation.OperationUID
                  // If not, re-route
                  else
                    this.$router.push({
                      name: "route.operations.edit",
                      params: {
                        OperationUID: response.data.OperationUID,
                      },
                      replace: true,
                    });
                // Re-route if response.data.OperationUID != this.syncedOperation.OperationUID
                else
                  this.$router.push({
                    name: "route.operations.list",
                    replace: true,
                  });
              // Re-route if typeof response.data.OperationUID == "undefined"
              else
                this.$router.push({
                  name: "route.home",
                  replace: true,
                }); // Re-route if isMobileDevice
            }, 700);
          }
        }
      })();

      // Error
    },
    async onDuplicateForm(event, action = "instream", associatePartner = false, SourcePartnerID = null, GlobalID = null) {
      var _continue = true;
      event.preventDefault();
      this.isDuplicating = true;

      // const swalMixin = Swal.mixin({
      //   customClass: {
      //     confirmButton: "btn btn-success",
      //     cancelButton: "btn btn-outline-secondary",
      //   },
      //   buttonsStyling: false,
      // });

      if (associatePartner) {
        await window.swalAction
          .fire({
            title: vm.$gettext("Do you want to add this partner in your partner list ?"),
            text: vm.$gettext("This partner is not associated to your partner list"),
            icon: "info",
            showCancelButton: true,
            confirmButtonText: vm.$gettext("Yes"),
            cancelButtonText: vm.$gettext("No"),
            reverseButtons: true,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              console.log("onDuplicateForm + associatePartner:: Then continue ? " + SourcePartnerID + GlobalID);

              // ImportPartner
              // SourcePartnerID As Integer,  As Integer

              // PartnerID	666
              // Status	Object { UserID: 332, ClientID: 101903 }

              let _PartnerID = null;

              try {
                const response = await ApiService.get("/ImportPartner/", "SourcePartnerID=" + SourcePartnerID + "&GlobalID=" + GlobalID);
                console.log("onDuplicateForm + associatePartner:: TRY > response == ", response);
                _PartnerID = await response.data.PartnerID;
              } catch (error) {
                console.log("onDuplicateForm + associatePartner:: ABORD > Partner associating error == ", error);
                this.isDuplicating = false;
                // Abord
                _continue = false;
              } finally {
                if (_PartnerID !== null && _PartnerID !== 0) {
                  console.log("onDuplicateForm + associatePartner:: CONTINUE > Partner associated == ", _PartnerID);
                  // @TODO NON REACTIVE
                  this.syncedOperation.Partners.filter((p) => p.PartnerRole === "MD")[0].PartnerID = _PartnerID;
                }
              }

              // alert("ABORD;");
              // this.isDuplicating = false;
              // // Abord
              // _continue = false;

              //
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === window.Swal.DismissReason.cancel
            ) {
              // this.isDuplicating = false;
              // Abord
              _continue = true;
            }
          });
      }

      if (_continue)
        await window.swalAction
          .fire({
            title: vm.$gettext("Are you sure to create a new operation from this one ?"),
            text: vm.$gettext("You won't be able to revert this!"),
            icon: "question",
            showCancelButton: true,
            confirmButtonText: vm.$gettext("Create"),
            cancelButtonText: vm.$gettext("Cancel"),
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.isDuplicating = false;

              // Reset our form values
              this.resetData(action);
              // Message
              // swalMixin.fire(vm.$gettext("Prepared"), vm.$gettext("Your new operation has been prepared."), "success");
              window.swalToast.fire({
                title: vm.$gettext("Prepared"),
                html: vm.$gettext("Your new operation has been prepared."),
                timer: 1200,
                timerProgressBar: true,
                icon: "success",
              });
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === window.Swal.DismissReason.cancel
            ) {
              this.isDuplicating = false;

              //swalMixin.fire(vm.$gettext("Cancelled"), vm.$gettext("You can keep editing your form."), "success");
            }
          });
    },
    onResetForm(event) {
      event.preventDefault();
      this.isResetting = true;

      // const swalMixin = Swal.mixin({
      //   customClass: {
      //     confirmButton: "btn btn-success",
      //     cancelButton: "btn btn-outline-secondary",
      //   },
      //   buttonsStyling: false,
      // });

      window.swalAction
        .fire({
          title: vm.$gettext("Are you sure ?"),
          text: vm.$gettext("You won't be able to revert this!"),
          icon: "question",
          showCancelButton: true,
          confirmButtonText: vm.$gettext("Reset"),
          cancelButtonText: vm.$gettext("Continue"),
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isResetting = false;

            // Reset our form values considering action
            if (this.syncedOperation.action === "edit") {
              //this.syncedOperation = this.initialSyncedOperation; // NON REACTIVE
              //this.$set(this.syncedOperation, this.initialSyncedOperation); // KO
              //Vue.set(this.syncedOperation, this.initialSyncedOperation); // KO
              this.syncedOperation = Object.assign({}, this.syncedOperation, this.initialSyncedOperation); // https://v2.vuejs.org/v2/guide/reactivity#For-Objects
              console.log("updated date onResetForm() edit ::", moment(this.initialSyncedOperation.OperationDate.format()));
              // FP/FR POV values are Not working w/ "OperationStatus": "M",
            } else {
              // Resetted to initial state
              this.resetData();
            }

            // Message
            // swalMixin.fire(vm.$gettext("Resetted"), vm.$gettext("Your form has been resetted."), "success");
            window.swalToast.fire({
              title: vm.$gettext("Resetted"),
              html: vm.$gettext("Your form has been resetted."),
              timer: 1200,
              timerProgressBar: true,
              icon: "success",
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === window.Swal.DismissReason.cancel
          ) {
            this.isResetting = false;

            //swalMixin.fire(vm.$gettext("Cancelled"), vm.$gettext("You can keep editing your form."), "success");
          }
        });
    },
    // Call & Reset data
    callData(OperationUID) {
      console.log("√ callData:: OperationUID : ", OperationUID, this.fullyLoaded, "/ Are Pallets loaded ?", this.getPallets.length > 0);

      // Reset loading state
      this.fullyLoaded = false;

      // Add state loading here
      // TODO

      ApiService.get("/operation/", "ID=" + OperationUID)
        .then((response) => {
          this.syncedOperation = response.data.Operation;
          // Process Pallets to get PalletName by PalletID
          this.getPalletNames();
          // Process Partners to get (Partner)Name by PartnerID
          this.getPartnerNames();
          // Call PartnerDetails for printing
          //this.callPartnerDetails(); >> See print actions
          // Set first next/prev IDs
          this.prevOperationUID = Object.keys(this.FilteredOperations).length > 0 ? this.getPreviousOperationID(this.syncedOperation.OperationUID) : undefined;
          this.nextOperationUID = Object.keys(this.FilteredOperations).length > 0 ? this.getNextOperationID(this.syncedOperation.OperationUID) : undefined;
          // Pass edit action
          // NO REACTIVE
          // this.syncedOperation.action = "edit";
          // this.syncedOperation.formstep = 2;
          // REACTIVE
          this.$set(this.syncedOperation, "action", "edit"); // Mooved to created
          this.$set(this.syncedOperation, "formstep", 2);

          // Update POV object
          // if (typeof this.syncedOperation.POVs !== "undefined")
          //   this.syncedOperation.POVs = this.syncedOperation.POVs.filter(p => p.Role === this.syncedOperation.MyRole)[0];
          // Add my POV on a waiting Operation Status
          if (this.syncedOperation.OperationStatus === "M") {
            // Get thirdPartyPOV
            if (typeof this.syncedOperation.POVs.find((pov) => pov.Role == this.syncedOperation.HisRole) === "undefined") {
              this.syncedOperation.HisRole = this.syncedOperation.POVs[0].Role;
            }
            let thirdPartyRole = this.getAllowedThirdPartyType(
              this.syncedOperation.OperationType,
              this.syncedOperation.MyRole,
              "thirdparty",
              this.syncedOperation.HisRole
            )[0];
            let thirdPartyPOV = null;
            if (
              thirdPartyRole !== null &&
              typeof thirdPartyRole !== "undefined" &&
              this.syncedOperation.POVs.filter((p) => p.Role === thirdPartyRole).length > 0
            ) {
              thirdPartyPOV = this.syncedOperation.POVs[0];
            }

            // Build Pallet object
            let _Pallets = [];

            thirdPartyPOV.Pallets.map(function (value, key) {
              _Pallets.push({ PalletID: value.PalletID, PalletName: value.PalletName, ValidatedFP: false, ValidatedFR: false });
            });

            // Add MyRole to POVs object
            this.syncedOperation.POVs.push({
              isDraft: true,
              Role: this.syncedOperation.MyRole,
              Pallets: _Pallets,
            });
          } // End API CALL

          // Copy date to UTC
          this.$set(this, "OperationDateUTC", this.syncedOperation.OperationDate);

          setTimeout(() => {
            console.log("√ callData:: Finally, store initial object", OperationUID);
            // Finally, store initial object
            // https://stackoverflow.com/questions/54078421/how-to-clone-props-object-and-make-it-non-reactive
            //          this.initialSyncedOperation = Object.assign({}, this.syncedOperation); // Make non-reactive
            this.initialSyncedOperation = this.$mainFunctions.noReactiveCopy(this.syncedOperation); // Make non-reactive
            // this.fullyLoaded = true;
            // this.isFetching = false;
          }, 200);

          setTimeout(() => {
            console.log("√ callData:: Secure re-enabled actions", OperationUID);
            this.fullyLoaded = true;
            this.isFetching = false;
          }, 500);
        })
        .catch((error) => {
          let { response } = error;
          console.log("callData:: error", error);
          response = response === undefined ? "Error, cannot reach the server" : response.data.errors;
          console.log("√ callData:: There is an error, redirect to dashboard", OperationUID, response);
          this.$router.push({ name: "route.home" });
        });
    },
    callPartnerDetails() {
      // A
      // console.log("callPartnerDetails::", PartnerID);
      // if (PartnerID !== undefined && PartnerID !== "") {
      //   ApiService.get("/partner", "ID=" + PartnerID).then((response) => {
      //     console.log(response.data.PartnerDetails);
      //     this.partnerDetails.push([PartnerID, response.data.PartnerDetails]);
      //   });
      // } else return null;

      // B
      console.log("callPartnerDetails::", this.syncedOperation.Partners);
      if (typeof this.syncedOperation.Partners !== "undefined" && this.syncedOperation.Partners.length > 0)
        this.syncedOperation.Partners.forEach((p, index) => {
          console.log("callPartnerDetails::p", p);
          if (p.PartnerID !== null && p.PartnerID !== 0) {
            ApiService.get("/partner", "ID=" + p.PartnerID).then((response) => {
              console.log("callPartnerDetails::response", response.data.PartnerDetails, { ...p, PartnerDetails: response.data });

              // DEBUG NO REACTIVE
              //this.syncedOperation.Partners[index] = { ...p, Partner: response.data.Partner, PartnerDetails: response.data.PartnerDetails };
              //console.log(this.syncedOperation.Partners);

              // REACTIVE
              // Update v-model
              // this.$emit("update:syncedOperation", this.syncedOperation);
              // Property https://fr.vuejs.org/v2/guide/reactivity.html#Pour-les-objects
              // this.$set(this.syncedOperation.Partners[index], 'property', value);
              // Tableaux https://fr.vuejs.org/v2/guide/reactivity.html#Pour-les-tableaux
              Vue.set(this.syncedOperation.Partners, index, { ...p, Partner: response.data.Partner, PartnerDetails: response.data.PartnerDetails });
            });
          } else console.log("callPartnerDetails::No PartnerID");
        });
      else console.log("callPartnerDetails::No Partners");
    },
    resetData(action = "") {
      // @TODO to refactore with a default object.
      console.log("resetData::action ", action);

      // Store actual object
      let _StreamID = this.syncedOperation.Stream.StreamID;
      let _OperationType = this.syncedOperation.OperationType;
      let _OperationDate = this.syncedOperation.OperationDate;
      let _OperationCMR = this.syncedOperation.OperationCMR;
      let _MyRole = this.syncedOperation.MyRole;
      let _HisRole = this.syncedOperation.HisRole;
      var _Partners = this.syncedOperation.Partners;
      let _POVs = this.syncedOperation.POVs;

      //Vue.delete(this.syncedOperation);

      // Reassign a reactive operation
      //this.syncedOperation = reactive(_Operation); // Vue 3
      //Object.assign(_Operation, this.syncedOperation); // Vue 2

      // All time
      // Reset thirdpartyEdit from FlowFormThirdParties
      this.$set(this.syncedOperation, "thirdpartyEdit", undefined);

      // Create, instream and copy
      if (action === "" || action === "instream" || action === "copyflow") {
        this.$set(this.syncedOperation, "OperationTID", undefined);
        this.$set(this.syncedOperation, "action", "create");
        this.$set(this.syncedOperation, "formstep", 0);

        this.$set(this.syncedOperation, "OperationUID", 0); // undefined > 0
        this.$set(this.syncedOperation, "OperationID", 0); // undefined > 0
        this.$set(this.syncedOperation, "OperationDate", undefined);
        // Empty date object too
        this.$set(this, "OperationDateUTC", "");

        this.$set(this.syncedOperation, "OperationCMR", undefined);
        this.$set(this.syncedOperation, "OperationType", null); // #####
        this.$set(this.syncedOperation, "OperationTypeSlug", undefined);
        this.$set(this.syncedOperation, "MyRole", null);
        this.$set(this.syncedOperation, "MyRoleSlug", undefined);
        this.$set(this.syncedOperation, "OperationStatus", undefined);

        this.$set(this.syncedOperation, "Partners", []);
        //this.$set(this.syncedOperation, "POVs", [{ Pallets: [] }]);
        this.$set(this.syncedOperation, "Manifest", []);
        this.$set(this.syncedOperation, "Creation", {});
        this.$set(this.syncedOperation, "Modification", {});
        this.$set(this.syncedOperation, "Stream", { Operations: [] });
        this.$set(this.syncedOperation, "Messages", []);
        this.$set(this.syncedOperation, "Documents", []);
        this.$set(this.syncedOperation, "OperationRef", undefined);
        this.$set(this.syncedOperation, "IsDraft", undefined);
        this.$set(this.syncedOperation, "IsOS", undefined);
        // Store state
        this.initialSyncedOperation = this.$mainFunctions.noReactiveCopy(this.syncedOperation); // Make non-reactive

        // Add default pallets
        // NO REACTIVE
        // this.syncedOperation.POVs[0].Pallets = _.orderBy(this.getDefaultPallets, "DisplayOrder") ?? []; // Attention, au rechargement de la page /create > les defauts Pallets ne charge pas.
        // REACTIVE
        Vue.delete(this.syncedOperation, "POVs", "Pallets"); // Just to make sure we deleted the last POVs
        console.log("resetData:: delete POVs", typeof this.syncedOperation.POVs);
        //console.log("resetData:: delete POVs Pallets", typeof this.syncedOperation.POVs.Pallets);
        this.$set(this.syncedOperation, "POVs", [{ Role: "", Pallets: [] }]);
        console.log("resetData::POVs", this.syncedOperation.POVs);

        delete this.syncedOperation.POVs.Pallets; // DEBUG
        this.$forceUpdate(); // DEBUG

        console.log("resetData:: delete forceUpdate POVs Pallets", this.syncedOperation.POVs.Pallets);

        console.log(
          "resetData:: getDefaultPallets POVs default pallets",
          //this.getDefaultPallets,
          //this.$mainFunctions.noReactiveCopy(this.getDefaultPallets),
          this.getDefaultPallets
        );

        //Vue.set(this.syncedOperation.POVs, 0, { Role: "MC", Pallets: _.orderBy(this.getDefaultPallets, "DisplayOrder") ?? [] }); // Use reactivity of state object
        //Vue.set(this.syncedOperation.POVs, 0, { Role: "MC", Pallets: _.orderBy(Object.assign({}, this.getDefaultPallets), "DisplayOrder") ?? [] }); // Don't use reactivity of state object / only syncedOperation obj
        Vue.set(this.syncedOperation.POVs, 0, {
          Role: "MC",
          Pallets: _.orderBy(this.$mainFunctions.noReactiveCopy(this.getDefaultPallets), "DisplayOrder") ?? [], // >>>> BUG this object is still reactive / KO
          //Pallets: _.orderBy(Object.assign({}, this.getDefaultPallets), "DisplayOrder") ?? [], // KO
          //Pallets: this.DefaultPallets, // KO
          //Pallets: [],
        }); // Don't use reactivity of state object / only syncedOperation obj
        //Object.assign(this.syncedOperation.POVs.Pallets, this.getDefaultPallets);

        // Set / Reset Multiselect options
        this.displayPallets = _.orderBy(this.$mainFunctions.noReactiveCopy(this.getDisplayPallets), "DisplayOrder") ?? [];

        // If single Operation types, then call setDefaut function
        this.$refs.flowformthirdparties.setDefaultOperationType();

        // Check if object has been renewed
        console.log("resetData::POVs", this.syncedOperation.POVs);
      }

      // Add create for all actions
      if (action !== "") {
        // Add Creation
        let _Creation = {
          PartnerID: this.getUserClientID,
          UserName: this.getUserName,
          DateTime: moment(new Date()).format(), // Fix issue 18/02/23 when sending a inflow loading | unloading | copy >> OLD : new Date(),
        };
        this.$set(this.syncedOperation, "Creation", _Creation);

        // Reset modification
        this.$set(this.syncedOperation, "Modification", {});

        // Empty date object too w/ today date
        this.$set(this.syncedOperation, "OperationDate", new Date());
        this.$set(this, "OperationDateUTC", new Date());
      }

      // Only duplicate in stream
      if (action === "instream") {
        //REACTIVE
        this.$set(this.syncedOperation.Stream, "StreamID", _StreamID);
        this.$set(this.syncedOperation.Stream, "storedStreamID", _StreamID);

        // Empty date object too w/ today date
        this.$set(this.syncedOperation, "OperationDate", new Date());
        this.$set(this, "OperationDateUTC", new Date());
      }

      // Create a loading or unloading in stream
      if (action === "loading" || action === "unloading") {
        // Before, reset the initial object and store it
        this.$set(this.syncedOperation, "OperationUID", 0); // undefined > 0
        this.$set(this.syncedOperation, "OperationID", 0); // undefined > 0
        this.$set(this.syncedOperation, "action", "create");
        this.$set(this.syncedOperation, "formstep", 1);

        this.$set(this.syncedOperation, "OperationTID", undefined);
        this.$set(this.syncedOperation, "OperationDate", undefined);
        // Empty date object too
        this.$set(this, "OperationDateUTC", "");

        this.$set(this.syncedOperation, "OperationCMR", undefined);
        this.$set(this.syncedOperation, "OperationType", null);
        this.$set(this.syncedOperation, "OperationTypeSlug", undefined);
        this.$set(this.syncedOperation, "MyRole", null);
        this.$set(this.syncedOperation, "MyRoleSlug", undefined);
        this.$set(this.syncedOperation, "OperationStatus", undefined);
        this.$set(this.syncedOperation, "Partners", []);
        this.$set(this.syncedOperation, "POVs", [{ Pallets: [] }]); // Reset POVs
        this.$set(this.syncedOperation, "Manifest", []);
        this.$set(this.syncedOperation, "Creation", {});
        this.$set(this.syncedOperation, "Modification", {});
        //this.$set(this.syncedOperation, "Stream", { Operations: [] });
        this.$set(this.syncedOperation, "Messages", []);
        this.$set(this.syncedOperation, "Documents", []);
        // Store state
        this.initialSyncedOperation = this.$mainFunctions.noReactiveCopy(this.syncedOperation); // Make non-reactive

        //REACTIVE
        //this.$set(this.syncedOperation, "OperationDate", _OperationDate);
        this.$set(this.syncedOperation, "OperationCMR", _OperationCMR);
        this.$set(this.syncedOperation, "OperationStatus", "B");

        // Empty date object too w/ today date
        this.$set(this.syncedOperation, "OperationDate", new Date());
        this.$set(this, "OperationDateUTC", new Date());

        this.$set(this.syncedOperation, "MyRole", _MyRole);
        this.$set(this.syncedOperation.Stream, "StreamID", _StreamID);
        this.$set(this.syncedOperation.Stream, "storedStreamID", _StreamID);
        this.$set(this.syncedOperation, "Partners", _Partners);
        // Add Creation
        let _Creation = {
          PartnerID: this.getUserClientID,
          UserName: this.getUserName,
          DateTime: moment(new Date()).format(), // Fix issue 18/02/23 when sending a inflow loading | unloading | copy >> OLD : new Date(),
        };
        this.$set(this.syncedOperation, "Creation", _Creation);

        // Reset stored POVs
        this.$set(this.syncedOperation, "POVs", _POVs);
        // POVs Reset all FR statements // BUG TODO REACTIVE
        this.syncedOperation.POVs[this.getMyPOVIndex(_MyRole)].Pallets.forEach((p, index) => {
          this.syncedOperation.POVs[this.getMyPOVIndex(_MyRole)].Pallets[index].FR = "0"; // #566
        });
      }

      // Create a loading in stream
      if (action === "loading") {
        //REACTIVE
        this.$set(this.syncedOperation, "OperationType", "C");
        // Set HisRole role = change role ( Return as to be stricly limited to MyRole=MT )
        this.$set(this.syncedOperation, "HisRole", "MC");
      }

      // Create an unloading in stream
      if (action === "unloading") {
        //REACTIVE
        this.$set(this.syncedOperation, "OperationType", "D");
        // Set HisRole role = change role ( Return as to be stricly limited to MyRole=MT )
        this.$set(this.syncedOperation, "HisRole", "MD");
      }

      // Duplicate flow
      if (action === "copyflow") {
        //REACTIVE
        this.$set(this.syncedOperation, "formstep", 2);
        this.$set(this.syncedOperation, "OperationType", _OperationType);

        // Set role
        this.$set(this.syncedOperation, "MyRole", _MyRole);
        this.syncedOperation.POVs[0].Role = _MyRole; // TODO BUG REACTIVE

        // Add stream
        let _Stream = {
          OperationType: _OperationType,
          OperationStatus: "B",
          MyRole: _MyRole,
          Partners: _Partners,
        };
        // NO REACTIVE
        //this.syncedOperation.Stream.Operations.push(Stream);
        // REACTIVE
        this.$set(this.syncedOperation.Stream, "Operations", [_Stream]);

        // Set partners
        this.$set(this.syncedOperation, "Partners", _Partners);

        // Keep Date
        this.$set(this.syncedOperation, "OperationDate", _OperationDate);
        this.$set(this, "OperationDateUTC", _OperationDate);
      }

      // Duplicate flow return
      if (action === "return") {
        this.$set(this.syncedOperation, "OperationTID", undefined);
        this.$set(this.syncedOperation, "action", "create");
        this.$set(this.syncedOperation, "formstep", 0);

        this.$set(this.syncedOperation, "OperationUID", 0); // undefined > 0
        this.$set(this.syncedOperation, "OperationID", 0); // undefined > 0
        this.$set(this.syncedOperation, "OperationDate", undefined);

        this.$set(this.syncedOperation, "OperationCMR", undefined);
        this.$set(this.syncedOperation, "OperationType", null); // #####
        this.$set(this.syncedOperation, "OperationTypeSlug", undefined);
        this.$set(this.syncedOperation, "MyRole", null);
        this.$set(this.syncedOperation, "MyRoleSlug", undefined);
        this.$set(this.syncedOperation, "OperationStatus", undefined);

        this.$set(this.syncedOperation, "Partners", []);
        this.$set(this.syncedOperation, "POVs", [{ Pallets: [] }]);
        this.$set(this.syncedOperation, "Manifest", []);
        this.$set(this.syncedOperation, "Creation", {});
        this.$set(this.syncedOperation, "Modification", {});
        this.$set(this.syncedOperation, "Stream", { Operations: [] });
        this.$set(this.syncedOperation, "Messages", []);
        this.$set(this.syncedOperation, "Documents", []);
        // Store state
        this.initialSyncedOperation = this.$mainFunctions.noReactiveCopy(this.syncedOperation); // Make non-reactive

        //REACTIVE
        this.$set(this.syncedOperation, "formstep", 2);
        this.$set(this.syncedOperation, "OperationType", _OperationType);

        // Set MyRole role = switch role ( Return as to be stricly limited to transfert )
        let newMyRole = _MyRole === "MC" ? "MD" : "MC";
        this.$set(this.syncedOperation, "MyRole", newMyRole);
        // Set HisRole role = switch role ( Return as to be stricly limited to transfert )
        let newHisRole = _HisRole === "MC" ? "MD" : "MC";
        this.$set(this.syncedOperation, "HisRole", newHisRole);

        // Reset stored POVs
        // Switch POV MC to MD / MD to MC
        _POVs.forEach((p) => {
          if (p.Role === "MC") p.Role = "MD";
          else if (p.Role === "MD") p.Role = "MC";
        });
        // Then, set
        this.$set(this.syncedOperation, "POVs", _POVs);

        // Keep streamID
        this.$set(this.syncedOperation.Stream, "StreamID", _StreamID);
        this.$set(this.syncedOperation.Stream, "storedStreamID", _StreamID);

        // Keep Date
        this.$set(this.syncedOperation, "OperationDate", _OperationDate);
        this.$set(this, "OperationDateUTC", _OperationDate);

        // Keep CMR
        this.$set(this.syncedOperation, "OperationCMR", _OperationCMR);

        // Switch _Partners MC > MD | MD > MC
        console.log(
          "DuplicateForm:: return before",
          _Partners.find((p) => p.PartnerRole === "MC").PartnerID,
          _Partners.findIndex((p) => p.PartnerRole === "MC"),
          _Partners[_Partners.findIndex((p) => p.PartnerRole === "MC")].PartnerRole
        );
        console.log(
          "DuplicateForm:: return before",
          _Partners.find((p) => p.PartnerRole === "MD").PartnerID,
          _Partners.findIndex((p) => p.PartnerRole === "MD"),
          _Partners[_Partners.findIndex((p) => p.PartnerRole === "MD")].PartnerRole
        );

        let foundPartnerRoleMC = _Partners.findIndex((p) => p.PartnerRole === "MC");
        let foundPartnerRoleMD = _Partners.findIndex((p) => p.PartnerRole === "MD");

        Vue.set(_Partners, foundPartnerRoleMC, {
          ..._Partners[foundPartnerRoleMC],
          PartnerRole: "MD",
        });

        Vue.set(_Partners, foundPartnerRoleMD, {
          ..._Partners[foundPartnerRoleMD],
          PartnerRole: "MC",
        });

        console.log(
          "DuplicateForm:: return switched",
          _Partners.find((p) => p.PartnerRole === "MC").PartnerID,
          _Partners.findIndex((p) => p.PartnerRole === "MC"),
          _Partners[_Partners.findIndex((p) => p.PartnerRole === "MC")].PartnerRole
        );
        console.log(
          "DuplicateForm:: return switched",
          _Partners.find((p) => p.PartnerRole === "MD").PartnerID,
          _Partners.findIndex((p) => p.PartnerRole === "MD"),
          _Partners[_Partners.findIndex((p) => p.PartnerRole === "MD")].PartnerRole
        );

        // Add stream
        let _Stream = {
          OperationType: _OperationType,
          OperationStatus: "B",
          MyRole: newMyRole,
          Partners: _Partners,
        };
        // NO REACTIVE
        //this.syncedOperation.Stream.Operations.push(Stream);
        // REACTIVE
        this.$set(this.syncedOperation.Stream, "Operations", [_Stream]);

        this.$set(this.syncedOperation, "Partners", _Partners);
      }

      if (action === "") {
        // Empty date object too w/ today date to new Create Operation
        this.$set(this.syncedOperation, "OperationDate", new Date());
        this.$set(this, "OperationDateUTC", new Date());

        setTimeout(() => {
          // Finally, store initial object
          // https://stackoverflow.com/questions/54078421/how-to-clone-props-object-and-make-it-non-reactive
          // this.initialSyncedOperation = Object.assign({}, this.syncedOperation); // Make non-reactive
          this.initialSyncedOperation = this.$mainFunctions.noReactiveCopy(this.syncedOperation); // Make non-reactive
        }, 500);
      }
    },
    // Next & Prev functions
    previousOperation() {
      console.log("previousOperation::", this.syncedOperation.OperationUID, this.prevOperationUID);

      // Reset loading state
      this.fullyLoaded = false;

      // Re-route solution // V4 + V6
      if (this.prevOperationUID !== undefined)
        this.$router.push({
          name: "route.operations.edit",
          params: {
            OperationUID: this.prevOperationUID,
          },
          replace: true,
        });
    },
    nextOperation() {
      console.log("nextOperation::", this.syncedOperation.OperationUID, this.nextOperationUID);

      // Reset loading state
      this.fullyLoaded = false;

      // Re-route solution // V4 + V6
      if (this.nextOperationUID !== undefined)
        this.$router.push({
          name: "route.operations.edit",
          params: {
            OperationUID: this.nextOperationUID,
          },
          replace: true,
        });
    },
    // Print
    computedAddress(PartnerID) {
      let data = this.syncedOperation.Partners.find((p) => p.PartnerID == PartnerID) ?? null;
      console.log("computedAddress::", PartnerID, data);
      if (data !== null && typeof data.PartnerDetails !== "undefined" && PartnerID !== "" && typeof PartnerID !== "undefined")
        return (
          (Boolean(data.PartnerDetails.Address1) ? data.PartnerDetails.Address1 + ", " : "") +
          (Boolean(data.PartnerDetails.Address2) ? data.PartnerDetails.Address2 + ", " : "") +
          (Boolean(data.PartnerDetails.Address1) || Boolean(data.PartnerDetails.Address2) ? "<br/>" : "") +
          (Boolean(data.Partner.ZipCode) ? data.Partner.ZipCode + " " : "") +
          (Boolean(data.Partner.City) ? data.Partner.City + ", " : "") +
          /* (Boolean(data.Partner.District) ? "( " + data.Partner.District + " ) " : "") + */
          (Boolean(data.Partner.CountryISO) ? this.CountryISO.filter((c) => c.ISO2 == data.Partner.CountryISO)[0].Name + " " : "")
        );
      else return "—";
    },
    getFakeLines(n = 2) {
      let ret = [];
      let pattern = { PalletID: 0, PalletName: "" };
      for (let i = 0; i < n; i++) {
        ret.push(pattern);
      }
      return ret;
    },
    print() {
      let modal = document.getElementById("contentToPrint");
      let cloned = modal.cloneNode(true);
      let section = document.getElementById("print");

      // Create print div if not
      if (!section) {
        section = document.createElement("div");
        section.id = "print";
        document.body.appendChild(section);
      }

      // Make sure section is empty
      section.innerHTML = "";
      // Append cloned content
      section.appendChild(cloned);

      // Then print
      window.print();
    },
    deletePrint() {
      let section = document.getElementById("print");
      if (section) section.remove(); // Removes the div
    },
    // Path
    getPath(url) {
      return process.env.BASE_URL + url;
    },
    transformUrlIntoLinkInMessage(text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(
        urlRegex,
        (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: #79726E;text-decoration:underline;">${url}</a>`
      );
    },
  },
  // DEBUGGER
  // async mounted() {
  //   await this.$nextTick();
  //   debugger;
  // },
  mounted() {
    // Medias / load camera
    // Safely mount camera
    // if (this.isMobileDevice) this.onCameraInit(); // Do not init camera on mount

    console.info("EditOperation.vue");

    // Default Pallets
    console.log("√ Mount::Default Pallet", this.syncedOperation.action, this.fullyLoaded, this.syncedOperation.POVs[0], this.getDefaultPallets);
    if (this.syncedOperation.action === "create" || this.syncedOperation.action === undefined) {
      /* Returns only ByDefault === true Pallets in order */
      // NO REACTIVE
      // this.syncedOperation.POVs[0].Pallets = _.orderBy(this.getDefaultPallets, "DisplayOrder") ?? [];
      // Attention, au rechargement de la page /create > les defauts Pallets ne charge pas.
      // REACTIVE
      // Vue.set(this.syncedOperation.POVs, 0, { Role: "MC", Pallets: _.orderBy(this.getDefaultPallets, "DisplayOrder") ?? [] }); // Use reactivity of state object
      // Vue.set(this.syncedOperation.POVs, 0, {
      //   Role: "MC",
      //   Pallets: _.orderBy(this.$mainFunctions.noReactiveCopy(this.getDefaultPallets), "DisplayOrder") ?? [],
      // }); // Don't use reactivity of state object / only syncedOperation obj
      // Set data when DefaultPallets are loaded
      var idx = 0; // Do not loop if fails
      const waitDefaultPallets = setInterval(() => {
        // console.log("√ Do we received Default Pallets ?", this.getDefaultPallets.length > 0);
        // if (this.getDefaultPallets.length > 0) {
        console.log("√ Do we received Default Pallets ?", this.getDefaultPallets, this.getDefaultPallets.length > 0, idx);
        if (this.getDefaultPallets.length > 0) {
          Vue.set(this.syncedOperation.POVs, 0, {
            Role: "MC",
            Pallets: _.orderBy(this.$mainFunctions.noReactiveCopy(this.getDefaultPallets), "DisplayOrder") ?? [], // OK
            //Pallets: this.DefaultPallets, // KO
          }); // Don't use reactivity of state object / only syncedOperation obj

          // Set / Reset Multiselect options
          this.displayPallets = _.orderBy(this.$mainFunctions.noReactiveCopy(this.getDisplayPallets), "DisplayOrder") ?? [];

          clearInterval(waitDefaultPallets);
          this.fullyLoaded = true;
        }
        if (idx > 40) {
          clearInterval(waitDefaultPallets);
          console.error("Interval timeout");
        } else idx++;
      }, 100);
    }

    // Default object
    // Make non reactive
    // https://stackoverflow.com/questions/54078421/how-to-clone-props-object-and-make-it-non-reactive
    if (this.syncedOperation.action === "create" || this.syncedOperation.action === undefined) {
      // this.initialSyncedOperation = Object.assign({}, this.syncedOperation); // Make non-reactive
      this.initialSyncedOperation = this.$mainFunctions.noReactiveCopy(this.syncedOperation); // Make non-reactive
    }

    // Set OperationID OperationUID to 0
    if (this.syncedOperation.action === "create") this.$set(this.syncedOperation, "OperationUID", 0);
    if (this.syncedOperation.action === "create") this.$set(this.syncedOperation, "OperationID", 0);

    // Default today date
    if (this.syncedOperation.action === "create" || this.syncedOperation.action === undefined) {
      // NO REACTIVE
      //this.syncedOperation.OperationDate = new Date();
      //REACTIVE
      this.$set(this.syncedOperation, "OperationDate", new Date());
      this.$set(this, "OperationDateUTC", new Date());
    }

    // Set today date
    this.$set(this, "todayDate", moment(new Date()).format()); //"DD/MM/YYYY"

    // Ok, if it's a creation, the operation is mounted
    // if (this.syncedOperation.action === "create") this.fullyLoaded = true;
  },
};
</script>
